import {PrimitiveDateTime} from "fhir-typescript-models/dist/src/models/fhir/internal";
import {codesystem, conceptmap, valueset} from "../../model/defaultvalues/Constant";
import {isSnomedSystem} from "../../codesystem/snomed/util/SnomedUrlUtil";

export const formatDate = (dateString) => {
    return new Date(dateString).toDateString();
}

export const formDateYearMonthDayTime = (dateTimeString) => {
    if (dateTimeString !== undefined) {
        let d = new Date(dateTimeString)
        let swedishDate = d.toLocaleDateString('sv-SE');
        let swedishTime = d.toLocaleTimeString('sv-SE').substring(0, 5);
        return swedishDate + " " + swedishTime;
    } else {
        return "";
    }
}


export const nowDateYearMonthDay = () => {
    const d = new Date()
    return d.toLocaleDateString('sv-SE');
}


export const nowYYYYMMDDHHMMSS = () => {
    Object.defineProperty(Date.prototype, 'YYYYMMDDHHMMSS', {
        value: function () {
            function pad2(n) {  // always returns a string
                return (n < 10 ? '0' : '') + n;
            }

            return this.getFullYear() +
                pad2(this.getMonth() + 1) +
                pad2(this.getDate()) +
                pad2(this.getHours()) +
                pad2(this.getMinutes()) +
                pad2(this.getSeconds());
        }
    });
}


export const nowDateYearMonthDayPrimitiveDateTime = () => {
    let primitiveDateTime = new PrimitiveDateTime();
    let d = new Date()
    primitiveDateTime.value = d.toLocaleDateString('sv-SE');
    return primitiveDateTime;
}

export const getArtifactSwedishLabel = (artifacttype) => {
    if (artifacttype === codesystem) {
        return "Kodverk";
    } else if (artifacttype === valueset) {
        return "Urval";
    } else if (artifacttype === conceptmap) {
        return "Mappningsspecifikation";
    }
};

export const getArtifactSwedishLabelNTgenus = (artifacttype) => {
    if (artifacttype === codesystem) {
        return "Kodverket";
    } else if (artifacttype === valueset) {
        return "Urvalet";
    } else if (artifacttype === conceptmap) {
        return "Mappningsspecifikationen";
    }
};

export const getStatusInSwedishSupinum = (status) => {
    if (status === "active") {
        return "aktiverats";
    } else if (status === "retired") {
        return "avställts";
    }
};


export const replaceSwedishCharacters = (s) => {
    const a = s.replaceAll("å", "a");
    const b = a.replaceAll("ä", "a");
    const c = b.replaceAll("ö", "o");
    const d = c.replaceAll("Å", "A");
    const e = d.replaceAll("Ä", "A");
    const f = e.replaceAll("Ö", "O");
    return f;
};

export function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

/**
 * @param {Object} object
 * @param {string} key
 * @return {any} value
 */
export function getParameterCaseInsensitive(object, key) {
    const keyWithoutWhiteSpace = key.replaceAll(" ", "");
    const asLowercase = keyWithoutWhiteSpace.toLowerCase();
    let result = object[Object.keys(object)
        .find(k => k.toLowerCase() === asLowercase)
        ];
    if (typeof result == "string") {
        result = result.trim()
    } else if (result !== undefined) {
        const resultAsString = JSON.stringify(result);
        result = resultAsString.trim();
    }
    return result;
}

export function hasPropertyCaseInsensitive(obj, property) {
    var props = [];
    for (var i in obj) if (obj.hasOwnProperty(i)) props.push(i);
    var prop;
    const propertyWithoutWhiteSpace = property.replaceAll(" ", "");
    while (prop = props.pop()) if (prop.toLowerCase().trim() === propertyWithoutWhiteSpace.toLowerCase()) return true;
    return false;
}

export function onlyContainsWhitespace(str) {
    if (!/\S/.test(str)) {
        return true
    } else {
        return false;
    }
}

export function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        var result = (a[property].toLowerCase() < b[property].toLowerCase()) ? -1 : (a[property].toLowerCase() > b[property].toLowerCase()) ? 1 : 0;
        return result * sortOrder;
    }
}

export function removeEmptyObjects(data) {
    Object.keys(data).forEach(key => {
        if (JSON.stringify(data[key]) === '{}') {
            data[key] = undefined;
        } else if (Array.isArray(data[key])) {
            removeEmptyObjectsInArray(data[key])
        }
    });
}

export function removeEmptyObjectsInArray(array) {
    if (array !== undefined && Array.isArray(array)) {
        for (let obj of array) {
            removeEmptyObjects(obj)
        }
    }
}

export function removeEmptyFields(data) {
    Object.keys(data).forEach(key => {
        if (Array.isArray(data[key]) == false && ((typeof data[key] == "string" && data[key].trim() === '') || data[key] == null)) {
            delete data[key];
        } else if (Array.isArray(data[key])) {
            removeEmptyFieldsInArray(data[key])
        }
    });
}

export function removeEmptyFieldsInArray(array) {
    if (array !== undefined && Array.isArray(array)) {
        for (let obj of array) {
            removeEmptyFields(obj)
        }
    }
}

export function removeEmptyFieldsInMetaData(data) {
    /*    removeEmptyFields(data);
        removeEmptyObjects(data);
        removeEmptyObjects(data);*/
}


export const removeBlanksIfOnlyBlanks = (v) => {
    // debugger;
    if ((v !== undefined && v != null) && (typeof v == "string" && v.trim() === '')) {
        return undefined;
    } else {
        return v;
    }
};

export function removeEmojiFromFieldEvent(e) {
    if (e.target !== undefined && e.target.value != undefined) {
        e.target.value = removeEmojiFromString(e.target.value);
    }
}


export function removeEmojiFromJson(dataInJsonFormat) {
    let dataAsString = JSON.stringify(dataInJsonFormat);
    let dataAsStringWithoutEmpojis = removeEmojiFromString(dataAsString);
    let dataInJsonFormatWithoutEmoji = JSON.parse(dataAsStringWithoutEmpojis);
    return dataInJsonFormatWithoutEmoji;
}

export function removeEmojiFromString(str) {
    let strCopy = str;
    if (strCopy !== undefined) {
        /*    const emojiKeycapRegex = /[\u0023-\u0039]\ufe0f?\u20e3/g;*/
        const emojiRegex = /\p{Extended_Pictographic}/gu;
        const emojiComponentRegex = /\p{Emoji_Component}/gu;
        /*    if (emojiKeycapRegex.test(strCopy)) {
                strCopy = strCopy.replace(emojiKeycapRegex, '');
            }*/
        if (emojiRegex.test(strCopy)) {
            strCopy = strCopy.replace(emojiRegex, '');
        }
        if (emojiComponentRegex.test(strCopy)) {

            try {
                // eslint-disable-next-line no-restricted-syntax
                for (const emoji of (String(strCopy).match(emojiComponentRegex) || [])) {
                    if (/[\d|*|#]/.test(emoji)) {
                        continue;
                    }
                    strCopy = strCopy.replace(emoji, '');
                }
            } catch (error) {
                console.error("Error on strCopy: " + JSON.stringify(strCopy))
                console.error(error);
            }


        }
    }
    return strCopy;
}

export let compareTwoArrayOfObjects = (
    first_array_of_objects,
    second_array_of_objects
) => {
    return (
        first_array_of_objects.length === second_array_of_objects.length &&
        first_array_of_objects.every((element_1) =>
            second_array_of_objects.some((element_2) =>
                Object.keys(element_1).every((key) => element_1[key] === element_2[key])
            )
        )
    );
};

export const sortStrings = (stringA, stringB) => {
    if (stringA < stringB) {
        return -1;
    }
    if (stringA > stringB) {
        return 1;
    }
    // names must be equal
    return 0;
}

export function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export const getNestedObject = (obj, nestedDotNotationPropertyString) => {
    nestedDotNotationPropertyString = nestedDotNotationPropertyString.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    nestedDotNotationPropertyString = nestedDotNotationPropertyString.replace(/^\./, '');           // strip a leading dot
    var a = nestedDotNotationPropertyString.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in obj) {
            obj = obj[k];
        } else {
            return;
        }
    }
    return obj;
}

export const replaceLast = (str, pattern, replacement) => {
    const match =
        typeof pattern === 'string'
            ? pattern
            : (str.match(new RegExp(pattern.source, 'g')) || []).slice(-1)[0];
    if (!match) return str;
    const last = str.lastIndexOf(match);
    return last !== -1
        ? `${str.slice(0, last)}${replacement}${str.slice(last + match.length)}`
        : str;
};

export const isCodeSystemWithoutCodesInMetaDataObject = (url) => {
    return isSnomedSystem(url);
};


export const accessObjPropertyValue = (path, object) => {
    return path.split('.').reduce((o, i) => o[i], object)
}


export function setObjPropertyValue(path, value, object) {
    var schema = object;  // a moving reference to internal objects within obj
    var pList = path.split('.');
    var len = pList.length;
    for (var i = 0; i < len - 1; i++) {
        var elem = pList[i];
        if (!schema[elem]) schema[elem] = {}
        schema = schema[elem];
    }
    schema[pList[len - 1]] = value;

    return schema;
}


export function changeValue(obj_path, value, json) {
    let keys = obj_path.split(".")
    let obj = {...json},
        tmpobj = {},
        prevobj = {}
    for (let x = keys.length - 1; x >= 0; x--) {
        if (x == 0) {
            obj[keys[0]] = tmpobj
        } else {
            let toeval = 'json.' + keys.slice(0, x).join('.');
            prevobj = {
                ...tmpobj
            }
            tmpobj = eval(toeval);
            if (x == keys.length - 1) {
                tmpobj[keys[x]] = value
            } else {
                tmpobj[keys[x]] = prevobj
            }
        }
    }
    return obj
}

export function setObjectKeyValue(obj = {}, key, val) {
    const keys = key.split('.')
    const last = keys.pop()
    keys.reduce((o, k) => o[k] ??= {}, obj)[last] = val
}

export function getObjectKeyValue(obj = {}, key) {
    const keys = key.split('.')
    const last = keys.pop()
    return keys.reduce((o, k) => o[k] ??= {}, obj)[last];
}
