export const hasDraftInVersionArray = (versionArray: any[]): boolean => {

    let hasDraft = false;
    if (versionArray !== undefined) {
        for (let item of versionArray) {
            const statusOfVersion: string = item.resource.status;
            if (statusOfVersion === "draft") {
                hasDraft = true;
                break;
            }
        }
    }
    return hasDraft;
}
