import {ValueSetExpansionContains} from "fhir-typescript-models/dist/src/models/fhir/internal";
import {getSwedishRecommendedFromDesignations} from "./synonym/SynonymSwedishLanguageTransformer";


export const getSwedishDisplayOrDefault = (contain: ValueSetExpansionContains): string => {
    let display = "";
    if (contain.designation !== undefined) {
        //  @ts-ignore
        let swedishValue: string | undefined = getSwedishRecommendedFromDesignations(contain.designation);
        display = swedishValue === undefined ? getDisplayDefault(contain) : swedishValue;
    }
    return display;
}

const getDisplayDefault = (contain: ValueSetExpansionContains): string => {
    let display = "";
    if (contain.display !== undefined) {
        let value = contain.display;
        //  @ts-ignore
        display = value === undefined ? "" : value;
    }
    return display;
}
