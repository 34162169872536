//@ts-nocheck
import React, {FC, useRef, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {IRHookFormValidationMessage} from "../../common/rhookform/IRHookFormValidationMessage";
import NamePurpose from "./NamePurpose";
import IdentifierArray from "./IdentifierArray";
import {removeValidationMessagesOnObjPath} from "../../common/validation/MetaValidationRules";


let renderCount = 0;

interface MetaDataProps {
    artifactType: string,
    isLocked: any,
    editDisabled?: boolean
    errorMessageList?: IRHookFormValidationMessage[],
    handleWarnMessageList?: any,
    handleInfoMessageList?: any,
    handleErrorMessageList?: any,
    setWarnMessageList?: any,
    setErrorMessageList?: any,
}


const MetaData: FC<MetaDataProps> = ({
                                         artifactType,
                                         isLocked,
                                         editDisabled,
                                         errorMessageList,
                                         handleWarnMessageList,
                                         handleInfoMessageList,
                                         handleErrorMessageList,
                                         setWarnMessageList,
                                         setErrorMessageList
                                     }) => {

    const methods = useFormContext();

    renderCount++;

    const removeValidationMessagesOnPath = (path: string) => {
        removeValidationMessagesOnObjPath(path, setErrorMessageList);
        removeValidationMessagesOnObjPath(path, setWarnMessageList);
    };

    return (
        <fieldset disabled={isLocked(editDisabled, methods)}>
            <NamePurpose artifactType={artifactType} editDisabled={editDisabled}
                         handleWarnMessageList={handleWarnMessageList}
                         handleInfoMessageList={handleInfoMessageList}
                         handleErrorMessageList={handleErrorMessageList}
                         setErrorMessageList={setErrorMessageList}
                         removeValidationMessagesOnPath={removeValidationMessagesOnPath}
            />
            <IdentifierArray editDisabled={editDisabled}
                             errorMessageList={errorMessageList}
                             handleWarnMessageList={handleWarnMessageList}
                             handleErrorMessageList={handleErrorMessageList}
                             setErrorMessageList={setErrorMessageList}/>
        </fieldset>
    );
};

export default MetaData;
