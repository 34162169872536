import '@inera/ids-core/components/link/register';
import '@inera/ids-core/components/icon/register';


const aktiv = "Aktiv";
const inaktiv = "Inaktiv";
const statusList = [
    aktiv,
    inaktiv
];


export const codeColumn: any = {
    accessorKey: 'code',
    header: 'Kod',
    size: 40, //small column
    minSize: 10, //min size enforced during resizing
    // @ts-ignore
    enableColumnFilters: true,
    enableEditing: false,
    /*    hidden: false,
        cellStyle: {
            minWidth: 10
        },
        headerStyle: {
            minWidth: 10
        }*/
}

export const displayColumn: any = {
    accessorKey: 'display',
    header: 'Klartext',
    // @ts-ignore
    enableColumnFilters: true,
    enableEditing: false,
    /*    hidden: false,
        cellStyle: {
            maxWidth: 50
        },
        headerStyle: {
            maxWidth: 50
        },
        minSize: 10, //min size enforced during resizing*/
}

export const activeColumn = (headerString : string): any => {

    return {
        // @ts-ignore
        accessorFn: (originalRow) => {
            // @ts-ignore
            return originalRow.inactive ? inaktiv : aktiv;
        },
        header: headerString,
        // @ts-ignore
        enableColumnFilters: true,
        size: 35, //small column,
        maxSize: 35,
        cellStyle: {
            minWidth: 10
        },
        headerStyle: {
            minWidth: 10
        },
        hidden: false,
        filterVariant: 'select',
        filterSelectOptions: statusList,
        enableEditing: false,
    }
}