import React from "react";
import Client from 'fhir-kit-client';
import {fhirUrl, SNOMED_CT_URL} from "../../model/defaultvalues/Constant";
import {Parameters, ValueSet} from "fhir-typescript-models/dist/src/models/fhir/internal";
import IConceptSearchResultItem from "../../codesystem/snomed/search/model/IConceptSearchResultItem";
import {transformParametersToConceptSearchResultItem} from "../../codesystem/snomed/transformer/ParameterTransformer";


const fhirClient = new Client({baseUrl: fhirUrl});

export const lookupSnomedCode = async (code: string, version : string) => {

    let conceptToReturn = undefined;
    await fhirClient.request("CodeSystem/$lookup?code=" + code +
        "&system=" + SNOMED_CT_URL +
        "&version=" + version +
        "&property=" +
        /*    "*"*/
        "effectiveTime&property=moduleId&property=inactive&property=sufficientlyDefined&property=code&property=fullySpecifiedName&property=display&property=kind&property=code&property=terms&property=codingNote&property=exclusion&property=abstract&property=postcoordinationScale&property=artgIds&property=display&property=designation"
        /*                " + &property=http://id.who.int/icd11/mms/designation|term&property=inactive&property=property"*/
    )
        .then(response => {
            let responseParameters: Parameters = response as unknown as Parameters;
            let responseCode = undefined;
            let responseDisplay = undefined;
            let responseSwedishRecommended = undefined;
            let responseSwedishDesignation = [];
            for (const element of responseParameters.parameter!) {

                // @ts-ignore
                if (element.name !== undefined && element.name === "code") {
                    // @ts-ignore
                    responseCode = element.valueCode;
                }
                // @ts-ignore
                else if (element.name !== undefined && element.name === "display") {
                    // @ts-ignore
                    responseDisplay = element.valueString;
                }
                // @ts-ignore
                else if (element.name === "designation") {
                    let swedish = false;
                    let swedishRecommendedValue = undefined;
                    let swedishSynonymValue = undefined;
                    let recommended = false;
                    let synonym = false;
                    for (const part of element.part!) {
                        // @ts-ignore
                        if (part.name === "language" && (part.valueCode === "sv-x-sctlang-46011000-052107" || part.valueCode === "sv")) {
                            swedish = true;
                        }
                        // @ts-ignore
                        else if (swedish && part.name === "use" && part.valueCoding !== undefined && part.valueCoding.code === "preferredForLanguage") {
                            // @ts-ignore
                            recommended = true;
                        }
                        // @ts-ignore
                        else if (swedish && part.name === "use" && part.valueCoding !== undefined && part.valueCoding.code === "900000000000013009") {
                            // @ts-ignore
                            synonym = true;
                        }
                        // @ts-ignore
                        else if (swedish && recommended && part.name === "value") {
                            // @ts-ignore
                            swedishRecommendedValue = part.valueString;
                            recommended= false;
                        }
                        // @ts-ignore
                        else if (swedish && synonym && part.name === "value") {
                            // @ts-ignore
                            swedishSynonymValue = part.valueString;
                            synonym= false;
                        }
                    }

                    if (swedish == true && swedishRecommendedValue !== undefined) {
                        responseSwedishRecommended = swedishRecommendedValue;

                    }
                    if (swedish == true && swedishSynonymValue !== undefined) {
                        responseSwedishDesignation.push(swedishSynonymValue)
                    }
                }
            }

            const concept = {
                "code": responseCode,
                "display": responseSwedishRecommended ? responseSwedishRecommended : responseDisplay,
                "swedishSynonym" : responseSwedishDesignation,
                "swedishDesignation": responseSwedishRecommended
            }

            conceptToReturn = concept;

        })
        .catch(e => {
            console.error("FhirClient SNOMED handleRowClickShowConceptData Error Response" + JSON.stringify(e));
            return undefined;
        });

    return conceptToReturn;

};


export const lookupCodeSystemCodeWithChildConceptCodes = async (code: string, conceptArray: IConceptSearchResultItem[], version: string) => {
    await fhirClient.request("CodeSystem/$lookup?code=" + code +
        "&system=" + SNOMED_CT_URL + "&version=" + version + "&property=*"
    )
        .then(response => {

            let responseParameters: Parameters = response as unknown as Parameters;
            const concept=  transformParametersToConceptSearchResultItem(responseParameters);
            if (conceptArray !== undefined) {
                // @ts-ignore
                conceptArray.push(concept);
            }

        })
        .catch(e => {
            console.error("FhirClient ICD handleRowClickShowConceptData Error Response" + JSON.stringify(e));
        });

};


export async function requestParent(code: string, display: string, propertyValue: string, value: string, version: string) {
    // @ts-ignore
    let responseValueSet: ValueSet = undefined;

    await fhirClient.request("ValueSet/$expand?_format=json&elements=expansion.contains.total,expansion.contains.contains",
        {
            method: 'POST',

            body: {
                // @ts-ignore
                "resourceType": "Parameters",
                "parameter": [{
                    "name": "valueSet",
                    "resource": {
                        "resourceType": "ValueSet",
                        "compose": {
                            "include": [{
                                "system": SNOMED_CT_URL,
                                "filter": [{
                                    "property": propertyValue,
                                    "op": "=",
                                    "value": value
                                }],
                                "version": version
                            }]
                        }
                    }
                }, {
                    "name": "property",
                    "valueString": "sufficientlyDefined"
                }, {
                    "name": "property",
                    "valueString": "inactive"
                }, {
                    "name": "property",
                    "valueString": "parent"
                },
                    {
                        "name": "property",
                        "valueString": "child"
                    },
                    {
                        "name": "count",
                        "valueInteger": 200000
                    }, {
                        "name": "includeDesignations",
                        "valueBoolean": true
                    }
                ]
            }
        }
    )
        .then(response => {
            let parentConcept = undefined;
            responseValueSet = response as unknown as ValueSet;

        })
        .catch(e => {
            console.error("FhirClient SNOMED handleRowClickShowConceptData Error Response" + JSON.stringify(e));
        });

    return responseValueSet;
};

export async function requestActiveOnly(codes: string[], version : string) {
    let concepts = [];
    for(let code of codes){
        concepts.push(
            {"code": code}
        );
    }
    // @ts-ignore
    let responseValueSet: ValueSet = undefined;

    await fhirClient.request("ValueSet/$expand?_format=json&elements=expansion.contains.total,expansion.contains",
        {
            method: 'POST',

            body: {
                // @ts-ignore
                "resourceType": "Parameters",
                "parameter": [
                    {
                        "name":"activeOnly",
                        "valueBoolean": true
                    },
                    {
                        "name": "valueSet",
                        "resource": {
                            "resourceType": "ValueSet",
                            "compose": {
                                "include": [{
                                    "system": SNOMED_CT_URL,
                                    "concept": concepts,
                                    "version": version
                                }]
                            }
                        }
                    },
                    {
                        "name": "count",
                        "valueInteger": 200000
                    }
                ]
            }
        }
    )
        .then(response => {
            responseValueSet = response as unknown as ValueSet;

        })
        .catch(e => {
            console.error("FhirClient SNOMED ValueSet/$expand? activeOnly Error Response" + JSON.stringify(e));
        });

    return responseValueSet;
};


export const getConcepts = async (codes: string[], setLoadingRows :any, version: string) => {
    setLoadingRows(true);
    // @ts-ignore
    let concepts : IConceptSearchResultItem[] = [];
    for (let code of codes) {
        // @ts-ignore
        await lookupCodeSystemCodeWithHierarchy(code, concepts, version);
    }
    setLoadingRows(false);
    // @ts-ignore
    return concepts;

}

export const lookupCodeSystemCodeWithHierarchy = async (code: string, conceptArray: [], version: string) => {
    await fhirClient.request("CodeSystem/$lookup?code=" + code +
        "&system=" + SNOMED_CT_URL + "&version=" + version + "&property=*"
    )
        .then(response => {

            let responseParameters: Parameters = response as unknown as Parameters;

            let responseCode = undefined;
            let responseDisplay = undefined;
            let definition = undefined;
            let swedishDisplay = undefined;
            let childCodes = [];
            let includes = [];
            let excludes = [];
            let notes = [];
            for (const element of responseParameters.parameter!) {
                // @ts-ignore
                if (element.name !== undefined && element.name === "code") {
                    // @ts-ignore
                    responseCode = element.valueCode;
                }
                // @ts-ignore
                else if (element.name !== undefined && element.name === "display") {
                    // @ts-ignore
                    responseDisplay = element.valueString;
                }
                // @ts-ignore
                else if (element.name !== undefined && element.name === "designation" && element.part[0].name === "language" &&
                    // @ts-ignore
                    element.part[0].valueCode === "sv-x-sctlang-46011000-052107") {
                    // @ts-ignore
                    swedishDisplay = element.part[2].valueString;
                }
                // @ts-ignore
                else if (element.name === "property") {
                    // @ts-ignore
                    if (element.part[0].valueCode === "child" && element.part[0].name === "code" && element.part[1].name === "value"
                    ) {
                        // @ts-ignore
                        childCodes.push({code: element.part[1].valueCode});
                    }

                    // @ts-ignore
                    else if (element.part[0].valueCode === "definition" && element.part[0].name === "code"
                    ) {
                        // @ts-ignore
                        definition = element.part[1].valueCode;
                    }
                }
            }


            const display = (swedishDisplay === undefined ? responseDisplay : swedishDisplay);

            // @ts-ignore
            const concept = {
                // @ts-ignore
                code: responseCode,
                // @ts-ignore
                display: display,
                // @ts-ignore
                definition: definition,
                // @ts-ignore
                concept: childCodes
            }

            if (conceptArray !== undefined) {
                // @ts-ignore
                conceptArray.push(concept);
            }


        })
        .catch(e => {
            console.error("FhirClient ICD handleRowClickShowConceptData Error Response" + JSON.stringify(e));
        });

};