import React, {useCallback, useEffect, useState} from "react";
import Client from "fhir-kit-client";
import {
    fhirUrl,
} from "../../../../model/defaultvalues/Constant";
import VSTabsForCodeSystem from "../VSTabsForCodeSystem";
import {isCodeSystemWithoutCodesInMetaDataObject} from "../../../common/CommonFunctions";
import {TAB_SUMMERY_TITLE} from "../VSConstant";
import {getTabKeyAfterSummeryTabIncluded} from "../util/VSCommonFunctions";
import {getActiveCodesOnly, shouldFetchActiveCodeStatus} from "../../../../codesystem/snomed/expand/SnomedExpandUtil";

const fhirClient = new Client({baseUrl: fhirUrl});
let renderCount = 0;

const VSDefineDataCodeSystemForUpdate = (props) => {

    const [changeFromMenu, setChangeFromMenu] = useState();

    const [tabList, setTabList] = useState([]);


    const handleChangeFromMenu = (e,
                                  concept,
                                  system,
                                  version,
                                  rowindex,
                                  tabKey,
                                  id,
                                  menuName) => {
        if (menuName !== undefined) {
            setChangeFromMenu(menuName);
        }
        props.handleChange(e, concept, system, version, rowindex, tabKey, id);
    }

    const removeConceptToValueSetFromMenu = (concept, system, version, menuName) => {
        setChangeFromMenu(menuName);
        props.removeConceptToValueSet(concept, system, version);
    }


    renderCount++;

    const getAllConceptsAvailableFromCodeSystemArtefact = async (key, system, version) => {
        let concept = undefined;

        if (isCodeSystemWithoutCodesInMetaDataObject(system)) {
            concept = props.conceptsToAdd[key].concept;
        } else {
            const id = props.artifactArray[key].resource.id;
            await fhirClient.read({
                resourceType: "CodeSystem",
                id: id
            })
                .then((data) => {
                    concept = data.concept;
                }).catch(e => {
                    console.error(e);
                });

        }
        return concept;
    }

    const collectAllCodesAndTabData = async () => {

        if (props.artifactArray !== undefined && props.artifactArray.length != 0) {

            let artifactDataArrayForTabs = [];
            if (props.artifactArray.length > 0) {
                await artifactDataArrayForTabs.push({
                    key: 0,
                    title: TAB_SUMMERY_TITLE,
                    loadSelectedCodeSystemForValueSet: true
                });
            }


            let conceptsInSummary = [];
            let codeSystemsAllCodes = [];
            for (let i = 0; i < props.artifactArray.length; i++) {

                //plus 1 because first tab is symmery and not a codeSystem
                const key = getTabKeyAfterSummeryTabIncluded(i);

                const id = props.artifactArray[i].resource.id;
                const title = props.artifactArray[i].resource.title;
                const version = props.artifactArray[i].resource.version;
                const system = props.artifactArray[i].resource.url;
                const status = props.artifactArray[i].resource.status;
                const allConceptsInCodeSystem = await getAllConceptsAvailableFromCodeSystemArtefact(i, system);

                /*   let id = getNextTabListId();*/
                await artifactDataArrayForTabs.push({
                    key: key,
                    id: key,
                    title: title,
                    version: version,
                    system: system,
                    status: status,
                    loadSelectedCodeSystemForValueSet: true
                });

                var allCodesInCodeSystem = false;
                var selectedCodesOnly = [];
                setSelectedCodesInCodeSystem(allConceptsInCodeSystem, allCodesInCodeSystem, selectedCodesOnly, system, version);

                if (selectedCodesOnly.length != 0) {

                    const shouldFetchSnomedActiveStatus = shouldFetchActiveCodeStatus(selectedCodesOnly, system)
                    if (shouldFetchSnomedActiveStatus) {
                        selectedCodesOnly =   await collectSnomedCodeActiveStatus(selectedCodesOnly, version);
                    }

                    conceptsInSummary.push({
                        version: version,
                        system: system,
                        concept: selectedCodesOnly
                    });
                }
                var codeSystemAllCodesWithSelectionState = {
                    allCodesInCodeSystem: allCodesInCodeSystem,
                    concept: allConceptsInCodeSystem,
                    resourceType: "CodeSystem",
                    url: system,
                    id: id,
                    status: status
                }
                if (version !== undefined) {
                    codeSystemAllCodesWithSelectionState.version = version;
                }
                await codeSystemsAllCodes.push(codeSystemAllCodesWithSelectionState);
            }

            props.setCodeSystemsWithAllCodesSelectableCodes(codeSystemsAllCodes);

            setTabList(artifactDataArrayForTabs);

            if (conceptsInSummary.length != 0) {
                props.setConceptsToAdd(conceptsInSummary);
            }

        }
    }

    const collectSnomedCodeActiveStatus = async (data, version) => {
        // @ts-ignore
        const codes = data.map(a => a.code);
        const activeCodes = await getActiveCodesOnly(codes, version);
        // @ts-ignore
        data.filter(obj => {
            if (activeCodes.includes(obj.code)) {
                obj.inactive = false;
            } else {
                obj.inactive = true;
            }
        })
        return data;
    }


    const resetAsyncForm = useCallback(async () => {
        collectAllCodesAndTabData();
    }, []);


    useEffect(() => {
        if (props.isLatestRelatedArtifactsArrayEventAnAddEvent) {
            collectAllCodesAndTabData();
            props.setIsLatestRelatedArtifactsArrayEventAnAddEvent(false)
        }
    }, [props.artifactArray])


    const setSelectedCodesInCodeSystem = (allConceptsInCodeSystem, allCodesInCodeSystem, codeSystemWithSelectedCodesOnly, system, version) => {

        for (let i = 0; i < props.conceptsToAdd.length; i++) {
            const include = props.conceptsToAdd[i];

            if (include.system === system && include.version === version && include.concept === undefined) {
                allCodesInCodeSystem = true;
            } else if (include.system === system && include.version === version && allConceptsInCodeSystem !== undefined) {

                for (let j = 0; j < include.concept.length; j++) {

                    var code = include.concept[j].code;

                    for (let k = 0; k < allConceptsInCodeSystem.length; k++) {
                        if (allConceptsInCodeSystem[k].code === code) {
                            allConceptsInCodeSystem[k].selected = true;
                            codeSystemWithSelectedCodesOnly.push(allConceptsInCodeSystem[k]);
                        }
                    }

                }
            }
        }

    };


    const removeCodeSystem = (codeSystemIndex) => {
        const isNan = Number.isNaN(codeSystemIndex);
        if (isNan == false && typeof codeSystemIndex === 'number') {
            try {

                const id = props.codeSystemsWithAllCodesSelectableCodes[codeSystemIndex].id;
                const system = props.codeSystemsWithAllCodesSelectableCodes[codeSystemIndex].url;
                const version = props.codeSystemsWithAllCodesSelectableCodes[codeSystemIndex].version;
                props.codeSystemsWithAllCodesSelectableCodes.splice(codeSystemIndex, 1); // 2nd parameter means remove one item only
                var newArray = [...props.codeSystemsWithAllCodesSelectableCodes]; // make a separate copy of the array
                props.setCodeSystemsWithAllCodesSelectableCodes(newArray);

                props.removeCodeSystemFromValueSet(codeSystemIndex, id, system, version);
            } catch (error) {
                console.error("removeCodeSystem codeSystemIndex: " + codeSystemIndex + ", from list : "
                    + JSON.stringify(props.codeSystemsWithAllCodesSelectableCodes) + ", throws error: " + error);
                throw error;
            }
        } else {
            console.error("tabIndex: " + JSON.stringify(codeSystemIndex) + " isNaN");
        }
    };

    const testMethod = () => {
        let localTablist = tabList;
        let localSelectedCodeSystemForValueSet = props.codeSystemsWithAllCodesSelectableCodes;
        debugger;
        console.log("testMethod");
    }

    return (
        <div>
            {/*            <IDSButton size='m' secondary={true} onClick={() => {
                testMethod();
            }}>
                TESTKNAPP
            </IDSButton>*/}
            <VSTabsForCodeSystem
                artifactArray={props.artifactArray}
                setRelatedArtifactsArray={props.setRelatedArtifactsArray}
                conceptsToAdd={props.conceptsToAdd}
                setConceptsToAdd={props.setConceptsToAdd}
                codeSystemsWithAllCodesSelectableCodes={props.codeSystemsWithAllCodesSelectableCodes}
                setCodeSystemsWithAllCodesSelectableCodes={props.setCodeSystemsWithAllCodesSelectableCodes}
                addConcept={props.addConcept}
                artifactType={props.artifactType}
                removeConceptToValueSet={props.removeConceptToValueSet}
                removeCodeSystemFromValueSet={removeCodeSystem}
                tabList={tabList}
                setTabList={setTabList}
                type={props.type}
                handleChange={handleChangeFromMenu}
                changeFromMenu={changeFromMenu}
                removeConceptToValueSetFromMenu={removeConceptToValueSetFromMenu}
            />
        </div>
    );
};

export default VSDefineDataCodeSystemForUpdate;
