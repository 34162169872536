import React, {FC} from 'react';

import {IDSCol, IDSContainer, IDSRow} from "@inera/ids-react";

import SnomedSearchForm from "../search/SnomedSearchForm";

let renderCount = 0;

interface SnomedTabPanelSearchProps {
    id: string,
    version: string,
    snomedConceptsToAdd?: any[],
    handleChange?: any,
    handleNodeClick: any,
    tabKey?: number,
    codeSystemIndex?: number
}


const SnomedTabPanelSearch: FC<SnomedTabPanelSearchProps> = ({
                                                                 id,
                                                                 version,
                                                                 snomedConceptsToAdd,
                                                                 handleChange,
                                                                 handleNodeClick,
                                                                 tabKey,
                                                                 codeSystemIndex
                                                             }) => {


    renderCount++;

    return (
        <div>
            <IDSContainer gutterless={true}>

                <IDSRow justify="space-between">
                    <IDSCol cols="12">
                        <IDSContainer gutterless={true}>
                            <IDSRow>
                                <IDSCol>
                                    <SnomedSearchForm
                                        id={id}
                                        version={version}
                                        snomedConceptsToAdd={snomedConceptsToAdd}
                                        handleChange={handleChange}
                                        handleNodeClick={handleNodeClick}
                                        tabKey={tabKey}
                                        codeSystemIndex={codeSystemIndex}/>
                                </IDSCol>
                            </IDSRow>
                        </IDSContainer>
                    </IDSCol>
                </IDSRow>
            </IDSContainer>
        </div>
    );
};

export default SnomedTabPanelSearch;
