import {
    ParametersParameter,
    ValueSetComposeIncludeConceptDesignation
} from "fhir-typescript-models/dist/src/models/fhir/internal";

export const snomedSynonymCode = "900000000000013009";


export const getUseCodeFromDesignation = (designation: ValueSetComposeIncludeConceptDesignation): string | undefined => {
    let snomedSynonymCode: string | undefined = undefined;
    if (designation.use !== undefined) {
        //  @ts-ignore
        snomedSynonymCode = designation.use.code;
    }
    return snomedSynonymCode;
}

export const getUseCodeFromDesignationPartArray = (designationParameters: ParametersParameter[]): string | undefined => {
    let snomedCode: string | undefined = undefined;
    if(designationParameters.length >= 1){
        snomedCode =  getUseCodeFromDesignationPart(designationParameters[1]);
    }
    return snomedCode;
}

const getUseCodeFromDesignationPart = (designationParameter: ParametersParameter): string | undefined => {
    let snomedCode: string | undefined = undefined;
    //  @ts-ignore
    if (designationParameter.name === "use" && designationParameter?.valueCoding !== undefined) {
        //  @ts-ignore
        snomedCode = designationParameter?.valueCoding?.code;
    }
    return snomedCode;
}
