export const checkHasCode = (worksheet) => {
    const firstHeadlineRow = worksheet.A1;

    if (firstHeadlineRow === undefined) {
        importAlert();
    } else {
        const valueInFirstCellInFirstHeadlineRow = firstHeadlineRow.v;
        if (valueInFirstCellInFirstHeadlineRow !== undefined) {
            const trimmedValue = valueInFirstCellInFirstHeadlineRow.trim();
            if (trimmedValue.toLowerCase() !== "kod") {
                importAlert();
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
}

const importAlert = () => {
    alert("En kolumn med rubrik \"Kod\" måste finnas i imporfilen");
}

export const hasImportErrorValidations = (codesNotInCodeSystem, displaysNotInCodeSystem) => {
    return ((codesNotInCodeSystem === null || codesNotInCodeSystem.length == 0) &&
        (displaysNotInCodeSystem === null || displaysNotInCodeSystem.length == 0)) ? false : true;
}

export const fetchedDataWithNoErrors = (excelData, codesNotInCodeSystem, displaysNotInCodeSystem) => {
    return (fetchedData(excelData) && !hasImportErrorValidations(codesNotInCodeSystem, displaysNotInCodeSystem));
}

export const fetchedData = (excelData) => {
    return excelData !== null && excelData.length != 0
}

export const getIsImportStepOneComplete = (isTabCodeSystemSelected, tabList, tabKey) => {
    return (!isTabCodeSystemSelected || tabList[tabKey].system === undefined) ? false : true;
}
