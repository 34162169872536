import React from 'react';

import {collectValidationMessage} from "../../common/validation/MetaValidationRules";
import {displayMinWarning} from "../meta/MetaWarnInfoConstant";
import {contentTabName} from "../../common/page/TabName";
import {
    getFieldNameInArrayHierarchy,
    hasRowCellsAllEmptyValues
} from "../../common/rhookform/RHookFormArrayHierarchyFunction";


let renderCount = 0;
const arrayname = "concept"

export const warnValidateDisplay = (value: string, prevValue: string, rowindex: string, warnMessageList: [], handleWarningMessageList: any, methods: any, hasRowAllEmptyValues?: boolean) => {

    if (hasRowAllEmptyValues !== undefined && hasRowAllEmptyValues) {
        handleEmptyRowWarningMessages(rowindex, displayMinWarning, handleWarningMessageList, methods);
    } else {

        let validationResults = []

        let minValidationResult = displayMinWarningValidation(value, rowindex, warnMessageList, handleWarningMessageList, methods);
        let minValidation = {"label": displayMinWarning, "message": minValidationResult}
        validationResults.push(minValidation);

        let validationMessage: string = "";
        for (let validationResult of validationResults) {
            if (validationResult.message !== false) {
                if (validationMessage === "") {
                    validationMessage = validationMessage + validationResult.message + " "
                } else {
                    validationMessage = validationMessage + " | " + validationResult.message + " "
                }
            }
        }
        return finalWarningValidationResultHandling(validationMessage, rowindex, methods);

    }
}

const finalWarningValidationResultHandling = (validationMessage: string, rowindex: string, methods: any) => {
    let rowConceptToValidate = methods.getValues(arrayname)[Number(rowindex)];
    if (validationMessage !== "") {
        rowConceptToValidate.displayWarning = validationMessage;
        return validationMessage;
    } else {
        rowConceptToValidate.displayWarning = undefined;
        return false;
    }
}

const handleEmptyRowWarningMessages = (rowindex: string, warningType: string, handleWarningMessageList: any, methods: any) => {
    handleWarningMessageList(null, warningType, rowindex);
    let rowConceptToValidate = methods.getValues(arrayname)[Number(rowindex)];
    if (rowConceptToValidate != undefined) {
        rowConceptToValidate.displayWarning = undefined;
    }
}


const displayMinWarningValidation = (value: string, rowindex: string, warnMessageList: [], handleWarningMessageList: any, methods: any) => {
    let message = "Klartext ofullständig. Ange minst 1 tecken."
    if (JSON.stringify(methods.getValues(arrayname)[Number(rowindex)]) !== undefined) {
        const name = getFieldNameInArrayHierarchy(rowindex, arrayname, "display");
        let validationResult = value !== undefined && ((typeof value == "string" && value.trim().length < 1 && !hasRowCellsAllEmptyValues(name, methods)) && message);
        let validationmessage = collectValidationMessage(message, displayMinWarning, validationResult, handleWarningMessageList, contentTabName, rowindex);
        return validationmessage;
    } else {
        collectValidationMessage("", displayMinWarning, false, handleWarningMessageList, contentTabName, rowindex);
        return false;
    }
}

