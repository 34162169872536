import * as React from "react";
import {useWatch, Control, UseFormReturn} from "react-hook-form";
import {getSwedishStyledStatusText} from "../../../../styles/TTStyles";

interface VersionStatusInput {
    status: string;
}

export function VersionStatusWatched({ control }: { control: Control<VersionStatusInput> }, { methods }: { methods: UseFormReturn }) {
    const status = useWatch({
        control,
        name: "status", // without supply name will watch the entire form
        defaultValue: ""  // default value before the render
    });

    return <strong>{getSwedishStyledStatusText(status)}</strong>; // only re-render at the component level, when status changes
}