export const descriptionMinimumWarn: string = "descriptionMinimumWarn";
export const authNameWarn: string = "authNameWarn";
export const authorEmailWarn: string = "authorEmailWarn";
export const authorUrlWarn: string = "authorUrlWarn";
export const authorPhoneWarn: string = "authorPhoneWarn";

export const identifierHasNoneWarn: string = "identifierHasNoneWarn";
export const identifierHasNoneWarnMessage: string = "Ange en unik identifierare enligt OID eller UUID";
export const publisherNameWarn: string = "publisherNameWarn";

export const publisherEmailWarn: string = "publisherEmailWarn";
export const publisherUrlWarn: string = "publisherUrlWarn";
export const publisherPhoneWarn: string = "publisherPhoneWarn";

export const versionDescWarn: string = "versionDescWarn";
export const retiredDescWarn: string = "retiredDescWarn";

export const codeMinError: string = "codeMinError";
export const codeSwedishCharError: string = "codeSwedishCharError";
export const codeSpecialCharError: string = "codeSpecialCharError";
export const codeDuplicateCaseSensitiveError: string = "codeDuplicateCaseSensitiveError";
export const codeDuplicateCaseSensitiveWarn: string = "codeDuplicateCaseSensitiveWarn";
export const displayMinWarning: string = "displayMinError";
export const displayMinWarn : string = "displayMinWarn";
export const displayDuplicateInfo: string = "displayDuplicateInfo";
export const codeWhiteSpaceInfo: string = "codeWhiteSpaceInfo";

export const definitionMinInfo : string = "definitionMinInfo";
export const validationInternalMetaDataWarning = "validationInternalMetaDataWarning";
export const emptyVersion : string = validationInternalMetaDataWarning + "EmptyVersion";

export const emptyDescription : string = validationInternalMetaDataWarning + "EmptyDescription";
export const emptyVersionDescription : string = validationInternalMetaDataWarning + "EmptyVersionDescription";
export const emptyAuthorName : string = validationInternalMetaDataWarning + "EmptyAuthorName";
export const emptyPublisher : string = validationInternalMetaDataWarning + "EmptyPublisher";
export const noIdentifier : string = validationInternalMetaDataWarning + "NoIdentifier";





