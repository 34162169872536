import React from "react";
import {SNOMED_CT_ID, SNOMED_CT_URL} from "../../../model/defaultvalues/Constant";

export const isSnomedSystem = (url: string | undefined) => {
    return url !== undefined && url === SNOMED_CT_URL;
}

export const isSnomedPath = (path: string) => {
    let isSnomed = false;
    for (let id of SNOMED_CT_ID) {
        if (path.includes("CodeSystem/" + id)) {
            isSnomed = true;
            break;
        }
    }
    return isSnomed;
}

export const getBaseId = (id: string) => {
    return id?.slice(0, 14);
}