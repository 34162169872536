// @ts-nocheck
import ITabName from "../page/ITabName";
import {
    getErrorField,
    getErrorMessage,
    getErrorType,
    matchesErrorType,
    maxErrorType,
    minErrorType,
    requiredErrorType
} from "../rhookform/RHookFormMessageUtil";
import {collectValidationMessage} from "./MetaValidationRules";
import {createLabel} from "./RHookFormValidationUtil";
import {YUPErrorLevelValidation} from "./ValidationInternalMetaDataYupErrorRules";

export const hasValidationInternalMetaDataYupErrorRules = (errorType: string) => {

    if (
        errorType === minErrorType ||
        errorType === maxErrorType ||
        errorType === requiredErrorType ||
        errorType === matchesErrorType ||
        (errorType !== undefined && errorType.startsWith(YUPErrorLevelValidation))
    ) {
        return true;
    } else {
        return false;
    }
};
export const collectValidationInternalMetaDataYupErrorRules = (name: string, previousYupValidation: string | undefined, setPreviousYupValidation: any, handleErrorMessageList: any, methods: any, tabName: ITabName, rowindex?: string) => {


    const errorType = getErrorType(name, methods);
    const errorMessage = getErrorMessage(name, methods)

    // @ts-ignore
    if (hasValidationInternalMetaDataYupErrorRules(errorType) && (isAsPreviousYupValidation(errorType, previousYupValidation) == false) && handleErrorMessageList !== undefined) {

        if (previousYupValidation === undefined) {
            // @ts-ignore
            collectValidationMessage(errorMessage, createLabel(name, errorType), true, handleErrorMessageList, tabName, rowindex, name);
            // @ts-ignore
            setPreviousYupValidation(errorType);
        } else {
            //Remove previous
            collectValidationMessage("", createLabel(name, previousYupValidation), false, handleErrorMessageList, tabName, rowindex);
            //add new errorMessage
            // @ts-ignore
            collectValidationMessage(errorMessage, createLabel(name, errorType), true, handleErrorMessageList, tabName, rowindex, name);
            // @ts-ignore
            setPreviousYupValidation(errorType);
        }


    } else if (previousYupValidation !== undefined && (getErrorField(name, methods) === undefined)) {
        // remove
        collectValidationMessage("", name + previousYupValidation, false, handleErrorMessageList, tabName, rowindex);
        setPreviousYupValidation(undefined);
    }

}

const isAsPreviousYupValidation = (errorType: string, previousYupValidation: string) => {
    if (errorType === previousYupValidation) {
        return true;
    } else {
        return false;
    }
}