import React, {FC, useState} from "react";
import '@inera/ids-core/styles/themes/inera-admin.css';
import '@inera/ids-core/styles/themes/inera.css';
import {
    IDSRow,
    IDSCol,
    IDSContainer,
    IDSCard,
    IDSIcon
} from "@inera/ids-react";
import {IRHookFormValidationMessage} from "../../rhookform/IRHookFormValidationMessage";
import {SaveNotification} from "./SaveNotification";

interface ValidationHeaderProps {
    editDisabled?: boolean,
    infoMessageList?: IRHookFormValidationMessage[]
    warnMessageList?: IRHookFormValidationMessage[]
    errorMessageList?: IRHookFormValidationMessage[],
    activateSaveSuccessNotice?: boolean,
    setActivateSaveSuccessNotice?: any,
    activateSaveErrorNotice?: boolean,
    setActivateSaveErrorNotice?: any,
    artefactType?: string,
    isFromDraftToActiveSave?: boolean,
    setIsFromDraftToActiveSave?: any,
    isFromActiveToRetiredSave?: boolean,
    setIsFromActiveToRetiredSave?: any
}

export const ValidationHeader: FC<ValidationHeaderProps> = ({
                                                                editDisabled,
                                                                infoMessageList,
                                                                warnMessageList,
                                                                errorMessageList,
                                                                activateSaveSuccessNotice,
                                                                setActivateSaveSuccessNotice,
                                                                activateSaveErrorNotice,
                                                                setActivateSaveErrorNotice,
                                                                artefactType,
                                                                isFromDraftToActiveSave,
                                                                setIsFromDraftToActiveSave,
                                                                isFromActiveToRetiredSave,
                                                                setIsFromActiveToRetiredSave,
                                                            }) => {

    let renderCount = 0;

    const getWarnTextExplanation = (total: number) => {
        let totalnumbersText: string = total == 1 ? (total.toString() + " varning") : (total.toString() + " Varningar")
        let explanation: string = " - Behöver åtgärdas innan du kan aktivera artefakten";
        return <span><strong>{totalnumbersText}</strong> {explanation}</span>;
    };

    const getInfoTextExplanation = (total: number) => {
        let totalnumbersText: string = total == 1 ? (total.toString() + " Informationsmeddelande") : (total.toString() + " Informationsmeddelanden")
        let explanation: string = " - Behöver inte åtgärdas men är bra att känna till";
        return <span><strong>{totalnumbersText}</strong> {explanation}</span>;
    };


    const getErrorTextExplanation = (total: number) => {
        let totalnumbersText: string = total.toString() + " Fel";
        let explanation: string = " - Behöver åtgärdas innan du kan spara innehållet eller aktivera artefakten";
        return <span><strong>{totalnumbersText}</strong> {explanation}</span>;
    };

    const getErrorWarnInfoMessageHeader = () => {
        if (errorMessageList?.length != 0) {
            return "Innehållet kan inte sparas";
        } else if (warnMessageList?.length != 0) {
            return "Innehållet kan sparas men inte aktiveras";
        } else {
            return "Innehållet kan sparas men det finns saker att se över";
        }
    };

    const hasValidations = () => {
            if (errorMessageList?.length != 0) {
                return true;
            } else if (warnMessageList?.length != 0) {
                return true;
            } else if (infoMessageList?.length != 0) {
                return true;

            } else {
                return false;
            }
        }
    ;


    return (
        <div>
            <div>

                {(activateSaveSuccessNotice !== undefined && activateSaveSuccessNotice == true) &&
                    <SaveNotification
                        activateSaveNotice={activateSaveSuccessNotice}
                        setActivateSaveNotice={setActivateSaveSuccessNotice}
                        idsAlertType={"success"}
                        artefactType={artefactType}
                        isFromDraftToActiveSave={isFromDraftToActiveSave}
                        setIsFromDraftToActiveSave={setIsFromDraftToActiveSave}
                        isFromActiveToRetiredSave={isFromActiveToRetiredSave}
                        setIsFromActiveToRetiredSave={setIsFromActiveToRetiredSave}
                    />
                }

                {(activateSaveErrorNotice !== undefined && activateSaveErrorNotice == true) &&
                    <SaveNotification
                        activateSaveNotice={activateSaveErrorNotice}
                        setActivateSaveNotice={setActivateSaveErrorNotice}
                        idsAlertType={"error"}
                        artefactType={artefactType}/>
                }
            </div>

            <div>
                {(
                        ((warnMessageList !== undefined && warnMessageList.length > 0) ||
                            (infoMessageList !== undefined && infoMessageList.length > 0) ||
                            (errorMessageList !== undefined && errorMessageList.length > 0)) && !editDisabled
                    )
                    &&
                    <IDSContainer gutterless={true}>
                        <IDSRow justify="space-between">
                            <IDSCol cols="7">

                                {editDisabled === false &&
                                    <IDSCard fill={true}>
                                        <>
                                            {hasValidations() &&
                                                <h3>{getErrorWarnInfoMessageHeader()}</h3>}

                                            {(errorMessageList !== undefined && errorMessageList.length > 0) &&
                                                <div>
                                                    <div style={{display: 'flex'}}>
                                                        {
                                                            //             JSON.stringify(errorMessageList)
                                                        }
                                                        <IDSIcon name={"warning"} color={"#FF0000"} color2={"#FF0000"}
                                                                 size={"s"}/> {getErrorTextExplanation(errorMessageList.length)}
                                                    </div>
                                                </div>
                                            }
                                            {(warnMessageList !== undefined && warnMessageList.length > 0) &&
                                                <div>
                                                    <div style={{display: 'flex'}}>
                                                        {
                                                            //             JSON.stringify(warnMessageList)
                                                        }
                                                        <IDSIcon name={"attention"} color={"#f4ae43"} color2={"#f4ae43"}
                                                                 size={"s"}/> {getWarnTextExplanation(warnMessageList.length)}
                                                    </div>
                                                </div>
                                            }
                                            {(infoMessageList !== undefined && infoMessageList.length > 0) &&
                                                <div>
                                                    <div style={{display: 'flex'}}>
                                                        {
                                                            //               JSON.stringify(infoMessageList)
                                                        }
                                                        <IDSIcon name={"information"} color={"#357726"}
                                                                 color2={"#357726"}
                                                                 size={"s"}/> {getInfoTextExplanation(infoMessageList.length)}
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    </IDSCard>
                                }
                             {/*   <br/>*/}
                            </IDSCol>
                            <IDSCol cols="5">
                            </IDSCol>
                        </IDSRow>
                    </IDSContainer>
                }
                <br/>
            </div>
        </div>
    );
};
