import React, {FC, useCallback} from 'react';
import {UseFormReturn} from 'react-hook-form';
import {textFieldStyle} from "../../../styles/TTStyles";
import {getHierarchyAsString} from "./RHookFormArrayHierarchyFunction";
import {remountConceptArrayWithNewValue} from "./RHookTextFieldForTableUtil";
import {handleKeyUp, removeTabsInValue} from "./FieldUtil";
import {removeEmojiFromString} from "../CommonFunctions";

interface ReactHookFormTextFieldIneraProps {
    arrayname: string;
    fieldname: string;
    index: string;
    methods: UseFormReturn;
    arrayUpdateMethod?: any;
    arrayAppendMethod?: any;
}

let renderCount = 0;

const RHookFormTextFieldForTable: FC<ReactHookFormTextFieldIneraProps> = ({
                                                                              arrayname,
                                                                              fieldname,
                                                                              index,
                                                                              methods,
                                                                              arrayUpdateMethod,
                                                                              arrayAppendMethod
                                                                          }: ReactHookFormTextFieldIneraProps) => {


    const getHierarchyForConcept = useCallback(async () => {
    }, []);


    //concept[0].definition
    const getName = () => {
        let name = getHierarchyAsString(index, arrayname) + "." + fieldname;
        return name;
    };

    const {onChange, onBlur, ref} = methods.register(getName());

    // @ts-ignore
    const handleChange = event => {
        if (event.target.value === "true") {
            methods.setValue(getName(), true);
        } else if (event.target.value == "false") {
            methods.setValue(getName(), false);
        } else if (event.target.value === "") {
            methods.setValue(getName(), undefined);
        } else {
            let nname = getName();
            let valueWithoutEmojis = removeEmojiFromString(event.target.value);
            methods.setValue(nname, valueWithoutEmojis);
        }
        remountConceptArrayWithNewValue(index, arrayname, methods, arrayUpdateMethod, arrayAppendMethod);
    };

    renderCount++;


    return (
        <div
            onKeyUp={e => handleKeyUp(e, handleChange)}>
            <label></label>
            <input type="text"
                   {...methods.register(getName(), {setValueAs: (value: string) => removeTabsInValue(value)})}
                   defaultValue={""}
                   key={Math.random()}
                   style={textFieldStyle}
            />
        </div>

    );
};

export default RHookFormTextFieldForTable;