import React, {FC, useEffect, useState} from 'react';

import {IDSCol, IDSContainer, IDSRow} from "@inera/ids-react";

import ConceptSnomedTaxonomi from "../../../components/artifact/concept/table/ConceptSnomedTaxonomi";
import {ValueSet} from "fhir-typescript-models/dist/src/models/fhir/internal";
import {SNOMED_CT_CODE, SNOMED_CT_DISPLAY, valueset} from "../../../model/defaultvalues/Constant";
import {requestParentViaCodeOnly} from "../expand/SnomedParentHierarchyUtil";


let renderCount = 0;


interface SnomedTabPanelTaxonomiProps {
    id: string,
    version: string,
    snomedConceptsToAdd?: any[],
    handleChange?: any,
    handleNodeClick: any,
    tabKey?: number,
    codeSystemIndex?: number,
    onChangeFromMenu?: string
}


const SnomedTabPanelTaxonomi: FC<SnomedTabPanelTaxonomiProps> = ({
                                                                     id,
                                                                     version,
                                                                     snomedConceptsToAdd,
                                                                     handleChange,
                                                                     handleNodeClick,
                                                                     tabKey,
                                                                     codeSystemIndex,
                                                                     onChangeFromMenu
                                                                 }) => {


    const [selectedCode, setSelectedCode] = useState<string>();
    const [selectedCodeDisplay, setSelectedCodeDisplay] = useState<string>();
    const [selectedCodeRelatedData, setSelectedCodeRelatedData] = useState<ValueSet>();

    const [parentChildDirection, setParentChildDirection] = useState<boolean>(true);
    const [parentChildDirectionData, setParentChildDirectionData] = useState<ValueSet>();


    useEffect(() => {
        getTaxonomi(SNOMED_CT_CODE, SNOMED_CT_DISPLAY);
    }, []);

    const getTaxonomi = (code: string, display: string) => {
        //       handleTaxonomyNodeClick(code, display, "0");
        performRequestParentViaCodeOnly(code, display);
    };

    const handleSelectedCode = (code: string, display: string, valueSet: ValueSet) => {
        setSelectedCode(code);
        setSelectedCodeDisplay(display);
        setSelectedCodeRelatedData(valueSet);
    };

    const performRequestParentViaCodeOnly = (code: string, display: string) => {
        requestParentViaCodeOnly(code, display, setParentChildDirection,
            setSelectedCode, setSelectedCodeDisplay,
            setParentChildDirectionData, handleSelectedCode, version);
    };


    renderCount++;

    return (
        <div>
            <IDSContainer gutterless={true}>
                <IDSRow justify="space-between">
                    <IDSCol>
                        <IDSContainer gutterless={true}>
                            <IDSRow justify="space-between">
                                <IDSCol>
                                    <ConceptSnomedTaxonomi
                                        id={id}
                                        version={version}
                                        snomedConceptsToAdd={snomedConceptsToAdd}
                                        parentChildDirectionData={parentChildDirectionData}
                                        handleTaxonomyNodeClick={handleNodeClick}
                                        handleChange={handleChange}
                                        tabKey={tabKey}
                                        codeSystemIndex={codeSystemIndex}
                                        onChangeFromMenu={onChangeFromMenu}
                                    />
                                </IDSCol>
                            </IDSRow>
                        </IDSContainer>
                    </IDSCol>
                </IDSRow>
            </IDSContainer>
        </div>
    );
};

export default SnomedTabPanelTaxonomi;
