import React from "react";
import {IValueSetComposeIncludeConcept} from "fhir-typescript-models/dist/src/models/fhir/internal";
import {MRT_RowSelectionState} from "material-react-table";

export const setRowSelections = (data: any,
                                 snomedConceptsToAdd: IValueSetComposeIncludeConcept[] | undefined,
                                 rowSelection: MRT_RowSelectionState,
                                 setRowSelection: any) => {
    if (snomedConceptsToAdd !== undefined) {
        var i = data.length;
        while (i--) {
            let searchResultItem = data[i];

            var rowSelectionFoundInConceptsToAdd = snomedConceptsToAdd.filter(obj => {
                if (obj.code === searchResultItem.code)
                    return obj;
            })

            if (rowSelectionFoundInConceptsToAdd.length == 0) {
                rowSelection[i.toString()] = false;
            } else {
                rowSelection[i.toString()] = true;
            }
        }
        // @ts-ignore
        setRowSelection(state => ({...rowSelection}));
    } else {
        setRowSelection({});
    }
}