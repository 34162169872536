import {ValueSet} from "fhir-typescript-models/dist/src/models/fhir/internal";
import {compare} from "../util/ConceptSearchItemUtil";

import {collectContains, extractCodes} from "../transformer/SnomedTransformer";
import {requestActiveOnly} from "../../../services/snomed/SnomedService";
import {isSnomedSystem} from "../util/SnomedUrlUtil";

const filter = '&filter=';
const refset = '=refset';
const systemVersion = '&system-version='
const countNmbr = '&count=500';
export const getExpandSystemVersionUrlPart = (searchParameter: string, id: string, url: string) => {
    const systemAndId = url + '/' + id
    return '/ValueSet/$expand?_format=json&url=' + systemAndId + '?fhir_vs' + filter + searchParameter +
        systemVersion + url + "|" + systemAndId +
        '&includeDesignations=true' + countNmbr +
        '&elements=expansion.contains.code,expansion.contains.display,expansion.contains.fullySpecifiedName,expansion.contains.active';
}


export const getDataWithParentChildDirection = async (parentChildDirectionData: ValueSet, setConceptCollection: any) => {
    let concepts = [];
    if (parentChildDirectionData !== undefined && parentChildDirectionData.expansion !== undefined && parentChildDirectionData.expansion.contains !== undefined) {
        const concepts = collectContains(parentChildDirectionData);
        concepts.sort(compare);
        setConceptCollection(concepts);
    } else {
        setConceptCollection([]);
    }
}

export const shouldFetchActiveCodeStatus = (conceptArray: any[], system: string | undefined) => {
    var shouldFetchActiveCodeStatus = false;
    if (system !== undefined &&
        conceptArray !== undefined && conceptArray.length != 0 && conceptArray[0].active === undefined) {
        shouldFetchActiveCodeStatus = system !== undefined && isSnomedSystem(system);
    }
    return shouldFetchActiveCodeStatus;
}

export const getActiveCodesOnly = async (codes: string[], version: string) => {
    let activeCodes: string[] = [];

    if (codes.length != 0) {
        let responseValueSet: ValueSet = await requestActiveOnly(codes, version);
        if (responseValueSet !== undefined && responseValueSet.expansion !== undefined &&
            responseValueSet.expansion.total !== undefined &&
            // @ts-ignore
            responseValueSet.expansion.total != 0) {
            activeCodes = extractCodes(responseValueSet.expansion.contains);
        }
    }
    return activeCodes
}


