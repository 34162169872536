import React, {useState} from "react";
import {
    IDSStepper,
    IDSStep,
    IDSCol,
    IDSRadioGroup,
    IDSContainer,
    IDSRow,
    IDSRadio,
    IDSButton,
    IDSButtonGroup
} from "@inera/ids-react";
import Search from "../../search/Search";
import ImportExcelForValueSet from "../../../../services/excel/ImportExcelForValueSet";
import {isSnomedSystem} from "../../../../codesystem/snomed/util/SnomedUrlUtil";
import {
    fetchedDataWithNoErrors,
    hasImportErrorValidations, getIsImportStepOneComplete
} from "../../../../services/excel/ImportCommonFunctions";
import {
    importExcelForValueSetForm,
    importReplaceMethod,
    importUpdateMethod, valueset
} from "../../../../model/defaultvalues/Constant";
import VSImportCancelLink from "../VSImportCancelLink";
import {useFormContext} from "react-hook-form";
import {getIsTabCodeSystemSelected} from "../util/VSCommonFunctions";
import {getActiveCodesOnly} from "../../../../codesystem/snomed/expand/SnomedExpandUtil";

let renderCount = 0;

const VSImportCodeSystemTabs = (props) => {

    // on change states
    const [excelFile, setExcelFile] = useState(null);
    const formRef = React.useRef();

    // submit
    const [excelData, setExcelData] = useState(null);
    // it will contain array of objects


    const [codesNotInCodeSystem, setCodesNotInCodeSystem] = useState(null);
    const [displaysNotInCodeSystem, setDisplaysNotInCodeSystem] = useState(null);
    const hasErrors = hasImportErrorValidations(codesNotInCodeSystem, displaysNotInCodeSystem);


    const stepOneHeadline = "Ange den källa som urvalet är baserat på";
    const stepTwoHeadline = "Välj en Excel-fil att importera";
    const stepThreeHeadline = "Välj importmetod";

    const isTabCodeSystemSelected = getIsTabCodeSystemSelected(props.tabList, props.tabKey);


    const isStepOneComplete = getIsImportStepOneComplete(isTabCodeSystemSelected, props.tabList, props.tabKey);
    const [isStepTwoValidated, setIsStepTwoValidated] = useState(false);
    const stepThreeDisabled = (isStepTwoValidated && (excelData != null) && !hasErrors) ? false : true;

    const [showImport, setShowImport] = useState(true);

    /*    const [selectImportMethodNeeded, setSelectImportMethodNeeded] = useState(false);*/
    const [selectedImportMethod, setSelectedImportMethod] = useState(null);

    const methods = useFormContext();

    const setImportStateFalse = () => {
        var array = [...props.tabList]; // make a separate copy of the array
        array[props.tabKey].importtab = false;
        props.setTabList(array);

    }

    const setSelected = (selected, code, conceptArray) => {
        return conceptArray.map(concept => {
            return concept.code === code ? {...concept, selected: selected} : concept
        })
    }

    const addCodesToCodeSystemsWithAllCodesSelectableCode_selectedWithoutDuplicates = (collection) => {
        for (let c of collection) {
            //add if not already existing
            if (props.codeSystemWithAllCodesSelectableCode.concept.find(obj => {
                return obj.code === c.code
            }) === undefined) {
                props.codeSystemWithAllCodesSelectableCode.concept.push(c);
            } else {
                modifySelectedConceptInArray(props.codeSystemWithAllCodesSelectableCode.concept, c.code, c.display);
            }
        }
    }

    const modifySelectedConceptInArray = (arr, code, display) => {
        const targetObj = arr.find(obj => obj.code === code);
        if (targetObj) {
            targetObj.selected = true;
            targetObj.display = display;
        }
    };


    const collectSnomedCodeActiveStatus = async (conceptToImportArray, version) => {
        debugger
        const codes = conceptToImportArray.map(a => a.code);
        const activeCodes = await getActiveCodesOnly(codes, version);
        // @ts-ignore
        conceptToImportArray.filter(obj => {
            if (activeCodes.includes(obj.code)) {
                obj.inactive = false;
            } else {
                obj.inactive = true;
            }
        })

        return conceptToImportArray;
    }

    const addCodes = async (conceptToImportArray) => {
        const indexInCodeSystemCodeArrays = props.tabKey - 1; // tabKey 0 is summery
        if (props.codeSystemWithAllCodesSelectableCode.concept === undefined) {
            props.codeSystemWithAllCodesSelectableCode.concept = [];
        } else if (selectedImportMethod === importReplaceMethod) {
            for (let c of props.codeSystemWithAllCodesSelectableCode.concept) {
                c.selected = false;
            }
        }

        const isSnomedCT = isSnomedSystem(props.codeSystemWithAllCodesSelectableCode.url);
        if (!isSnomedCT && props.codeSystemWithAllCodesSelectableCode !== undefined) {

            //update display
            for (let conceptToImport of conceptToImportArray) {
                props.codeSystemWithAllCodesSelectableCode.concept.find(p =>
                    (p.code === conceptToImport.code) && (p.selected = true, true) && (p.display === conceptToImport.display)
                );
            }
        }
        else {

            await collectSnomedCodeActiveStatus(conceptToImportArray, props.codeSystemWithAllCodesSelectableCode.version);

            let collection = [];
            for (let d of conceptToImportArray) {
                d.selected = true;
                collection.push(d);
            }

            addCodesToCodeSystemsWithAllCodesSelectableCode_selectedWithoutDuplicates(collection);
            props.updateSelectedCodeSystemForValueSet(props.codeSystemWithAllCodesSelectableCode, indexInCodeSystemCodeArrays);
        }

        //  ConceptsToAdd:

        if (selectedImportMethod === importReplaceMethod) {
            let newArr = [...props.conceptsToAdd];
            newArr[indexInCodeSystemCodeArrays].concept = [];
            props.setConceptsToAdd(newArr);
        }

        if (props.codeSystemWithAllCodesSelectableCode.concept !== undefined) {
            props.addConcept(conceptToImportArray, props.codeSystemWithAllCodesSelectableCode.url, props.codeSystemWithAllCodesSelectableCode.version, undefined, indexInCodeSystemCodeArrays, props.codeSystemWithAllCodesSelectableCode.id);
        }

        setImportStateFalse();
        setShowImport(false);
    }

    const askImportMethod = () => {
        var ask = false;
        if (isTabCodeSystemSelected) {
            const system = props.tabList[props.tabKey].system;
            const version = props.tabList[props.tabKey].version;
            if (props.conceptsToAdd !== undefined) {
                for (let include of props.conceptsToAdd) {
                    if ((include.system == system) && (include.version == version)) {
                        ask = true;
                        break;
                    }
                }
            }
        }
        return ask;
    }

    const showImportButton = () => {
        const withoutErrors = fetchedDataWithNoErrors(excelData, codesNotInCodeSystem, displaysNotInCodeSystem);
        const stepOneStepTwoHandled = isStepOneComplete && (withoutErrors || isStepTwoValidated);
        const askImportMethodStepHandled = (askImportMethod() && (selectedImportMethod == null)) ? false : true;
        const stepsHandled = (stepOneStepTwoHandled && askImportMethodStepHandled);
        let show = stepsHandled;
        if (askImportMethod()) {
            show = show && !stepThreeDisabled;
        }
        return show;
    }

    const importConcepts = () => {
        addCodes(excelData);
        cleanForm();

        //Summery tab
        props.setTabValue(0);

        scrollToTop()
    }

    const cleanForm = () => {
        document.getElementById(importExcelForValueSetForm).reset();
        formRef.current.reset();
        setExcelData(null);
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const onSetSelectedImportMethod = (e) => {
        setSelectedImportMethod(e.target.value);
    };

    const getStepThreeState = () => {
        let state = "";
        if (isStepOneComplete && isStepTwoValidated && !showImportButton()) {
            state = "selected";
        } else if (isStepOneComplete && isStepTwoValidated && showImportButton()) {
            state = "valid";
        }
        return state;
    };

    const getStepTwoState = () => {
        //isStepOneComplete && isStepTwoValidated ? "valid" : "selected"
        let state = "";

        if (!isStepOneComplete) {
            state = "";
        } else if (isStepOneComplete && isStepTwoValidated) {
            state = "valid";
        } else {
            state = "selected";
        }
        return state;
    };

    renderCount++;

    return (
        <div>
            {/*            {"isStepOneComplete: " + isStepOneComplete}<br/><br/>
            {"isStepTwoValidated: " + isStepTwoValidated}<br/><br/>
            {"stepThreeDisabled: " + stepThreeDisabled}<br/><br/>
            {"isTabCodeSystemInitiallySelected: " +isTabCodeSystemInitiallySelected}<br/><br/>
            {"isTabCodeSystemSelected: " + isTabCodeSystemSelected}<br/><br/>

            {"stepThreeDisabled: " + stepThreeDisabled}<br/><br/>

            {"showImportButton: " + showImportButton()}<br/><br/>*/}


            {/*     {"askImportMethod: " + askImportMethod()}<br/><br/>
            {"showImportButton: " + showImportButton()}<br/><br/>

*/}
            {showImport && <IDSStepper>
                <IDSStep headline={stepOneHeadline} expanded={!isStepOneComplete} disabled={isTabCodeSystemSelected}
                         state={isStepOneComplete ? "valid" : "selected"}>
                    <Search
                        selectedCodeSystemForValueSet={props.codeSystemWithAllCodesSelectableCode}
                        loadSelectedCodeSystemForValueSet={props.loadSelectedCodeSystemForValueSet}
                        setLoadSelectedCodeSystemForValueSet={props.setLoadSelectedCodeSystemForValueSet}
                        searchForCodeSystem={props.searchForCodeSystem} addConcept={props.addConcept}
                        artifactType={props.artifactType}
                        belongsTo={props.belongsTo}
                        tabKey={props.tabKey}
                        getCodeSystemForValueSet={props.getCodeSystemForValueSet}
                        getCodeSystemForValue={props.getCodeSystemForValue}
                        getValueSetCodeSystemIds={props.getValueSetCodeSystemIds}/>
                </IDSStep>
                <IDSStep headline={stepTwoHeadline} disabled={isStepOneComplete ? false : true}
                         state={getStepTwoState()}
                         expanded={isStepOneComplete && !isStepTwoValidated}>
                    <ImportExcelForValueSet
                        excelFile={excelFile}
                        setExcelFile={setExcelFile}
                        formRef={formRef}
                        codeSystemsWithAllCodesSelectableCode={props.codeSystemWithAllCodesSelectableCode}
                        excelData={excelData}
                        setExcelData={setExcelData}
                        codesNotInCodeSystem={codesNotInCodeSystem}
                        setCodesNotInCodeSystem={setCodesNotInCodeSystem}
                        displaysNotInCodeSystem={displaysNotInCodeSystem}
                        setDisplaysNotInCodeSystem={setDisplaysNotInCodeSystem}
                        isStepTwoValidated={isStepTwoValidated}
                        setIsStepTwoValidated={setIsStepTwoValidated}
                        selectImportMethodNeeded={askImportMethod()}
                    />
                </IDSStep>
                {askImportMethod()
                    &&
                    <IDSStep headline={stepThreeHeadline} disabled={stepThreeDisabled}
                             state={getStepThreeState()}
                             expanded={!stepThreeDisabled}>
                        <IDSContainer gutterless={true}>
                            <IDSRow>
                                <IDSCol>
                                    <br/>
                                </IDSCol>
                            </IDSRow>
                            <IDSRow>
                                <IDSCol>
                                    <IDSRadioGroup name="artifactType" required={true}>
                                        <IDSContainer gutterless={true}>
                                            <IDSRow>
                                                <IDSCol cols="4">
                                                    <IDSRadio>
                                                        <input type="radio" value={importReplaceMethod}
                                                               onClick={onSetSelectedImportMethod}
                                                               onChange={onSetSelectedImportMethod}></input>
                                                        <label>Rensa och ersätt alla kodposter</label>
                                                    </IDSRadio>
                                                </IDSCol>
                                                <IDSCol cols="5">
                                                    <IDSRadio>
                                                        <input type="radio" value={importUpdateMethod}
                                                               onClick={onSetSelectedImportMethod}
                                                               onChange={onSetSelectedImportMethod}></input>
                                                        <label>Lägg till eller uppdatera enskilda kodposter</label>
                                                    </IDSRadio>
                                                </IDSCol>
                                                <IDSCol cols="3">
                                                </IDSCol>
                                            </IDSRow>
                                        </IDSContainer>
                                    </IDSRadioGroup>
                                </IDSCol>
                            </IDSRow>
                        </IDSContainer>
                    </IDSStep>
                }
                <div style={{display: 'flex', justifyContent: 'right', alignItems: 'right'}}>
                    <IDSButtonGroup style={{
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'right'
                    }}>
                        <VSImportCancelLink ix={props.tabKey} setImportTab={props.setImportTab}/>
                        <IDSButton size="m" onClick={() => importConcepts()} disabled={!showImportButton()}>
                            Importera koder
                        </IDSButton>
                    </IDSButtonGroup>
                </div>
            </IDSStepper>}
        </div>
    );
};

export default VSImportCodeSystemTabs;
