import React from 'react';

import {collectValidationMessage} from "../../common/validation/MetaValidationRules";
import {definitionMinInfo} from "../meta/MetaWarnInfoConstant";
import {contentTabName} from "../../common/page/TabName";
import {ICodeSystemConcept} from "fhir-typescript-models/dist/src/models/fhir/internal";
import {
    getFieldNameInArrayHierarchy,
    hasRowCellsAllEmptyValues
} from "../../common/rhookform/RHookFormArrayHierarchyFunction";


let renderCount = 0;
const arrayname = "concept"

export const validateInfoDefinition = (value: string, rowindex: string, infoMessageList: [], handleInfoMessageList: any, methods: any, hasRowAllEmptyValues?: boolean) => {
    if (hasRowAllEmptyValues !== undefined && hasRowAllEmptyValues) {
        handleEmptyRowWarningMessages(rowindex, definitionMinInfo, handleInfoMessageList, methods);
    } else {

        let validationResults = []

        let minValidationResult = definitionMinInfoValidation(value, rowindex, infoMessageList, handleInfoMessageList, methods);
        let minValidation = {"label": definitionMinInfo, "message": minValidationResult}
        validationResults.push(minValidation);


        let validationMessage: string = "";
        for (let validationResult of validationResults) {
            if (validationResult.message !== false) {
                if (validationMessage === "") {
                    validationMessage = validationMessage + validationResult.message + " "
                } else {
                    validationMessage = validationMessage + " | " + validationResult.message + " "
                }
            }
        }

        return finalInfoValidationResultHandling(validationMessage, rowindex, methods);
    }
}

const definitionMinInfoValidation = (value: string, rowindex: string, infoMessageList: [], handleInfoMessageList: any, methods: any) => {

    if (JSON.stringify(methods.getValues(arrayname)[Number(rowindex)]) !== undefined) {
        let message = "Beskrivning ofullständig. Lägg till beskrivning."
        let validationResult = value !== undefined && ((value.trim().length != 0 && value.trim().length < 2) && message);
        let validationmessage = collectValidationMessage(message, definitionMinInfo, validationResult, handleInfoMessageList, contentTabName, rowindex);
        return validationmessage;
    } else {
        collectValidationMessage("", definitionMinInfo, false, handleInfoMessageList, contentTabName, rowindex);
        return false;
    }
}


const finalInfoValidationResultHandling = (validationMessage: string, rowindex: string, methods: any) => {
    let rowConceptToValidate = methods.getValues(arrayname)[Number(rowindex)];
    if (validationMessage !== "") {
        rowConceptToValidate.definitionInformation = validationMessage;
        return validationMessage;
    } else {
        rowConceptToValidate.definitionInformation = undefined;
        return false;
    }
}

const handleEmptyRowWarningMessages = (rowindex: string, infoType: string, handleInfoMessageList: any, methods: any) => {
    handleInfoMessageList(null, infoType, rowindex);
    let rowConceptToValidate = methods.getValues(arrayname)[Number(rowindex)];
    rowConceptToValidate.definitionInformation = undefined;
}

export const validateDefinitionAfterSubmit = (concepts: ICodeSystemConcept[], infoMessageList: [], handleInfoMessageList: any, methods: any) => {
    for (let i = 0; i < concepts.length; i++) {
        let concept = concepts[i];
        const name = getFieldNameInArrayHierarchy(i.toString(), "concept", "definition");

        const rowCellsAllEmptyValuesResult : boolean = hasRowCellsAllEmptyValues(name, methods);

        // @ts-ignore
        validateInfoDefinition(concept.definition, i.toString(), infoMessageList, handleInfoMessageList, methods, rowCellsAllEmptyValuesResult);
    }
}

