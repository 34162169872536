import {MRT_Cell} from "material-react-table";
import {ICodeSystemConcept} from "fhir-typescript-models/dist/src/models/fhir/internal";
import IConceptSearchResultItem from "../../../codesystem/snomed/search/model/IConceptSearchResultItem";


const nameOfExpandColumn = 'mrt-row-expand';
const nameOfSelectColumn = 'mrt-row-select'

export const isDataColumn = (cell: MRT_Cell<ICodeSystemConcept>) => {
    return !isExpandColumn(cell) && !isSelectColumn(cell);
};

export const isSearchResultDataColumn = (cell: MRT_Cell<IConceptSearchResultItem>) => {
    return !isSearchResultExpandColumn(cell) && !isSearchResultSelectColumn(cell);
};

const  isSearchResultExpandColumn = (cell: MRT_Cell<IConceptSearchResultItem>) => {
    return cell.column.id.endsWith(nameOfExpandColumn);
};

const  isSearchResultSelectColumn = (cell: MRT_Cell<IConceptSearchResultItem>) => {
    return cell.column.id.endsWith(nameOfSelectColumn);
};


const isExpandColumn = (cell: MRT_Cell<ICodeSystemConcept>) => {
    return cell.column.id.endsWith(nameOfExpandColumn);
};

const isSelectColumn = (cell: MRT_Cell<ICodeSystemConcept>) => {
    return cell.column.id.endsWith(nameOfSelectColumn);
};



