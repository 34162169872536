import {ValueSetComposeIncludeConceptDesignation} from "fhir-typescript-models/dist/src/models/fhir/internal";
import {getUseCodeFromDesignation} from "../SnomedTransformerUtil";
import {preferredForLanguageCode} from "./ConstantsForSynonymTransformer";


export const getRecommendedFromDesignations = (designationArray: Array<ValueSetComposeIncludeConceptDesignation>, languageCode: string): string => {
    let recommendedForLanguageCode = undefined;
    //  @ts-ignore
    for (let designation of designationArray) {
        recommendedForLanguageCode = getRecommendedFromDesignation(designation, languageCode);
        if(recommendedForLanguageCode !== undefined){
            break;
        }
    }
    //  @ts-ignore
    return recommendedForLanguageCode;
}

export const getRecommendedFromDesignation = (designation: ValueSetComposeIncludeConceptDesignation, languageCode: string): string | undefined => {
    let recommendedForLanguageCode = undefined;
    //  @ts-ignore
    if (designation.language === languageCode && getUseCodeFromDesignation(designation) === preferredForLanguageCode) {
        //  @ts-ignore
        recommendedForLanguageCode = designation.value;
    }
    //  @ts-ignore
    return recommendedForLanguageCode;
}