import {useFieldArray, useFormContext} from "react-hook-form";
import {getRenderCountText} from '../RenderCount';
import React, {FC, useEffect, useState} from "react";
import {
    getExtensionIndexForVersionDescription,
} from "../../artifact/versioninfo/VersionDescriptionUtil";
import RHookFormTextAreaForExtension from "./RHookFormTextAreaForExtension";
import {publishTabName} from "../page/TabName";
import {string} from "yup";
import ITabName from "../page/ITabName";
import {
    removeValidationInternalMetaDataWarningFromValidationCollectionList
} from "../validation/ValidationInternalMetaDataWarningRulesUtil";

let renderCount = 0;

interface ExtensionValueProps {
    label: string,
    extensionSystemUrl: string,
    tabName: ITabName,
    editDisabled?: boolean,
    warnValidation?: any,
    handleWarnMessageList?: any
}


const RHookFormTextAreaExtensionValueString: FC<ExtensionValueProps> = ({
                                                                             label,
                                                                             extensionSystemUrl,
                                                                             tabName,
                                                                             editDisabled,
                                                                             warnValidation,
                                                                             handleWarnMessageList
                                                                         }) => {

    renderCount++;

    const methods = useFormContext();
    const [extensionFieldIndex, setExtensionFieldIndex] = useState<any>(undefined);

    const {fields, append, remove} = useFieldArray({
        name: `extension`,
    });

    // trigger on component mount
    useEffect(() => {
        const currenExtensionIndexForTextArea = getExtensionIndexForVersionDescription(extensionSystemUrl, fields);
        if (currenExtensionIndexForTextArea !== extensionFieldIndex) {
            setExtensionFieldIndex(currenExtensionIndexForTextArea);
        }
    }, [methods.getValues("extension")]);


    const fieldNameForVersionDescription = "extension." + extensionFieldIndex + ".valueString";

    useEffect(() => {
        if (handleWarnMessageList !== undefined) {
            removeValidationInternalMetaDataWarningFromValidationCollectionList(fieldNameForVersionDescription, handleWarnMessageList, methods, tabName, undefined);
        }
        // @ts-ignore
    }, [methods.formState.errors?.["extension"]?.[extensionFieldIndex]?.["valueString"]]);

    return (
        <div>
            <RHookFormTextAreaForExtension methods={methods}
                                           label={label}
                                           name={fieldNameForVersionDescription}
                                           editDisabled={editDisabled}
                                           warnFunction={warnValidation}
                                           maxLength={1000} tabName={publishTabName}/><br/>

        </div>
    );
}
export default RHookFormTextAreaExtensionValueString;


