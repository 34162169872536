import {ValueSetComposeIncludeConceptDesignation} from "fhir-typescript-models/dist/src/models/fhir/internal";
import {getUseCodeFromDesignation} from "./SnomedTransformerUtil";

export const fullySpecifiedNameCode: string = "900000000000003001";

export const getFullySpecifiedNameFromDesignation = (designation: ValueSetComposeIncludeConceptDesignation, languageCode: string): string | undefined => {
    let fullySpecifiedNameDesignation = undefined;
    //  @ts-ignore
    if (designation.language === languageCode && getUseCodeFromDesignation(designation) === fullySpecifiedNameCode) {
        //  @ts-ignore
        fullySpecifiedNameDesignation = designation.value;
    }
    //  @ts-ignore
    return fullySpecifiedNameDesignation;
}