import React, {useState, useRef} from "react";
import Pagination from "@material-ui/lab/Pagination";
import Client from 'fhir-kit-client';
import {SEARCHRESULT_ID, VERTICAL_BAR_URL_ENCODING} from "../../../constants";
import {
    IDSCol,
    IDSContainer,
    IDSRow,
    IDSInput,
    IDSButton,
    IDSSelect,
    IDSSpinner, IDSLink
} from "@inera/ids-react";
import Spinner from 'react-bootstrap/Spinner';
import '@mobiscroll/react-lite/dist/css/mobiscroll.min.css';
import '@inera/ids-core/components/form/radio/register';
import '@inera/ids-core/components/form/error-message/register';
import '@inera/ids-core/components/breadcrumbs/register';
import '@inera/ids-core/components/list/register';
import '@inera/ids-core/components/badge/register';
import '@inera/ids-core/components/link/register';
import '@inera/ids-core/components/icon/register';
import '@inera/ids-core/components/notification/badge/register';
import {IRHookSelectOption} from "../../common/rhookform/IRHookSelectOption";
import SearchItem from "./SearchItem";
import SearchSortList from "./SearchSortList";
import {
    fhirUrl,
    urnUUID,
    valueset,
    tagTerminologyServiceBaseSystemUrl, tagTerminologyServiceBaseSystemCode, urnOID, TAG_SECTOR_SYSTEM_URL
} from "../../../model/defaultvalues/Constant";
import {
    getIdentifierSystemFormValue,
    getIdentifierValueText,
    transformSearchResponseEntryIdentifierText
} from "../meta/IdentifierTranslator";

import SearchArtifactChoiceAndFilter from "./SearchArtifactChoiceAndFilter";
import {getSectorTitleForSearchResultItem, removeLeadingZeros} from "../../../services/SectorUtil";
import {SearchNotificationHeader} from "../../common/page/header/SearchNotificationHeader";


import {downloadExcel} from "../../../services/excel/ExportExcel";
import {formLabelOID, formLabelUUID, formOID, formUUID} from "../../../model/defaultvalues/MetaDataDefaultFormValue";

const SearchBase = (props) => {
        const client = new Client({baseUrl: fhirUrl});
        const [foundArtifacts, setFoundArtifacts] = useState([]);
        const searchPhraseRef = useRef();
        const [lastSearchPhrase, setLastSearchPhrase] = useState(undefined);
        const [isRequesting, setIsRequesting] = useState(false);

        const [sortBy, setSortBy] = useState("title");
        const codeSystemsRef = useRef();
        const [page, setPage] = useState(1);
        const [total, setTotal] = useState(0);
        const [count, setCount] = useState(0);
        const pageSizes = [10, 50, 100, 200, 500];
        const [pageSize, setPageSize] = useState(pageSizes[4]);
        const maxNumberOfPages = 10;
        const [searchItem, setSearchItem] = useState(null);
        const [searchPerformed, setSearchPerformed] = useState(false);
        const [latestSearchArtifactType, setLatestSearchArtifactType] = useState(undefined);

        const [searchErrorNotice, setSearchErrorNotice] = useState(false);
        const searchTotalId = "searchTotalId";


        codeSystemsRef.current = foundArtifacts;

        const searchOptions: IRHookSelectOption[] = [
            /*         {value: "title", label: "Titel"},*/
            {value: "publisher", label: "Förvaltare"},
            {value: formOID, label: formLabelOID},
            {value: formUUID, label: formLabelUUID},
            /*   {value: "code", label: "Kodpost"},*/
            {value: "description", label: "Beskrivning"},
            {value: "url", label: "URL"}
        ];


        const download = async () => {
            var exportObjects = []
            for (let item of foundArtifacts) {
                var b = {
                    titel: item.resource.title,
                    version: item.resource.version,
                    beskrivning: item.resource.description,
                    senast_uppdaterad: item.resource.meta.lastUpdated,
                    status: item.resource.status,
                    sektor: getSectorTitleForSearchResultItem(item),
                    identifierare: JSON.stringify(item.resource.identifier)
                };
                exportObjects.push(b);
            }

            downloadExcel(exportObjects, lastSearchPhrase);
        };

        const setArtifactTypeIfInProps = () => {
            if (props.artifactType !== undefined) {
                props.setArtifactType(props.artifactType);
            }
        };
//const getRequestParams = (searchTitle, page, pageSize) => {
//     let params = {};
//
//     if (searchTitle) {
//       params["title"] = searchTitle;
//     }
//
//     if (page) {
//       params["page"] = page - 1;
//     }
//
//     if (pageSize) {
//       params["size"] = pageSize;
//     }
//
//     return params;
//   };
        const getRequestParams = (searchPhrase, page, pageSize, sortRequest) => {
            let params = {};

            searchParams(searchPhrase, params);
            props.filter(params);

            /*    if (page) {
                  params["page"] = page - 1;
                }*/

            if (pageSize) {
                params["_count"] = pageSize;
            }

            params["_elements"] = "description,title,publisher,version,status,identifier,date,url";

            if (sortRequest !== undefined) {
                params["_sort"] = sortRequest + ",title,-version,-_lastUpdated";
            } else if (sortBy !== undefined) {
                params["_sort"] = sortBy + ",title,-version,-_lastUpdated";
            } else {
                params["_sort"] = ",title,-version,-_lastUpdated";
            }

            return params;
        };

        const searchParams = (searchPhrase, params) => {
            if (searchPhrase && (searchItem == null)) {
                params["title:contains"] = searchPhrase;
            } else if (searchPhrase && (searchItem === "title")) {
                params["title:contains"] = searchPhrase;
                //Can only search on identifier not identifier-system https://build.fhir.org/codesystem-search.html#CanonicalResource-identifier
            } else if (searchPhrase && (searchItem === formUUID)) {
                params["identifier"] = searchPhrase.startsWith(urnUUID) ? searchPhrase : (urnUUID + searchPhrase);
            } else if (searchPhrase && (searchItem === formOID)) {
                params["identifier"] = searchPhrase.startsWith(urnOID) ? searchPhrase : (urnOID + searchPhrase);
            } else if (searchPhrase && (searchItem === "publisher")) {
                params["publisher:contains"] = searchPhrase;
            } else if (searchPhrase && (searchItem === "url")) {
                params["url:contains"] = searchPhrase;
            } else if (searchPhrase && (searchItem === "description")) {
                params["description:contains"] = searchPhrase;
            } else if (searchPhrase && (searchItem === "code")) {
                params["code:contains"] = searchPhrase;
            } else if (searchPhrase && (searchItem === "sector")) {
                searchPhrase = removeLeadingZeros(searchPhrase);
                params._tag = TAG_SECTOR_SYSTEM_URL + VERTICAL_BAR_URL_ENCODING + searchPhrase;
            } else if (searchPhrase && (searchItem === "system_admin")) {
                //example: _tag=http://terminology.hl7.org/ValueSet/v3-SeverityObservation|H
                params["_tag"] = tagTerminologyServiceBaseSystemUrl + VERTICAL_BAR_URL_ENCODING + tagTerminologyServiceBaseSystemCode;
            } else if (searchPhrase) {
                params["_id"] = searchPhrase;
            }

            if (searchPhrase && props.belongsTo === valueset) {
                params["status"] = "active";
            }
        }
        const retrieveArtifacts = (sortRequest, getFirstPage) => {
            let nextSearchResultUrl = undefined;
            if (props.nextSearchResultLink !== undefined && props.nextSearchResultLink.length !== 0) {
                nextSearchResultUrl = props.nextSearchResultLink[(page - 1)]
            }

            if (searchPhraseRef.current.value !== undefined && lastSearchPhrase !== undefined &&
                searchPhraseRef.current.value === lastSearchPhrase && nextSearchResultUrl !== undefined &&
                (getFirstPage === undefined || getFirstPage === false)) {
                client
                    .request(nextSearchResultUrl)
                    .then((data) => {
                        if (data.total !== 0) {
                            handleSearchResponse(data);
                        }
                    });
            } else if (searchPhraseRef.current.value !== undefined ||
                props.publisher !== undefined ||
                props.sector !== undefined ||
                props.version !== undefined ||
                props.status !== undefined

            ) {
                const params = getRequestParams(searchPhraseRef.current.value, page, pageSize, sortRequest);

                setArtifactTypeIfInProps();

                let searchFor = props.searchForCodeSystem ? "CodeSystem" : props.artifactType;
                setLastSearchPhrase(searchPhraseRef.current.value)
                setTotal(0);
                props.setNextSearchResultLink([]);
                setIsRequesting(true);
                client
                    .search({resourceType: searchFor, searchParams: params})
                    .then((data) => {
                        handleSearchResponseFirstSelfPageUrl(data);
                        handleSearchResponse(data);
                    })
                    .catch(e => {
                        setIsRequesting(false);
                        setSearchErrorNotice(true);
                        console.error("FhirClient Search Error Response" + JSON.stringify(e));
                    });
            }
            document.getElementById(searchTotalId)?.scrollIntoView();
        };


        const handleSearchResponseFirstSelfPageUrl = (data) => {
            const selfPageRelation = data.link.find(element => element.relation === "self");
            const nextPageRelation = data.link.find(element => element.relation === "next");

            if (selfPageRelation !== undefined) {

                if (props.nextSearchResultLink === undefined) {
                    let array = [];
                    array.push(selfPageRelation.url);
                    console.log("handleSearchResponseFirstSelfPageUrl array: " + JSON.stringify(array))
                    props.setNextSearchResultLink(...array);
                }

                /*                else if (nextPageRelation !== undefined && props.total !== 0) {
                                    console.log("handleSearchResponseFirstSelfPageUrl else if props.nextSearchResultLink: " + JSON.stringify(props.nextSearchResultLink))
                                    if (props.nextSearchResultLink !== undefined && selfPageRelation.url !== undefined) {
                                        props.nextSearchResultLink.push(selfPageRelation.url)
                                        props.setNextSearchResultLink(...props.nextSearchResultLink);
                                    }
                                }*/
            }
        };

        /*       useEffect(retrieveArtifacts, [page, pageSize]);
       */

        const getNextSearchResultLink = (data) => {

            const nextPageRelation = data.link.find(element => element.relation === "next");

            if (nextPageRelation !== undefined && (maxNumberOfPages >= props.nextSearchResultLink.length)) {

                let array = [...props.nextSearchResultLink];
                array.push(nextPageRelation.url)
                props.setNextSearchResultLink(array);

                // console.log("client getNextSearchResultLink nextPageRelation.url: " + JSON.stringify(nextPageRelation.url));
                client
                    .request(nextPageRelation.url)
                    .then((data) => {

                        if (data.total !== 0) {
                            getNextSearchResultLink(data)
                        }
                    });
            }
        };

        const collectValidSearchResults = (searchResultEntries) => {

            if (props.belongsTo === valueset) {
                let validSearchResultEntries = [];
                for (let item of searchResultEntries) {
                    if (isArtefactExcludedFromSearchResult(item.resource.id) == false) {
                        validSearchResultEntries.push(item);
                    }
                }
                return validSearchResultEntries;
            } else {
                return searchResultEntries;
            }
        };

        const isArtefactExcludedFromSearchResult = (itemId) => {
            let isExluded = false;
            if (props.belongsTo === valueset) {
                const alreadyExistingCodeSystemIds = props.getValueSetCodeSystemIds();
                for (let id of alreadyExistingCodeSystemIds) {
                    if (id === itemId) {
                        isExluded = true;
                    }
                }
            }
            return isExluded;
        };


        const handleSearchResponse = (data) => {
            setSearchPerformed(true);
            setLatestSearchArtifactType(props.artifactType);
            if (data.total === 0) {
                setIsRequesting(false);
                setFoundArtifacts([]);
            } else {
                setIsRequesting(false);
                transformSearchResponseEntryIdentifierText(data.entry);
                data.entry = collectValidSearchResults(data.entry)
                const total = data.entry.length;

                for (let item of data.entry) {
                    const sectorIitle = getSectorTitleForSearchResultItem(item)
                    item.resource.sectorTitle = sectorIitle;
                }

                setFoundArtifacts(data.entry);
                setTotal(total);
                let allPagesCountNumber = Math.ceil(total / pageSize);
                setCount(maxNumberOfPages >= allPagesCountNumber ? allPagesCountNumber : maxNumberOfPages);
                /*                if ((props.nextSearchResultLink !== undefined) && (1 >= props.nextSearchResultLink.length)) {
                                    getNextSearchResultLink(data);
                                }*/

                if ((total !== 0) && (1 >= props.nextSearchResultLink.length)) {
                    getNextSearchResultLink(data);
                } else {
                    props.setNextSearchResultLink([]);
                }
            }

            if (props.loadSelectedCodeSystemForValueSet !== undefined) {
                props.setLoadSelectedCodeSystemForValueSet(props.tabKey, false);
            }
        };


        const findBySearchPhrase = () => {
            // console.log("findBySearchPhrase ")
            //  setPage(1);
            retrieveArtifacts();
        };

        const handlePageChange = (event, value) => {
            // console.log("handlePageChange");
            setPage(value);
        };

        const handleSearchItem = (event) => {
            if (searchItem !== event.target.value) {
                setSearchItem(event.target.value);
            }
        };

        const handlePageSizeChange = (event) => {
            props.setNextSearchResultLink([]);
            setPageSize(event.target.value);
            setPage(1);
        };

        const getIdentifierText = (item) => {
            if (item.resource.identifier === undefined) {
                return "";
            } else if (item.resource?.identifier[0].value === undefined) {
                return "";
            } else {
                let value = item.resource.identifier[0].value;
                return getIdentifierValueText(value);
            }
        };

        const getIdentifierSystem = (item) => {
            if (item.resource.identifier === undefined) {
                return "";
            } else if ((item.resource?.identifier[0] !== undefined) && (item.resource?.identifier[0].value === undefined)) {
                return "";
            } else {
                return getIdentifierSystemFormValue(item.resource?.identifier[0])
            }
        };


        const SearchTotalMessage = () => {
            if (searchPerformed) {
                let hitsText = total > 1 ? "träffar" : "träff";
                return (
                    <div>
                        Din sökning på "{lastSearchPhrase}" gav {total} {hitsText}
                    </div>
                );
            }
        };

        const FetchReport = () => {
            if (searchPerformed) {
                return (
                    <div>
                        <IDSLink underlined>
                            <a href="#" onClick={() => {
                                download();
                            }}>HÄMTA RAPPORT </a>
                        </IDSLink>
                    </div>
                );
            }
        };


        const handleSearchIfEnter = (e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                findBySearchPhrase();
            }
        }

        const clearFilters = () => {
            props.clearFilters();
        };

        return (
            <div>
                <SearchNotificationHeader searchErrorNotice={searchErrorNotice}
                                          setSearchErrorNotice={setSearchErrorNotice}/>
                <IDSContainer gutterless={true} id={searchTotalId}>
                    <IDSRow justify="space-between">
                        <IDSCol>
                            <IDSContainer gutterless={true}>
                                <IDSRow>
                                    <IDSCol cols="2">
                                        {/* <div className={'container'}>*/}
                                        <IDSSelect>
                                            <label>Sök på</label>
                                            <select onChange={handleSearchItem}>
                                                <option value="title" disabled="" selected=""
                                                        key={"search_option"}>Titel
                                                </option>
                                                {searchOptions.map((item) => (
                                                    <option value={item.value}>{item.label}</option>
                                                ))}
                                            </select>
                                        </IDSSelect>


                                        {/* </div>*/}
                                    </IDSCol>
                                    <IDSCol cols="10">
                                        <div className={'container'}>
                                            <IDSInput> <label>Värde</label>
                                                <input ref={searchPhraseRef}
                                                       type="text"
                                                       className="form-control"
                                                       onKeyDown={e => handleSearchIfEnter(e)}
                                                /></IDSInput>
                                        </div>
                                    </IDSCol>
                                </IDSRow>
                            </IDSContainer>
                        </IDSCol>
                    </IDSRow>
                    {props.belongsTo !== valueset &&
                        <SearchArtifactChoiceAndFilter
                            clearFilters={clearFilters}
                            artifactTypeChoice={props.artifactTypeChoice}
                            sector={props.sector}
                            publisher={props.publisher}
                            version={props.version}
                            status={props.status}
                            onChangeSector={props.onChangeSector}
                            onChangePublisher={props.onChangePublisher}
                            onChangeStatus={props.onChangeStatus}
                            onChangeVersion={props.onChangeVersion}
                            onChangeArtifactType={props.onChangeArtifactType}
                            belongsTo={props.belongsTo}
                        />

                    }

                    <IDSRow align="end">
                        <IDSCol cols="10">
                        </IDSCol>
                        <IDSCol cols="2">
                            <div className={'container'}>
                                <br/>
                                <IDSButton
                                    className="btn btn-outline-secondary"
                                    type="button"
                                    onClick={findBySearchPhrase}>
                                    Sök
                                </IDSButton>
                                <br/> <br/>
                            </div>
                        </IDSCol>
                    </IDSRow>
                </IDSContainer>
                <IDSContainer gutterless={true}>
                    {props.loadSelectedCodeSystemForValueSet ?
                        <div id={SEARCHRESULT_ID}>
                            {/*Search result when searching Codesystems for ValueSet*/}
                            {props.getCodeSystemForValue(props.tabKey)}
                        </div>
                        :
                        <div id={SEARCHRESULT_ID}>
                            {!isRequesting &&
                                <IDSRow align="end">
                                    <IDSCol cols="7">
                                        {SearchTotalMessage()}
                                    </IDSCol>
                                    <IDSCol cols="2">
                                        {FetchReport()}
                                    </IDSCol>
                                    <IDSCol cols="1"> &nbsp;
                                    </IDSCol>
                                    <IDSCol cols="2">
                                        <SearchSortList
                                            foundArtifacts={foundArtifacts}
                                            sortBy={sortBy}
                                            setSortBy={setSortBy}
                                            setNextSearchResultLink={props.setNextSearchResultLink}
                                            retrieveArtifacts={retrieveArtifacts}
                                        />
                                    </IDSCol>
                                </IDSRow>
                            }
                            <IDSRow>

                                <IDSCol>
                                    <div>
                                        {isRequesting ? (
                                            <Spinner animation="border" role="status">
                                                {/*  <span className="visually-hidden">Söker...</span>*/}
                                                <IDSSpinner live="polite" srtext="Söker..."/>
                                            </Spinner>
                                        ) : (
                                            <div>
                                                {foundArtifacts.map(item => (
                                                    <SearchItem
                                                        getCodeSystemForArtifact={props.getCodeSystemForArtifact}
                                                        title={item.resource.title} publisher={item.resource.publisher}
                                                        id={item.resource.id}
                                                        identifier={item.resource.identifier}
                                                        identifierSystem={getIdentifierSystem(item)}
                                                        version={item.resource.version}
                                                        versionId={item.resource.meta.versionId}
                                                        description={item.resource.description}
                                                        date={item.resource.meta.lastUpdated}
                                                        status={item.resource.status}
                                                        url={item.resource.url}
                                                        type={latestSearchArtifactType}
                                                        belongsTo={props.belongsTo}
                                                        getCodeSystemForValueSet={props.getCodeSystemForValueSet}
                                                        tabKey={props.tabKey}
                                                        sectorTitle={item.resource.sectorTitle}
                                                        setLoadSelectedCodeSystemForValueSet={props.setLoadSelectedCodeSystemForValueSet}/>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                    <br/><br/>
                                    {/*         <div className="mt-3">
                                        {"Resultatposter per sida: "}
                                        <select onChange={handlePageSizeChange} value={pageSize}>
                                            {pageSizes.map((size) => (
                                                <option key={size} value={size}>
                                                    {size}
                                                </option>
                                            ))}
                                        </select>

                                        <Pagination
                                            className="my-3"
                                            count={count}
                                            page={page}
                                            siblingCount={1}
                                            boundaryCount={1}
                                            variant="outlined"
                                            shape="rounded"
                                            onChange={handlePageChange}
                                        />
                                    </div>*/}
                                    {/*  <br/><br/>*/}

                                </IDSCol>
                            </IDSRow>
                        </div>
                    }
                </IDSContainer>
            </div>
        );
    }
;

export default SearchBase;
