import React, {useState} from "react";
import '@inera/ids-core/styles/themes/inera-admin.css';
import '@inera/ids-core/styles/themes/inera.css';
import {
    IDSRow,
    IDSCol,
    IDSButton,
    IDSButtonGroup,
    IDSContainer,
    IDSBreadcrumbs,
    IDSCrumb,
    IDSLink
} from "@inera/ids-react";
import {versionCurrentStyle} from "../../../../styles/TTStyles";
import {getRenderCountText} from "../../RenderCount";
import {useFormContext} from "react-hook-form";
import {permissionToEdit, userPermissionToSeeEditButton} from "../../../../user/PermissionUtil";
import {getArtifactSwedishLabel} from "../../CommonFunctions";
import UpdateButton from "../button/UpdateButton";
import {getSectorTitleFromMetaTag} from "../../../../services/SectorUtil";
import {VersionStatusWatched} from "./VersionStatusWatched";
import {useLocation} from "react-router-dom";
import {hasDraftInVersionArray} from "../../../artifact/meta/readonly/HistoryUtil";

export const ArtifactHeader = (props) => {

    const methods = useFormContext();
    const [sector, setSector] = useState();

    const search = useLocation().search;
    const editValue = new URLSearchParams(search).get('edit');

    let renderCount = 0;

    const getBreadcrumbsCurrent = () => {
        return "Detaljvy: " + methods.getValues("title");
    }

    const getBreadcrumbsSearchLink = () => {
        return (
            <IDSLink>
                <a href="/search">Sök artefakt</a>
            </IDSLink>
        );
    }

    const changeEditDisabled = (reloadArtefact) => {

        if (reloadArtefact !== undefined && reloadArtefact == true) {
            if (editValue !== null && (editValue.toString() === "true")) {
                let url = window.location.href;
                let newUrl = url.replace('&edit=true', '');
                newUrl = newUrl.replace('edit=true', '');
                window.location.href = newUrl;
            } else {
                window.location.reload();
            }
        } else if (props.editDisabled) {
            props.setEditDisabled(false)
            props.setEditModeActivated(true);
        } else {
            props.setEditDisabled(true);
            props.setEditModeActivated(false);
        }
    }

    const setEditStatus = () => {
        return (
            <IDSLink>
                <a href="/search">Sök artefakt</a>
            </IDSLink>
        );
    }

    const getSektor = () => {
        if (sector === undefined) {
            if (methods.getValues("meta") !== undefined && methods.getValues("meta.tag") !== undefined) {
                let tag = methods.getValues("meta.tag");
                if (tag !== undefined) {
                    const sectorName = getSectorTitleFromMetaTag(tag)
                    if (sectorName !== sector) {
                        setSector(sectorName);
                    }
                    return sectorName;
                }
            }
        } else {
            return sector;
        }
    }

    return (
        <IDSContainer gutterless={true}>
            <IDSRow>
                <IDSCol>
                    <br/>
                    <IDSBreadcrumbs
                        current={getBreadcrumbsCurrent()} srlabel="Brödsmulor"
                        lead="Du är här:"><IDSCrumb>{getBreadcrumbsSearchLink()}</IDSCrumb></IDSBreadcrumbs>
                </IDSCol>
            </IDSRow>
            <IDSRow justify="space-between">
                <IDSCol cols="9">
                    <br/><br/>
                    <h1 className="ids-heading-1 ids-small">{methods.getValues("title")}</h1>
                    <p className="body">
                        Sektor:&nbsp;
                        <span style={versionCurrentStyle}>
                                   <strong>{getSektor()}</strong>&nbsp;&nbsp;</span>
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        Artefakt:&nbsp;
                        <span style={versionCurrentStyle}>
                                   <strong>{getArtifactSwedishLabel(props.artifactType)}</strong>&nbsp;&nbsp;</span>
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        Version: <strong>{methods.getValues("version")}</strong>
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        Versionsstatus: <VersionStatusWatched control={methods.control} methods={methods}/>
                    </p> {getRenderCountText(renderCount)}<br/>
                </IDSCol>
                <IDSCol cols="3" align="end">

                    {(permissionToEdit(methods) && !props.editModeActivated && methods.getValues("status") === "draft") &&
                        <IDSButtonGroup>
                            <IDSButton type="button" secondary={true} size="s" onClick={() => changeEditDisabled()}>
                                REDIGERA
                            </IDSButton>
                        </IDSButtonGroup>
                    }
                    {(permissionToEdit(methods) && props.editModeActivated && (props.updateDisabled == false)
                            && (methods.getValues("status") === "draft")) &&
                        <UpdateButton
                            artefactType={props.artifactType}
                            editDisabled={props.updateDisabled}
                            methods={methods}
                            isRequesting={props.isRequesting}
                            changeEditDisabled={changeEditDisabled}
                            isCodesBelongingToFormDirty={props.isCodesBelongingToFormDirty}/>
                    }

                    <br/><br/>
                    {(
                            userPermissionToSeeEditButton(methods) &&
                            (methods.getValues("status") !== undefined) &&
                            (methods.getValues("status") !== "draft") &&
                            !hasDraftInVersionArray(props.versionArray)
                        )
                        &&
                        <IDSButton secondary={true} size="s" onClick={() => props.createNewVersion()}>
                            SKAPA NY VERSION
                        </IDSButton>
                    }
                </IDSCol>
            </IDSRow>
        </IDSContainer>
    );
};
