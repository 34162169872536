import React, {useState} from "react";
import {Tabs, Tab, Grid} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import {
    useGmailTabsStyles,
    useGmailTabItemStyles,
} from '@mui-treasury/styles/tabs';
import {IDSButton, IDSButtonGroup, IDSLink, IDSIcon} from "@inera/ids-react";
import {codesystem, valueset, fhirUrl} from "../../../model/defaultvalues/Constant";
import Client from "fhir-kit-client";
import {defaultCodeSystemForValueSet} from "../../../model/defaultvalues/ValueSetDefaultFormValue";
import {a11yProps, TabPanel} from "../../common/tab/TabCommons";
import Search from "../search/Search";
import VSSelectableCodeSystemTable from "./VSSelectableCodeSystemTable";
import VSImportCodeSystemTabs from "./create/VSImportCodeSystemTabs";
import {TAB_TITLE_DEFAULT, TAB_SUMMERY_TITLE, TAB_VERSION_DEFAULT} from "./VSConstant";
import {isCodeSystemWithoutCodesInMetaDataObject} from "../../common/CommonFunctions";
import {
    getCodeSystemKeyFromTabKeyThatIncludeSummeryTab,
    getIsTabCodeSystemSelected,
    isTabCodesSummery
} from "./util/VSCommonFunctions";
import {isSnomedSystem} from "../../../codesystem/snomed/util/SnomedUrlUtil";
import SnomedCodeDashBoard from "../../../codesystem/snomed/panels/SnomedCodeDashBoard";
import VSIncludeCodeSummary from "./VSIncludeCodeSummary";
import {createSlimRelatedArtefactFromCodeSystem} from "../relatedartefact/RelatedArtefactUtil";
import {createSlimCodeSystem} from "../codesystem/CodeSystemUtil";
import {getSwedishStatusText} from "../../../styles/TTStyles";

const indicatorColors = ['#A33662', '#A33662', '#A33662', '#A33662'];

let renderCount = 0;

const VSTabsForCodeSystem = (props) => {
    const tabItem2Styles = useGmailTabItemStyles({color: indicatorColors[0]});
    const tabsStyles = useGmailTabsStyles({indicatorColors});
    const client = new Client({baseUrl: fhirUrl});


    const [fistEmptyTab, setFistEmptyTab] = useState(true);
    const [tabValue, setTabValue] = useState(0);
    const handleTabChange = (event, value) => {
        setTabValue(value);
    };

    renderCount++;

    const isImportTab = (tabkey) => {
        try {
            return (props.tabList[tabkey].importtab === undefined || props.tabList[tabkey].importtab == false) ? false : true;
        } catch (error) {
            console.error("isImportTab check for tabkey: " + tabkey + ", resulted in error: " + error);
            throw error;
        }
    }

    const setImportTab = (tabkey, isImportTab) => {
        let newArr = [...props.tabList]; // copying the old data array
        newArr[tabkey].importtab = isImportTab;
        props.setTabList(newArr);
    }

    const addTab = (importtab) => {
        let id = (props.tabList === undefined || props.tabList === null || props.tabList.length === 0) ? 0 : props.tabList[props.tabList.length - 1].id + 1;
        // let id = props.tabList.length;
        // props.tabList[props.tabList.length - 1].id + 1;

        const tabHeader = {
            key: id,
            id: id,
            title: TAB_TITLE_DEFAULT,
            version: TAB_VERSION_DEFAULT,
            status: TAB_VERSION_DEFAULT,
            system: undefined,
            loadSelectedCodeSystemForValueSet: false,
            importtab: (importtab === undefined || importtab == false) ? false : true
        }

        props.setTabList([...props.tabList, tabHeader]);
        setTabValue(id);
    };

    const setLoadSelectedCodeSystemForValueSet = (tabKey, state) => {
        var array = [...props.tabList]; // make a separate copy of the array
        array[tabKey].loadSelectedCodeSystemForValueSet = state;
        props.setTabList(array);

    }

    const updateTab = (tabKey, title, version, system, status, createSummeryTab) => {
        if (version == undefined) {
            version = "-";
        }

        var array = [...props.tabList]; // make a separate copy of the array
        if (createSummeryTab) {
            const tabHeader = {
                key: 0,
                id: 0,
                title: TAB_SUMMERY_TITLE,
                loadSelectedCodeSystemForValueSet: false,
                importtab: false
            }
            array.unshift(tabHeader);
        }

        try {
            array[tabKey].title = title;
            array[tabKey].version = version;
            array[tabKey].system = system;
            array[tabKey].status = status;
            array[tabKey].id = tabKey;
            array[tabKey].key = tabKey;
            props.setTabList(array);
        } catch (error) {
            console.error("updateTab tabKey: " + tabKey + ", error: " + error);
            throw error;
        }

        if (createSummeryTab) {
            setTabValue(1);
        }
    };


    const addImportStep = (tabKey, importStep, compleatedStep) => {

        let array = [...props.tabList]; // make a separate copy of the array
        let importstepsUndefined = array[tabKey].importsteps === undefined;

        if (importstepsUndefined) {
            array[tabKey].importsteps = [{importStep, compleatedStep}]
            props.setTabList(array);
        } else if (!importstepsUndefined && array[tabKey].importsteps[importStep] === undefined) {
            array[tabKey].importsteps[importStep] = {importStep, compleatedStep};
            props.setTabList(array);
        }

    };

    const deleteTab = e => {
        e.stopPropagation();

        //Always keep one tab
        /*   if (props.tabList.length === 1) {
               return;
           }*/

        let tabId = parseInt(e.target.id);
        const isNan = Number.isNaN(tabId);

        //tabId == 0 is the summery tab - it can not be removed on its own
        if (!isNan && tabId !== 0) {
            // must delete summery when last(1) codesystem is removed
            const mustRemoveSummery = tabId == 1 && props.tabList.length == 2;

            // remove the codesystem tab
            props.tabList.splice(tabId, 1); // 2nd parameter means remove one item only

            // remove summery
            if (mustRemoveSummery) {
                props.tabList.splice(0, 1);
            }

            var newTabList = [...props.tabList]; // make a separate copy of the array

            for (let i = 0; i < newTabList.length; i++) {
                let tab = newTabList[i];
                tab.key = i;
                tab.id = i;
            }

            let tabIDIndex = 0;

            let curValue = parseInt(tabValue);

            if (
                //curValue === tabId &&
                newTabList.length > 0) {
                if (tabIDIndex == 0 && curValue > 0) {
                    curValue = curValue - 1;
                }
            } else if (newTabList.length == 0) {
                curValue = 0;
            }

            setTabValue(curValue);
            props.setTabList(newTabList);

            // first tab is summery, so codeSystemFromValueSet index is (selectedTab -1)
            const codeSystemIndex = getCodeSystemKeyFromTabKeyThatIncludeSummeryTab(tabId);


            props.removeCodeSystemFromValueSet(codeSystemIndex);
        } else {
            console.error("Tabnumber is not number(isNan), tabnumber: " + tabId);
        }
    };

    const addToSelectedCodeSystemForValueSet = (data, codeSystemIndex) => {
        let newArr = [...props.codeSystemsWithAllCodesSelectableCodes]; // copying to new array

        const codeSystem = createSlimCodeSystem(data);

        newArr[codeSystemIndex] = codeSystem;
        props.setCodeSystemsWithAllCodesSelectableCodes(newArr);

        const relatedArtefact = createSlimRelatedArtefactFromCodeSystem(codeSystem)
        props.setRelatedArtifactsArray([...props.artifactArray, relatedArtefact]);


    };

    const updateSelectedCodeSystemForValueSet = (data, tabkey) => {
        var array = [...props.codeSystemsWithAllCodesSelectableCodes]; // make a separate copy of the array
        array[tabkey] = data;
        props.setCodeSystemsWithAllCodesSelectableCodes(array);
    };

    const hasCodeSystemFetchOnIdCodes = (codesystem) => {
        debugger
        if (!isCodeSystemWithoutCodesInMetaDataObject(codesystem.url) && (codesystem.concept === undefined || codesystem.concept.length === 0)) {
            alert("Kodverket som du valt: " + codesystem.title + ", innehar inga koder")
            return false;
        } else {
            return true;
        }
    };

    const getVSSelectableCodeSystemTable = (tabKey, codesystem) => {
        return (
            <VSSelectableCodeSystemTable
                conceptsToAdd={props.conceptsToAdd}
                setConceptsToAdd={props.setConceptsToAdd}
                handleChange={props.handleChange}
                tabKey={tabKey}
                data={codesystem.concept}
                removeConceptToValueSet={props.removeConceptToValueSet}
                codesystem={codesystem}/>
        );
    };

    const getVSSelectableCodeSystemTableOrAddCodesImportStep = (tabKey) => {
        const importTab = isImportTab(tabKey);
        let codeSystemIndex = getCodeSystemKeyFromTabKeyThatIncludeSummeryTab(tabKey);
        let indexOfConceptToAdd = 0;

        //get indexOfConceptToAdd
        if (props.conceptsToAdd !== undefined &&
            props.conceptsToAdd[indexOfConceptToAdd] !== undefined) {


            if (
                (props.conceptsToAdd[indexOfConceptToAdd].system !== props.tabList[tabKey].system) ||
                (props.conceptsToAdd[indexOfConceptToAdd].version !== props.tabList[tabKey].version)) {
                let codeSystemIndexCorrected = false;
                for (var i = 0; i < props.conceptsToAdd.length; i++) {
                    let includeOfConceptToAdd = props.conceptsToAdd[i]
                    if ((includeOfConceptToAdd.system === props.tabList[tabKey].system) && (includeOfConceptToAdd.version === props.tabList[tabKey].version)) {
                        indexOfConceptToAdd = i;
                        codeSystemIndexCorrected = true;
                        break;
                    }
                }
                if (!codeSystemIndexCorrected) {
                    if (props.conceptsToAdd === undefined) {
                        indexOfConceptToAdd = 0;
                    } else {
                        indexOfConceptToAdd = props.conceptsToAdd.length + 1;
                    }
                }
            }
        } else {
            indexOfConceptToAdd = 0;
        }


        let isImportStepOneComplete = false;
        if (importTab) {
            if (props.tabList[tabKey].importsteps !== undefined) {
                isImportStepOneComplete = (props.tabList[tabKey].importsteps[1] === undefined) ? false : true;
            }
        }

        if ((importTab && isImportStepOneComplete) || !importTab) {
            let codesystem;
            //codeSystemsWithAllCodesSelectableCodes
            if (props.codeSystemsWithAllCodesSelectableCodes === undefined) {
                codesystem = [defaultCodeSystemForValueSet];
            } else {
                if (tabKey === undefined) {
                    codesystem = props.codeSystemsWithAllCodesSelectableCodes[1];
                } else {
                    codesystem = props.codeSystemsWithAllCodesSelectableCodes[codeSystemIndex];
                }
            }

            if (codesystem === undefined) {
                codesystem = [defaultCodeSystemForValueSet];
            }


            return (
                <div>
                    {getImportButton(tabKey)}
                    <div className={'container'}>
                        {/*  <div>{"props.conceptsToAdd: " + JSON.stringify(props.conceptsToAdd, undefined, 4)} <br/> <br/></div>*/}
                        {isSnomedSystem(codesystem.url) ?
                            <SnomedCodeDashBoard
                                id={codesystem.id}
                                version={codesystem.version}
                                tabKey={tabKey}
                                codeSystemIndex={codeSystemIndex}
                                handleChange={props.handleChange}
                                snomedConceptsToAdd={props.conceptsToAdd[indexOfConceptToAdd] === undefined ? undefined : props.conceptsToAdd[indexOfConceptToAdd].concept}
                                changeFromMenu={props.changeFromMenu}/>
                            : getVSSelectableCodeSystemTable(codeSystemIndex, codesystem)
                        }
                    </div>
                </div>

            );
        } else if (importTab
            //  && !isImportStepOneComplete
        ) {
            addImportStep(tabKey, 0, true);
            return (
                <div>
                </div>
            );
        }
    }

    const getCodeSystemForValueSet = async (id, version, tabkey, url) => {
        debugger
        let codesystemAlreadySelectedIndex = undefined;

        for (let i = 0; i < props.codeSystemsWithAllCodesSelectableCodes.length; i++) {
            var codesystem = props.codeSystemsWithAllCodesSelectableCodes[i];
            if (codesystem.id === id) {
                codesystemAlreadySelectedIndex = i;
            }
        }

        if (id && codesystemAlreadySelectedIndex === undefined) {


            let createSummaryTab = false;
            if (tabkey == 0) {
                createSummaryTab = true;
                tabkey = 1
            }

            const codeSystemIndex = getCodeSystemKeyFromTabKeyThatIncludeSummeryTab(tabkey);

            //   const isImportTab = (props.tabList[tabkey].importtab === undefined || props.tabList[tabkey].importtab == false) ? false : true;
            await client.vread({resourceType: 'CodeSystem', id: id, version: version}).then((data) => {
                debugger
                if (data && hasCodeSystemFetchOnIdCodes(data)) {
                    debugger
                    addToSelectedCodeSystemForValueSet(data, codeSystemIndex);

                    setLoadSelectedCodeSystemForValueSet(codeSystemIndex, true);

                    updateTab(tabkey, data.title, data.version, data.url, data.status, createSummaryTab);

                    if (props.conceptsToAdd[codeSystemIndex] != undefined) {
                        let newConceptsToAddArr = [...props.conceptsToAdd]; // copying to new array
                        newConceptsToAddArr[codeSystemIndex].id = data.id;
                        newConceptsToAddArr[codeSystemIndex].concept = [];
                        newConceptsToAddArr[codeSystemIndex].system = data.url;
                        newConceptsToAddArr[codeSystemIndex].status = data.status;
                        newConceptsToAddArr[codeSystemIndex].version = data.version;
                        props.setConceptsToAdd(newConceptsToAddArr);
                    }
                }
            });

        } else {
            alert("Kodsystemet finns redan i Urvalet");
        }
    };

    const loadTabs = () => {
        /*      if (!props.initUpdate) {

                  if (fistEmptyTab) {
                      props.setTabList([
                          {
                              key: 0,
                              id: 0,
                              title: 'Kodverk',
                              version: '-',
                              system: undefined
                          }
                      ]);
                      setFistEmptyTab(false);
                  }
                  return loadTabsStep2();
              } else {*/

        let tabs = [];

        for (let i = 0; i < props.artifactArray.length; i++) {

            let version = "-";
            if (props.artifactArray[i].resource.version !== undefined) {
                version = props.artifactArray[i].resource.version;
            }


            let tab = {
                key: i,
                id: i,
                title: props.artifactArray[i].resource.title,
                version: version,
                system: props.artifactArray[i].resource.url,
                status: props.artifactArray[i].resource.status
            }

            if (props.artifactArray[i].resource.version !== undefined) {
                tab.version = props.artifactArray[i].resource.version;
            }

            tabs.push(tab)
        }

        if (fistEmptyTab) {
            props.setTabList(tabs);
            setFistEmptyTab(false);
        }
        return loadTabsStep2();
        /* }*/
    }

    const getValueSetCodeSystemIds = () => {
        let codeSystemIds = [];
        if (props.codeSystemsWithAllCodesSelectableCodes !== undefined) {
            for (let i = 0; i < props.codeSystemsWithAllCodesSelectableCodes.length; i++) {
                codeSystemIds.push(props.codeSystemsWithAllCodesSelectableCodes[i].id)
            }
        }
        return codeSystemIds;
    }
    const getImportButton = (tabindex) => {
        return (
            <IDSButtonGroup style={{
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'right'
            }}>
                <IDSButton secondary onClick={() => setImportTab(tabindex, true)}
                           size="s">
                    {/* IMPORTERA URVAL FRÅN FIL*/}
                    IMPORTERA KODER FRÅN FIL
                </IDSButton>
            </IDSButtonGroup>
        );
    }

    const loadTabsStep2 = () => {

        return (
            <div>
                <Grid container alignItems="center" justifyContent="flex-start">
                    <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                        <Tabs
                            classes={tabsStyles}
                            value={tabValue}
                            onChange={handleTabChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            TabIndicatorProps={{
                                children: <div className={`MuiIndicator-${tabValue}`}/>,
                            }}
                        >
                            {props.tabList.map(tab => (
                                <Tab
                                    key={tab.key.toString()}
                                    value={tab.id}
                                    label={
                                        <div className={'MuiTabItem-labelGroup'}>
                                            <div className={'MuiTabItem-label'}>
                                                <strong>{tab.title}</strong>
                                                {/*<span className={'MuiTabItem-tag'}>Codesystem</span>*/}
                                            </div>
                                            <div className={'MuiTabItem-subLabel'}>
                                                {
                                                    tab.version !== undefined &&
                                                    <span>Version: <strong>{tab.version}</strong></span>
                                                }<br/>
                                                {tab.status !== undefined &&
                                                    ("Status: " + getSwedishStatusText(tab.status))
                                                }
                                            </div>
                                        </div>}
                                    icon={
                                        <Close id={tab.id} onClick={deleteTab} style={{width: '25px'}}/>}
                                    /* className="mytab"*/
                                    classes={tabItem2Styles}
                                    {...a11yProps(tab.key.toString())}
                                />
                            ))}
                        </Tabs>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                        <IDSButtonGroup>
                            <IDSLink underlined="true" size="1em">
                                <IDSIcon name={"plus"} size={"xs"}/>
                                <a href="#" onClick={() => {
                                    addTab();
                                }}>
                                    LÄGG TILL KODVERK</a>
                            </IDSLink>
                        </IDSButtonGroup>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" justifyContent="flex-start">
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        {props.tabList.map((tab, ix) => (
                            <div>
                                <TabPanel value={tabValue} index={tab.key}>
                                    <div>
                                        {(tab.importtab === undefined || tab.importtab == false) ? (
                                            <div>
                                                {
                                                    (isTabCodesSummery(props.tabList, ix)) ?
                                                        <div>
                                                            <VSIncludeCodeSummary
                                                                artifactArray={props.artifactArray}
                                                                codeSystemsWithAllCodesSelectableCodes={props.codeSystemsWithAllCodesSelectableCodes}
                                                                conceptsToAdd={props.conceptsToAdd}
                                                                removeConceptToValueSet={props.removeConceptToValueSetFromMenu}
                                                            />
                                                        </div>
                                                        :
                                                        getIsTabCodeSystemSelected(props.tabList, ix) ?
                                                            getVSSelectableCodeSystemTableOrAddCodesImportStep(tab.key)
                                                            :
                                                            <div>
                                                                {getImportButton(ix)}
                                                                <Search
                                                                    setSelectedCodeSystemForValueSetIndex={ix}
                                                                    loadSelectedCodeSystemForValueSet={tab.loadSelectedCodeSystemForValueSet}
                                                                    setLoadSelectedCodeSystemForValueSet={setLoadSelectedCodeSystemForValueSet}
                                                                    searchForCodeSystem={true}
                                                                    artifactType={codesystem}
                                                                    belongsTo={valueset}
                                                                    removeConceptToValueSet={props.removeConceptToValueSet}
                                                                    tabKey={tab.key}
                                                                    getCodeSystemForValueSet={getCodeSystemForValueSet}
                                                                    getCodeSystemForValue={getVSSelectableCodeSystemTableOrAddCodesImportStep}
                                                                    getValueSetCodeSystemIds={getValueSetCodeSystemIds}/>
                                                            </div>
                                                }


                                                {/*                 {getIsTabCodeSystemSelected(props.tabList, ix) ?
                                                    getVSSelectableCodeSystemTableOrAddCodesImportStep(tab.key)
                                                    :
                                                    <Search
                                                        setSelectedCodeSystemForValueSetIndex={ix}
                                                        loadSelectedCodeSystemForValueSet={tab.loadSelectedCodeSystemForValueSet}
                                                        setLoadSelectedCodeSystemForValueSet={setLoadSelectedCodeSystemForValueSet}
                                                        searchForCodeSystem={true} addConcept={props.addConcept}
                                                        artifactType={codesystem}
                                                        belongsTo={valueset}
                                                        removeConceptToValueSet={props.removeConceptToValueSet}
                                                        tabKey={tab.key}
                                                        getCodeSystemForValueSet={getCodeSystemForValueSet}
                                                        getCodeSystemForValue={getVSSelectableCodeSystemTableOrAddCodesImportStep}
                                                        getValueSetCodeSystemIds={getValueSetCodeSystemIds}/>
                                                }*/}
                                            </div>
                                        ) : (

                                            <div>
                                                {/*                                                <IDSButtonGroup style={{
                                                    display: 'flex',
                                                    justifyContent: 'right',
                                                    alignItems: 'right'
                                                }}>
                                                    <VSImportCancelButton ix={ix} setImportTab={setImportTab} buttonSize={"s"}/>
                                                </IDSButtonGroup>*/}
                                                <br/>

                                                <VSImportCodeSystemTabs
                                                    tabList={props.tabList}
                                                    setTabList={props.setTabList}
                                                    codeSystemWithAllCodesSelectableCode={props.codeSystemsWithAllCodesSelectableCodes[getCodeSystemKeyFromTabKeyThatIncludeSummeryTab(tabValue)]}
                                                    updateSelectedCodeSystemForValueSet={updateSelectedCodeSystemForValueSet}
                                                    setSelectedCodeSystemForValueSetIndex={ix}
                                                    loadSelectedCodeSystemForValueSet={tab.loadSelectedCodeSystemForValueSet}
                                                    setLoadSelectedCodeSystemForValueSet={setLoadSelectedCodeSystemForValueSet}
                                                    searchForCodeSystem={true}
                                                    addConcept={props.addConcept}
                                                    artifactType={codesystem}
                                                    belongsTo={valueset}
                                                    removeConceptToValueSet={props.removeConceptToValueSet}
                                                    tabKey={tabValue}
                                                    getCodeSystemForValueSet={getCodeSystemForValueSet}
                                                    getCodeSystemForValue={getVSSelectableCodeSystemTableOrAddCodesImportStep}
                                                    conceptsToAdd={props.conceptsToAdd}
                                                    setConceptsToAdd={props.setConceptsToAdd}
                                                    getValueSetCodeSystemIds={getValueSetCodeSystemIds}
                                                    setImportTab={setImportTab}
                                                    setTabValue={setTabValue}/>
                                            </div>
                                        )}
                                    </div>
                                </TabPanel>
                            </div>
                        ))}

                    </Grid>
                </Grid>
            </div>
        );
    };

    return (
        <div>{loadTabs()}</div>
    );
};

export default VSTabsForCodeSystem;
