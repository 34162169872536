import React from 'react';

import {collectValidationMessage} from "../../common/validation/MetaValidationRules";
import {displayDuplicateInfo} from "../meta/MetaWarnInfoConstant";
import {contentTabName} from "../../common/page/TabName";


let renderCount = 0;
const arrayname = "concept"

export const infoValidateDisplay = (value: string, prevValue: string, rowindex: string, infoMessageList: [], handleInfoMessageList: any, methods: any, hasRowAllEmptyValues?: boolean) => {

    if (hasRowAllEmptyValues !== undefined && hasRowAllEmptyValues) {
        handleEmptyRowInfoMessages(rowindex, displayDuplicateInfo, handleInfoMessageList, methods);
    } else {

        let validationResults = []


        let specialCharactersValidationResult = displayHasDuplicateValidation(value, prevValue, rowindex, handleInfoMessageList, methods);
        let specialCharactersValidation = {
            "label": displayDuplicateInfo,
            "message": specialCharactersValidationResult
        }
        validationResults.push(specialCharactersValidation);


        let validationMessage: string = "";
        for (let validationResult of validationResults) {
            if (validationResult.message !== false) {
                if (validationMessage === "") {
                    validationMessage = validationMessage + validationResult.message + " "
                } else {
                    validationMessage = validationMessage + " | " + validationResult.message + " "
                }
            }
        }
        return finalInfoValidationResultHandling(validationMessage, rowindex, methods);

    }
}

const finalInfoValidationResultHandling = (validationMessage: string, rowindex: string, methods: any) => {
    let rowConceptToValidate = methods.getValues(arrayname)[Number(rowindex)];
    if (validationMessage !== "") {
        rowConceptToValidate.displayInfo = validationMessage;
        return validationMessage;
    } else {
        rowConceptToValidate.displayInfo = undefined;
        return false;
    }
}

const handleEmptyRowInfoMessages = (rowindex: string, warningType: string, handleInfoMessageList: any, methods: any) => {
    handleInfoMessageList(null, warningType, rowindex);
    let rowConceptToValidate = methods.getValues(arrayname)[Number(rowindex)];
    if (rowConceptToValidate != undefined) {
        rowConceptToValidate.displayInfo = undefined;
    }
}


const displayHasDuplicateValidation = (value: string, prevValue: string, rowindex: string, handleInfoMessageList: any,
                                       methods: any) => {
    const concepts = methods.getValues(arrayname);
    var areEqual = false;
    if (value !== undefined) {
        for (let i = 0; i < concepts.length; i++) {
            //Only compare with rows that is not itself
            if (Number(rowindex) != i) {
                let concept = concepts[i];
                if (concept.display !== undefined) {
                    if ((prevValue !== undefined && prevValue.trim() !== "") &&
                        prevValue.toUpperCase() === concept.display.toUpperCase() && concept.displayInfo != undefined) {
                        concept.displayInfo = undefined;
                        collectValidationMessage("", displayDuplicateInfo, false, handleInfoMessageList, contentTabName, i.toString());
                        return areEqual;
                    }
                    if (value.trim() != "" && concept.display.trim() !== "" &&
                        value.toUpperCase() === concept.display.toUpperCase()) {
                        let message = "Samma klartext används på flera ställen i kodverket. Detta är tillåtet, men rekommenderas ej, då det kan ge problem i andra system som använder kodverket."
                        let validationmessage = collectValidationMessage(message, displayDuplicateInfo, message, handleInfoMessageList, contentTabName, rowindex);
                        areEqual = true;
                        return message;
                    }
                }
            }

        }
    }
    //remove validationMessage if any
    if (areEqual == false) {
        collectValidationMessage("", displayDuplicateInfo, false, handleInfoMessageList, contentTabName, rowindex);
    }
    return false
}