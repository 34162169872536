import {ValueSetExpansionContains} from "fhir-typescript-models/dist/src/models/fhir/internal";
import {
    getSwedishRecommendedFromDesignations,
    getSwedishSynonymsAsStringsFromDesignation, getSwedishSynonymsFromContains
} from "./SynonymSwedishLanguageTransformer";
import {
    getAcceptableEnglishLanguageSynonymsFromDesignations,
    getEnglishSynonymsFromContains
} from "./SynonymEnglishLanguageTransformer";
import IConceptSynonymsAll from "../../search/model/IConceptSynonymsAll";
import IConceptSynonymsSwedish from "../../search/model/IConceptSynonymsSwedish";
import IConceptSynonymsEnglish from "../../search/model/IConceptSynonymsEnglish";


export const getAllSynonyms = (synonymSwedish: IConceptSynonymsSwedish | undefined, synonymEnglish: IConceptSynonymsEnglish | undefined): IConceptSynonymsAll | undefined => {
    let synonymsSwedish: IConceptSynonymsAll | undefined = undefined;
    if (synonymSwedish !== undefined || synonymEnglish !== undefined) {
        synonymsSwedish = {
            synonymsSwedish: synonymSwedish,
            synonymsEnglish: synonymEnglish
        };
    }
    return synonymsSwedish;
}

export const getAllSynonymsFromContains = (contains: ValueSetExpansionContains): IConceptSynonymsAll | undefined => {

    const swedishSynonyms: IConceptSynonymsSwedish | undefined = getSwedishSynonymsFromContains(contains);
    const englishSynonyms: IConceptSynonymsEnglish | undefined = getEnglishSynonymsFromContains(contains);

    if (swedishSynonyms === undefined && englishSynonyms === undefined) {
        return undefined;
    } else {
        return {
            synonymsEnglish: englishSynonyms,
            synonymsSwedish: swedishSynonyms
        };
    }
}

export const getSwedishOrDefaultSynonymsAsStringsFromDesignation = (contains: ValueSetExpansionContains): string[] => {
    let synonyms: string[] = [];
    if (contains.designation !== undefined) {
        const swedishDisplay = getSwedishRecommendedFromDesignations(contains.designation);

        if (swedishDisplay !== undefined) {
            synonyms = getSwedishSynonymsAsStringsFromDesignation(contains.designation);
        } else {
            synonyms = getAcceptableEnglishLanguageSynonymsFromDesignations(contains.designation);
        }
    }
    return synonyms;
}

export const getSwedishOrDefaultSynonymsAsStringsFromConceptSynonymsAll = (allSynonyms: IConceptSynonymsAll | undefined): string[] => {
    let synonyms: string[] = [];
    if (allSynonyms !== undefined) {

        const hasSweRecSynonyms = hasSwedishRecommended(allSynonyms);
        const hasSweAccSynonyms = hasSwedishAcceptableSynonyms(allSynonyms);
        if (hasSweRecSynonyms && hasSweAccSynonyms) {
            // @ts-ignore
            for (const swedishSynonym of allSynonyms.synonymsSwedish.acceptableList) {
                synonyms.push(swedishSynonym.value)
            }
        } else if (!hasSweRecSynonyms &&
            allSynonyms.synonymsEnglish !== undefined &&
            allSynonyms.synonymsEnglish.acceptableList !== undefined &&
            (allSynonyms.synonymsEnglish.recommendedUS !== undefined || allSynonyms.synonymsEnglish.recommendedUK !== undefined)) {


            for (const englishSynonym of allSynonyms.synonymsEnglish.acceptableList) {
                synonyms.push(englishSynonym.value)
            }
        }
    }
    return synonyms;
}

 const hasSwedishAcceptableSynonyms = (allSynonyms: IConceptSynonymsAll | undefined): boolean => {
    let has: boolean = false;
    if (allSynonyms !== undefined) {

        if (allSynonyms.synonymsSwedish !== undefined &&
            allSynonyms.synonymsSwedish.acceptableList !== undefined
        ) {
            has = true;
        }
    }
    return has;
}

const hasSwedishRecommended = (allSynonyms: IConceptSynonymsAll | undefined): boolean => {
    let has: boolean = false;
    if (allSynonyms !== undefined) {
        if (allSynonyms.synonymsSwedish !== undefined &&
            allSynonyms.synonymsSwedish.recommended !== undefined
        ) {
            has = true;
        }
    }
    return has;
}