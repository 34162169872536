import * as React from "react";
import RHookFormWarnInfoController from "./RHookFormWarnInfoController";
import {IDSErrorMessage, IDSInput} from "@inera/ids-react";
import {errorStyle, statusActiveStyle, statusDraftStyle} from "../../../styles/TTStyles";
import {FC, useEffect, useState} from "react";
import RHookFormValidationMessage from "../validation/RHookFormValidationMessage";
import ITabName from "../page/ITabName";
import {getErrorMessage} from "./RHookFormMessageUtil";
import {
    removeValidationInternalMetaDataWarningFromValidationCollectionList
} from "../validation/ValidationInternalMetaDataWarningRulesUtil";
import {
    collectValidationInternalMetaDataYupErrorRules
} from "../validation/ValidationInternalMetaDataYupErrorRulesUtil";
import {removeTabsInValue} from "./FieldUtil";

interface RHookFormTextFieldControllerProps {
    name: string,
    label?: string,
    tabName?: ITabName,
    methods: any,
    warn?: any,
    info?: any,
    toucherror?: any,
    handleWarnMessageList?: any
    editDisabled: boolean,
    rowindex?: string
}

const RHookFormTextFieldController: FC<RHookFormTextFieldControllerProps> = ({
                                                                                 name,
                                                                                 label,
                                                                                 tabName,
                                                                                 methods,
                                                                                 warn,
                                                                                 info,
                                                                                 toucherror,
                                                                                 handleWarnMessageList,
                                                                                 editDisabled,
                                                                                 rowindex
                                                                             }) => {


    const [previousYupValidation, setPreviousYupValidation] = useState<string>();
    const arrayName = rowindex === undefined ? "" : name.substring(0, name.indexOf("."));

    useEffect(() => {
        if (tabName !== undefined) {
            collectValidationInternalMetaDataYupErrorRules(name, previousYupValidation, setPreviousYupValidation, handleWarnMessageList, methods, tabName, rowindex);
            removeValidationInternalMetaDataWarningFromValidationCollectionList(name, handleWarnMessageList, methods, tabName, undefined)
        }
        //methods.formState.errors?.['identifier']?.[0]?.['value']?.['message']

    }, [methods.formState.errors[name], methods.formState.errors[arrayName]]);


    return (
        <RHookFormWarnInfoController
            control={methods.control}
            name={name}
            defaultValue=""
            warn={warn !== undefined ? warn : undefined}
            info={info !== undefined ? info : undefined}
            toucherror={toucherror !== undefined ? toucherror : undefined}
            methods={methods}
            render={({
                         // @ts-ignore
                         value, onChange, onBlur, name, isDirty, isTouched, warning, info, toucherror
                     }) => {
                return (
                    <>
                        <div className={'container'} key={"container_textinput_" + name}>
                            <IDSInput>
                                {label === undefined ? <label></label> : <label>{label}</label>}
                                <input
                                    {...!editDisabled &&
                                    methods.register(name, {setValueAs: (value: string) => removeTabsInValue(value)})}
                                    {...{value, onChange, onBlur, name}}
                                    key={"textinput_" + name}
                                />

                            </IDSInput>
                            <RHookFormValidationMessage name={name} editDisabled={editDisabled}/>
                            {(!!warning && isTouched && !editDisabled) &&
                                <IDSErrorMessage><span style={statusDraftStyle}>{warning}</span></IDSErrorMessage>}
                            {!(!!warning && isTouched && !editDisabled) && (warn(methods.getValues(name), name) !== undefined && warn(methods.getValues(name), name) !== false && !editDisabled) &&
                                <IDSErrorMessage><span
                                    style={statusDraftStyle}>{warn(methods.getValues(name), name)}</span></IDSErrorMessage>}
                            {(!!info && isTouched && !editDisabled) &&
                                <IDSErrorMessage><span style={statusActiveStyle}>{info}</span></IDSErrorMessage>}
                            {(!!toucherror && isTouched && !editDisabled) &&
                                <IDSErrorMessage><span style={errorStyle}>{toucherror}</span></IDSErrorMessage>}

                        </div>
                    </>
                );
            }}
        />
    );
};
export default RHookFormTextFieldController;