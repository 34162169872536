import {useFieldArray, useFormContext} from "react-hook-form";
import {IDSCol, IDSContainer, IDSRow, IDSButton} from "@inera/ids-react";
import React, {useEffect, useState} from "react";
import RHookSelect from "../../common/rhookform/RHookSelect";
import {IRHookSelectOption} from "../../common/rhookform/IRHookSelectOption";
import {getRenderCountText} from '../../common/RenderCount';
import Bin from "../../../img/bin";
import {
    formUUID,
    formOID,
    formLabelUUID,
    formLabelOID
} from "../../../model/defaultvalues/MetaDataDefaultFormValue";
import RHookFormTextFieldSpan from "../../common/rhookform/RHookFormTextFieldSpan";
import {metaDataTabName} from "../../common/page/TabName";
import RHookFormValidationMessage from "../../common/validation/RHookFormValidationMessage";
import {getErrorField} from "../../common/rhookform/RHookFormMessageUtil";
import {removeValidationMessagesOnObjPath} from "../../common/validation/MetaValidationRules";

type FormValues = {
    identifier: {
        system: string;
        value: string;
    }[];
};

let renderCount = 0;


export default function IdentifierArray(props?: any) {
    const methods = useFormContext();

    const {
        register,
        formState: {errors},
        control
    } = useFormContext<FormValues>();

    const name = "identifier";

    const {fields, append, remove} = useFieldArray({
        name: name,
        control,
        rules: {
            required: "Please append at least 1 item"
        }
    });

    const categoryOptions: IRHookSelectOption[] = [
        {value: formOID, label: formLabelOID},
        {value: formUUID, label: formLabelUUID},
    ];

    const [hasErrors, setHasErrors] = useState(false);

    useEffect(() => {
        const errorFieldArray = getErrorField(name, methods);
        if (errorFieldArray !== undefined) {
            setHasErrors(true);
        } else if (hasErrors) {
            setHasErrors(false);
        }
    }, [methods.formState.errors[name]]);

    const performRemove = async (index: number) => {
        const nameForSpecificIdentifierFieldValue = name + "[" + index + "].value";
        const nameForSpecificIdentifierFieldSystem = name + "[" + index + "].system";

        const errorFieldValue = getErrorField(nameForSpecificIdentifierFieldValue, methods);
        const errorFieldSystem = getErrorField(nameForSpecificIdentifierFieldSystem, methods);

        if (errorFieldValue !== undefined) {
            removeValidationMessagesOnObjPath(name + "." + index + ".value", props.setErrorMessageList);
            await methods.clearErrors(nameForSpecificIdentifierFieldValue)
        }
        if (errorFieldSystem !== undefined) {
            removeValidationMessagesOnObjPath(name + "." + index + ".system", props.setErrorMessageList);
            await methods.clearErrors(nameForSpecificIdentifierFieldSystem)
        }
        return remove(index);
    }

    const showBin = (index: number) => {
        if (fields.length > 1) {
            return (
                <Bin remove={performRemove} index={index}/>
            );
        }
    }

    renderCount++;

    return (
        <div>
            <IDSContainer gutterless={true}>
                <IDSRow justify="space-between">
                    <IDSCol cols="12">
                        <br/>
                        <h2 className="ids-heading-2">Identifierare</h2> {getRenderCountText(renderCount)}
                    </IDSCol>
                </IDSRow>
            </IDSContainer>
            {fields.map((field, index) => {
                return (
                    <section key={field.id}>
                        <IDSContainer gutterless={true}>
                            <IDSRow justify="space-between">
                                <IDSCol cols="6">
                                    <RHookSelect methods={methods} label={"Typ av identifierare (obligatorisk)"}
                                                 name={`identifier.${index}.system`}
                                                 options={categoryOptions}
                                                 handleWarnMessageList={props.handleWarnMessageList}
                                                 handleErrorMessageList={props.handleErrorMessageList}
                                                 rowindex={index.toString()} tabName={metaDataTabName}
                                                 disabled={props?.editDisabled}/>
                                </IDSCol>
                                <IDSCol cols="6">
                                    <RHookFormTextFieldSpan label="Identifierare" name={`identifier.${index}.value`}
                                                            handleErrorMessageList={props.handleErrorMessageList}
                                                            rowindex={index.toString()} tabName={metaDataTabName}
                                                            disabled={props?.editDisabled}/>
                                </IDSCol>
                            </IDSRow>
                            <IDSRow justify="space-between">
                                <IDSCol cols="6">
                                    <div className={'container'} key={"identifier_array_validation_message"}>
                                        <RHookFormValidationMessage name={name}
                                                                    editDisabled={props?.editDisabled}/><br/>
                                    </div>
                                </IDSCol>
                            </IDSRow>
                            {!props?.editDisabled &&
                                <IDSRow align='end'>
                                    <IDSCol>
                                    </IDSCol>
                                    {/*<IDSCol align='end'>*/}
                                    <IDSCol>
                                        {showBin(index)}
                                    </IDSCol>
                                </IDSRow>
                            }
                        </IDSContainer>
                    </section>
                );
            })}

            {!props?.editDisabled &&
                <div className={'container'}>
                    <IDSButton size='s' secondary={true} disabled={hasErrors}
                               onClick={() => {
                                   append({
                                       system: "",
                                       value: "",
                                   });
                               }}>
                        LÄGG TILL FLER
                    </IDSButton>
                    <br/><br/> <br/>
                </div>
            }
            <p>{errors.identifier?.root?.message}</p>

        </div>
    );
}
