import React from "react";
import {blue} from "@mui/material/colors";
import {createGlobalStyle} from 'styled-components'
import {makeStyles} from "@material-ui/core/styles";
import {pink, yellow} from '@mui/material/colors';


export const getSwedishStyledStatusText = (statusInEnglish : string) => {
    if (statusInEnglish === "active") {
        return <span style={statusActiveStyle}>Aktiv</span>;
    } else if (statusInEnglish === "draft") {
        return <span style={statusDraftStyle}>Utkast</span>;
    } else if (statusInEnglish === "retired") {
        return <span style={statusDraftRetired}>Avställd</span>;
    } else {
        return statusInEnglish;
    }
};


export const getSwedishStatusText = (statusInEnglish : string) => {
    if (statusInEnglish === "active") {
        return "Aktiv";
    } else if (statusInEnglish === "draft") {
        return "Utkast";
    } else if (statusInEnglish === "retired") {
        return "Avställd";
    } else {
        return statusInEnglish;
    }
};

export const sameRowStyle = {
    whiteSpace: 'nowrap',
    display: 'inline',
}

export const errorStyle = {fontStyle: "bold", color: "red"};
export const warnStyle = {fontStyle: "bold", color: "orange"};

export const infoStyle = {fontStyle: "bold", color: "green"};
export const whiteBgColor = {backgroundColor: "white", borderColor: "white"};
export const grayBorder = {borderColor: "coral"};
export const infoItemStyle = {fontStyle: "normal"};


export const statusActiveStyle = {fontStyle: "bold", color: "green"};
export const versionCurrentStyle = {color: "gray"};
export const statusDraftStyle = {fontStyle: "bold", color: "orange"};

export const statusDraftRetired = {fontStyle: "bold", color: "black"};
export const codeSystemLinkColumnStyle = {alignSelf: "end", justifySelf: "end"};
export const codeSystemBadgeColumnStyle = {justifySelf: "end", alignSelf: "end"};

export const display_flex = {display: "flex", width: "100%"};

const styles: ('absolute' | 'relative' | 'fixed') = 'relative';
const scrollType: ('auto' | 'scroll') = 'auto';


export const snomed_display_rightAligned_table_scroll = {
    height: 400,
    overflowY: scrollType,
}

export const display_width_48_leftAligned = {width: "48%",
    height: 1255,
    marginLeft: 0,
    marginRight: 0,
    position: styles,
    overflowY: scrollType,
    wrapper: {
        display: "inline-block"
    }
}
export const display_width_48_rightAligned = {
    width: "48%",
    marginLeft: 0,
    marginRight: 0,
    // @ts-ignore
    position: styles,
    wrapper: {
        display: "inline-block"
    }
} ;
//border: '1px solid', borderColor: "pink"
export const display_width_4 = {width: "4%"};

export const columnWidth5 = {width: "5%"};
export const columnWidth10 = {width: "10%"};
export const columnWidth15 = {width: "15%"};
export const columnWidth20 = {width: "20%"};
export const columnWidth25 = {width: "25%"};
export const columnWidth30 = {width: "30%"};
export const columnWidth35 = {width: "35%"};
export const columnWidth50 = {width: "50%"};
export const truncateText = {whitespace: "nowrap", textoverflow: "ellipsis", overflow: "hidden", maxwidth: "0"};
export const truncateTexts = {textoverflow: "ellipsis", overflow: "100%", maxwidth: "0"};
export const valuesetChoosenCodeTableHeaderRow = {backgroundColor: "#e7f7ea"};
export const conceptMapSourceTableHeaderColumn = {backgroundColor: "#e7f7ea", width: "50%"};
export const conceptMapSourceTableHeaderColumn20Width = {backgroundColor: "#e7f7ea", width: "20%"};
export const conceptMapSourceTableHeaderColumn30Width = {backgroundColor: "#e7f7ea", width: "30%"};

export const muiSelectCheckboxPropsStyle =
    {

    color: '#40775e',
    '&.Mui-checked': {
        color: '#40775e',
    },

    /*    color: '#40775e',*/
    borderWidth: '1px',


    '&.Mui-disabled': {
        pointerEvents: 'auto',
        '&:hover': {
            backgroundColor: 'transparent',
        },
        cursor: 'not-allowed',
        '& .MuiSvgIcon-root .innerBox': {
            fill: '#eee',
        },
    },


    '&:hover': {
        backgroundColor: 'transparent',
    },

    icon: {
        borderRadius: 3,
        border: '1px solid',
        width: 18,
        height: 18,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf5eb',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(0,0,0,.15)',
        },

        '&.Mui-disabled': {
            pointerEvents: 'auto',
            '&:hover': {
                backgroundColor: 'transparent',
            },
            cursor: 'not-allowed',
            '& .MuiSvgIcon-root .innerBox': {
                fill: '#eee',
            },
        },

    },
    checkedIcon: {
        backgroundColor: '#40775e',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 18,
            height: 18,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#40775e',
        },
    }
}


export const checkBoxStyle =
    {

    backgroundColorOnInnerBoxOfCustomIcon: {
        '&.Mui-disabled': {
            pointerEvents: 'auto',
            '&:hover': {
                backgroundColor: 'transparent',
            },
            cursor: 'not-allowed',
            '& .MuiSvgIcon-root .innerBox': {
                fill: '#eee',
            },
        },
    },


    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: 3,
        border: '1px solid',
        width: 18,
        height: 18,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf5eb',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(0,0,0,.15)',
        },

        '&.Mui-disabled': {
            pointerEvents: 'auto',
            '&:hover': {
                backgroundColor: 'transparent',
            },
            cursor: 'not-allowed',
            '& .MuiSvgIcon-root .innerBox': {
                fill: '#eee',
            },
        },

    },
    checkedIcon: {
        backgroundColor: '#40775e',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 18,
            height: 18,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#40775e',
        },
    },
};

export const checkBoxStyles = makeStyles(checkBoxStyle);

//border: "none",
export const textFieldStyle = {
    borderColor: "#f0f0f0",
    backgroundColor: "transparent",
    width: '100%',
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: "3px"
};

export const muiTablePropsTwoColumnStyle = {
    borderRadius: "5px",
    border: '1px solid #727373',
    '& th:first-of-type': {
        borderRadius: '5px 0 0 0'
    },
    '& th:last-child': {
        borderRadius: '0 5px 0 0'
    },
    '& td:nth-last-of-type(2)': {
        borderRadius: '0 0 0 5px',
    },
    '& td:last-child': {
        borderRadius: '0 0 5px 0'
    },
}

export const muiTablePropsThreeColumnStyle = {
    borderRadius: "5px",
    border: '1px solid #727373',
/*    border: '1px solid rgba(81, 81, 81, .5)',
    caption: {
        captionSide: 'top',
    },*/
/*    border: '1px solid #727373',*/
    '& th:first-of-type': {
        borderRadius: '5px 0 0 0'
    },
    '& th:last-child': {
        borderRadius: '0 5px 0 0'
    },
    '& td:nth-last-of-type(3)': {
        borderRadius: '0 0 0 5px',
    },
    '& td:last-child': {
        borderRadius: '0 0 5px 0'
    },
}


export const muiTablePropsFourColumnStyle = {
    borderRadius: "5px",
    border: '1px solid #727373',
    '& th:first-of-type': {
        borderRadius: '5px 0 0 0'
    },
    '& th:last-child': {
        borderRadius: '0 5px 0 0'
    },
    '& td:nth-last-of-type(4)': {
        borderRadius: '0 0 0 5px',
    },
    '& td:last-child': {
        borderRadius: '0 0 5px 0'
    },
}

export const muiTablePropsFiveColumnStyle = {
    borderRadius: "5px",
    border: '1px solid #727373',
    '& th:first-of-type': {
        borderRadius: '5px 0 0 0'
    },
    '& th:last-child': {
        borderRadius: '0 5px 0 0'
    },
    '& td:nth-last-of-type(5)': {
        borderRadius: '0 0 0 5px',
    },
    '& td:last-child': {
        borderRadius: '0 0 5px 0'
    },
}

export const muiTablePropsSixColumnStyle = {
    borderRadius: "5px",
    border: '1px solid #727373',
    '& th:first-of-type': {
        borderRadius: '5px 0 0 0'
    },
    '& th:last-child': {
        borderRadius: '0 5px 0 0'
    },
    '& td:nth-last-of-type(6)': {
        borderRadius: '0 0 0 5px',
    },
    '& td:last-child': {
        borderRadius: '0 0 5px 0'
    },
}

export const muiTableRowPropsStyle = {
    verticalAlign: 'top'
}

export const muiTableHeadRowPropsStyle = {
    borderBottomRightRadius: "5px",
    borderBottomLeftRadius: "5px",
    /*   borderRadius: "5px",*/
    backgroundColor: '#f6f1e9',
    /*        borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px'*/
}

export const muiTableHeadRowErrorPropsStyle = {
    borderBottomRightRadius: "5px",
    borderBottomLeftRadius: "5px",
    /*   borderRadius: "5px",*/
    backgroundColor: '#f9dbd8',
    /*        borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px'*/
}

export const rcorners2 = {
    borderTopRightRadius: "5px",
    borderTopLeftRadius: "5px",
    borderBottomRightRadius: "5px",
    borderBottomLeftRadius: "5px",
    /*    backgroundColor: '#f9dbd8',*/
    border: "1px solid #727373"
    /*    borderColor: blue,
        borderWidth: "2px"*/
    /*   borderRadius: "5px",*/
    /* backgroundColor: '#f9dbd8',*/
    /*        borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px'*/
}

export const padding = {
    paddingTop: "15px",
    paddingRight: "0px",
    paddingBottom: "0px",
    paddingLeft: "15px"
}


export const muiTableBodyPropsStyle = {
    /*        //stripe the rows, make odd rows a darker color
            '& tr:nth-of-type(odd)': {
                backgroundColor: '#f5f5f5',
            },*/
}

export const muiTableBodyColorPropsStyle = {

    /*    '& td:nth-of-type(odd)': {
            backgroundColor: '#f6f1e9'
        },
        '& td:nth-of-type(even)': {
            backgroundColor: '#f6f1e9'
        }*/
}


export const muiTableBodyCellPropsStyle = {
    verticalAlign: 'top'
}

export const displayLinebreak = {
    whiteSpace: 'pre-line'
}


export const GlobalStyled = createGlobalStyle`
  body:not(.user-is-tabbing) button:focus {
    outline: none;
  }
`;
