import {
    Extension
} from "fhir-typescript-models/dist/src/models/fhir/internal";
import IConceptSearchResultItem from "../search/model/IConceptSearchResultItem";


export const transformExtensionsAndSetProperties = (extensionArray: Array<Extension>, concept: IConceptSearchResultItem | undefined): IConceptSearchResultItem | undefined => {
    if (concept !== undefined) {
        for (let ex of extensionArray) {
            if (ex.extension !== undefined) {
                let code = undefined;
                let value = undefined;
                for (const extension of ex.extension) {
                    //  @ts-ignore
                    if (extension.url !== undefined && extension.url === "code") {
                        //  @ts-ignore
                        code = extension.valueCode;
                    }
                    //  @ts-ignore
                    else {
                        //  @ts-ignore
                        if (extension.url === "inactive") {
                            //  @ts-ignore
                            concept.inactive = extension.valueBoolean;
                        }

                        //  @ts-ignore
                        if (code === "child") {

                            //  @ts-ignore
                            const child = {code: extension.valueCode};
                            //  @ts-ignore
                            if (concept.concept === undefined) {
                                //  @ts-ignore
                                concept.concept = [child]
                            } else {
                                //  @ts-ignore
                                concept.concept.push(child)
                            }
                        }

                        //  @ts-ignore
                        if (code === "parent") {
                            //  @ts-ignore
                            const parent = {code: extension.valueCode};

                            //  @ts-ignore
                            if (concept.parentCodes === undefined) {
                                //  @ts-ignore
                                concept.parentCodes = [parent]
                            } else {
                                //  @ts-ignore
                                concept.parentCodes.push(parent)
                            }
                        }

                        //  @ts-ignore
                        if (code === "sufficientlyDefined") {
                            //  @ts-ignore
                            concept.sufficientlyDefined = extension.valueBoolean;
                        }
                    }
                }
            }
        }
    }
    return concept;
}