import {useFieldArray, useFormContext} from "react-hook-form";
import {IDSCol, IDSContainer, IDSIcon, IDSIconArrow, IDSLink, IDSRow} from "@inera/ids-react";
import '@inera/ids-core/components/expandable/register';
import {getRenderCountText} from '../../../common/RenderCount';
import React, {FC} from "react";
import '@inera/ids-core/components/expandable/register';
import VSConceptTable from "./VSConceptTable";
import {composeIncludeObjPath} from "../VSConstant";
import VSIncludeHeading from "./VSIncludeHeading";
import {getCodeSystemMeta} from "../util/CSBundleEntryArrayUtil";
import {IBundleEntry} from "fhir-typescript-models";

let renderCount = 0;


interface VSComposeProps {
    relatedArtifactsArray?: IBundleEntry[];
    codeSystemArray?: any[],
}


const Compose: FC<VSComposeProps> = ({relatedArtifactsArray, codeSystemArray}) => {

    const methods = useFormContext();


    const {
        register,
        formState: {errors},
        control
    } = useFormContext();

    const {fields, replace, append, remove} = useFieldArray({
        name: composeIncludeObjPath,
        control,
        rules: {
            required: "Please append at least 1 item"
        }
    });

    renderCount++;

    return (
        <div>
            <br/><br/>
            {fields.map((field, index) => {
                return (
                    <div>
                        <IDSContainer gutterless={true}>
                            <IDSRow justify="space-between">
                                <IDSCol cols="12">
                                    <VSIncludeHeading showSystemUrl={true}
                                                      includeMetaData={relatedArtifactsArray === undefined ? undefined : getCodeSystemMeta(relatedArtifactsArray[index])}/>
                                </IDSCol>
                            </IDSRow>
                            <IDSRow justify="space-between">
                                <IDSCol cols="12">
                                    <VSConceptTable replace={replace}
                                                    index={index}
                                        /*                     data={methods.getValues(composeIncludeObjPath + "[" + index + "].concept")}*/
                                                    fields={fields}
                                                    system={
                                                        // @ts-ignore
                                                        (codeSystemArray[index] === undefined || codeSystemArray[index].resource === undefined) ? undefined : codeSystemArray[index].resource.url}
                                                    version={
                                                        // @ts-ignore
                                                        (codeSystemArray[index] === undefined || codeSystemArray[index].resource === undefined) ? undefined : codeSystemArray[index].resource.version}
                                    />
                                </IDSCol>
                            </IDSRow>
                        </IDSContainer>
                    </div>
                );
            })}
        </div>
    );
}
export default Compose;