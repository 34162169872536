import React from 'react';
import {
    getFieldNameInArrayHierarchy,
    hasRowCellsAllEmptyValues
} from "../../common/rhookform/RHookFormArrayHierarchyFunction";
import {ICodeSystemConcept} from "fhir-typescript-models/dist/src/models/fhir/internal";
import {infoValidateDisplay} from "./DisplayInfoValidation";
import {warnValidateDisplay} from "./DisplayWarnValidation";


let renderCount = 0;
const arrayname = "concept"

export const validateDisplayAfterSubmit = (concepts: ICodeSystemConcept[], warnMessageList: [], handleWarnMessageList: any, infoMessageList: [], handleInfoMessageList: any, methods: any) => {
    for (let i = 0; i < concepts.length; i++) {
        let concept = concepts[i];
        const name = getFieldNameInArrayHierarchy(i.toString(), "concept", "display");
        // @ts-ignore
        const faultyValue = warnValidateDisplay(concept.display, undefined, i.toString(), warnMessageList, handleWarnMessageList, methods, hasRowCellsAllEmptyValues(name, methods));

        if (faultyValue == false) {
            // @ts-ignore
            infoValidateDisplay(concept.display, undefined, i.toString(), infoMessageList, handleInfoMessageList, methods, hasRowCellsAllEmptyValues(name, methods));
        }
    }
}