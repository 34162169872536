import React, {FC, useEffect, useMemo, useState} from 'react';
import {MaterialReactTable, MaterialReactTableProps, MRT_ColumnDef, MRT_RowSelectionState} from 'material-react-table';
import {MRT_Localization_SV} from 'material-react-table/locales/sv';
import {
    checkBoxStyles,
    muiSelectCheckboxPropsStyle,
    muiTableBodyPropsStyle,
    muiTableHeadRowPropsStyle,
    muiTablePropsFourColumnStyle
} from "../../../styles/TTStyles";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ImportExport from '@mui/icons-material/ImportExport';
import {Checkbox} from "@material-ui/core";
import '@inera/ids-core/components/link/register';
import '@inera/ids-core/components/icon/register';

import {SNOMED_CT_URL} from "../../../model/defaultvalues/Constant";
import IConceptSearchResultItem from "./model/IConceptSearchResultItem";
import {snomedSearchMenu} from "../../../constants/PanelConstant";
import {isSearchResultDataColumn} from "../../../components/common/mrtable/MRTUtil";
import {IValueSetComposeIncludeConcept} from "fhir-typescript-models/dist/src/models/fhir/internal";
import {setRowSelections} from "../util/RowSelectionUtil";


let renderCount = 0;

interface SnomedSearchResultProps {
    id: string,
    version: string,
    data: IConceptSearchResultItem[],
    snomedConceptsToAdd?: IValueSetComposeIncludeConcept[],
    handleNodeClick: any,
    handleChange?: any,
    tabKey?: number,
    codeSystemIndex?: number
}

export const SnomedSearchResult: FC<SnomedSearchResultProps> = ({
                                                                    id,
                                                                    version,
                                                                    data,
                                                                    snomedConceptsToAdd,
                                                                    handleNodeClick,
                                                                    handleChange,
                                                                    tabKey,
                                                                    codeSystemIndex
                                                                }) => {


    const [loadingRows, setLoadingRows] = useState(false);
    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
    const isCreateValueSetMode = tabKey !== undefined;
    const enableRowSelection = isCreateValueSetMode;
    const thisManuName = snomedSearchMenu;

    const snomedConceptsToAddString = JSON.stringify(snomedConceptsToAdd);

    useEffect(() => {
        //handling deSelection/removal in conceptToAdd, via summery table
        setRowSelections(data,
            snomedConceptsToAdd,
            rowSelection,
            setRowSelection);

    }, [snomedConceptsToAddString, data]);


    renderCount++;

    const columnDefinitions: MRT_ColumnDef<IConceptSearchResultItem>[] = [
        {
            accessorKey: 'code',
            header: 'Kod',
            // @ts-ignore
            enableColumnFilters: true,
            size: 50, //small column
        },
        {
            accessorKey: 'display',
            header: 'Klartext',
            // @ts-ignore
            enableColumnFilters: true
        },
        {
            // @ts-ignore
            accessorFn: (originalRow) => {
                // @ts-ignore
                return originalRow.inactive ? "Inaktiv" : "Aktiv";
            },
            header: 'Status',
            // @ts-ignore
            enableColumnFilters: true,
            size: 50, //small column

        }
        /*        ,
                {
                    accessorKey: 'synonym',
                    header: 'Synonymer',
                    // @ts-ignore
                    enableColumnFilters: true
                }*/
    ];

    const columns = useMemo<MRT_ColumnDef<IConceptSearchResultItem>[]>(
        //column definitions...
        () => columnDefinitions,
        [],
        //end
    );


    const commonTableSourceProps: Partial<MaterialReactTableProps<IConceptSearchResultItem>> & {
        columns: MRT_ColumnDef<IConceptSearchResultItem>[];
    } = {
        columns,
        enableFullScreenToggle: false,
    };

    const checkBoxClasses = checkBoxStyles();

    renderCount++;

    return (
        <div>
            <div>
                <><br/>
                    <MaterialReactTable
                        {...commonTableSourceProps}
                        autoResetPageIndex={false}
                        data={data}
                        enableRowOrdering={false}

                        enableSelectAll={false}
                        enableSubRowSelection={false}
                        enableRowSelection={enableRowSelection}
                        onRowSelectionChange={setRowSelection}
                        enableRowActions={false}


                        enableColumnFilters={true}
                        enableSorting={true}
                        localization={MRT_Localization_SV}
                        enableTopToolbar={false}
                        enableEditing={false}
                        enableHiding={false}
                        editDisplayMode="table"
                        positionActionsColumn="last"
                        enableExpanding={false}
                        state={{
                            rowSelection,
                            isLoading: loadingRows
                        }}
                        // @ts-ignore
                        /*         getRowId={(originalRow) => originalRow.code}*/
                        initialState={{pagination: {pageSize: 50, pageIndex: 0}}}

                        // @ts-ignore
                        muiSelectCheckboxProps={({row, table}) => ({
                            /*           color: 'success',*/
                            /*          className: "ids-checkbox",*/
                            /*                 icon: () => <Checkbox/>,

                                             checkedIcon: () => <Checkbox/>,*/
                            disabled: row.original.inactive,

                            // @ts-ignore
                            defaultChecked: row.original.selected,
                            onClick: (event) => {
                                const code = row.original.code;
                                const display = row.original.display;
                                const rowId = row.id;
                                handleChange(event, row.original, SNOMED_CT_URL, version, rowId, codeSystemIndex, id, thisManuName);
                                // @ts-ignore
                                handleNodeClick(code, display, rowId);
                            },
                            sx: muiSelectCheckboxPropsStyle
                        })}

                        muiTableHeadRowProps={{
                            sx: muiTableHeadRowPropsStyle
                        }}

                        muiTableProps={{
                            sx: muiTablePropsFourColumnStyle
                        }}

                        muiTableBodyProps={{
                            sx: muiTableBodyPropsStyle
                        }}


                        muiTablePaperProps={{
                            elevation: 0, //change the mui box shadow
                        }}

                        icons={{
                            MoreVertIcon: () => <ImportExport/>,
                            MoreHorizIcon: () => <MoreVertIcon/>,
                        }}

                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                header: '',
                                size: 10,
                            },
                            'mrt-row-drag': {
                                header: '',
                                size: 10,
                            },
                        }}

                        muiColumnActionsButtonProps={{
                            children: <ExpandMore/>
                        }}


                        muiTableBodyCellProps={({cell, row, table}) => ({
                            sx: {
                                cursor: isSearchResultDataColumn(cell) ? 'pointer' : 'default',
                                verticalAlign: 'top'
                            },
                            onClick: () => {
                                if (isSearchResultDataColumn(cell)) {
                                    const code = row.original.code;
                                    const display = row.original.display;
                                    // @ts-ignore
                                    handleNodeClick(code, display, row.id);
                                }
                            },
                        })}
                    />
                </>
            </div>
        </div>
    );
};

export default SnomedSearchResult;

