import {getUseCodeFromDesignation, snomedSynonymCode} from "../SnomedTransformerUtil";
import {
    ValueSetComposeIncludeConceptDesignation,
    ValueSetExpansionContains
} from "fhir-typescript-models/dist/src/models/fhir/internal";
import {getRecommendedFromDesignations} from "./SynonymRecommendedTransformer";
import IConceptSynonymsEnglish from "../../search/model/IConceptSynonymsEnglish";
import IConceptSynonym from "../../search/model/IConceptSynonym";


export const snomedDefaultLanguageCodeEn = "en";
export const US_English_en_x_sctlang = "en-x-sctlang-90000000-00005090-07";
export const UK_English_en_x_sctlang = "en-x-sctlang-90000000-00005080-04";


export const getEnglishSynonyms = (
    englishSynonyms: IConceptSynonym[],
    englishUSRecommended: IConceptSynonym | undefined,
    englishUKRecommended: IConceptSynonym | undefined): IConceptSynonymsEnglish | undefined => {

    let synonymEnglish: IConceptSynonymsEnglish | undefined = undefined;
    if (englishSynonyms.length !== 0 ||
        englishUSRecommended !== undefined ||
        englishUKRecommended !== undefined) {

        synonymEnglish = {};
        if (englishSynonyms.length !== 0) {
            synonymEnglish.acceptableList = englishSynonyms;
        }
        synonymEnglish.recommendedUS = englishUSRecommended;
        synonymEnglish.recommendedUK = englishUKRecommended;
    }
    return synonymEnglish;
}


export const getEnglishSynonymsFromContains = (contains: ValueSetExpansionContains): IConceptSynonymsEnglish | undefined => {
    let engAcceptableSynonyms: IConceptSynonym[] = [];
    let recommendedUK: IConceptSynonym | undefined = undefined;
    let recommendedUS: IConceptSynonym | undefined = undefined;
    if (contains.designation !== undefined) {
        const engAcceptableSynonymValues: string[] = getAcceptableEnglishLanguageSynonymsFromDesignations(contains.designation);
        for (const value of engAcceptableSynonymValues) {
            engAcceptableSynonyms.push({value})
        }

        const ukRecommended = getEnglishUKRecommendedFromDesignations(contains.designation);
        if (ukRecommended !== undefined) {
            recommendedUK = {value: ukRecommended}
        }

        const usRecommended = getEnglishUSRecommendedFromDesignations(contains.designation);
        if (usRecommended !== undefined) {
            recommendedUS = {value: usRecommended}
        }
    }

    if (engAcceptableSynonyms.length == 0 && recommendedUK === undefined && recommendedUS === undefined) {
        return undefined;
    } else {
        return {
            recommendedUK: recommendedUK,
            recommendedUS: recommendedUS,
            acceptableList: engAcceptableSynonyms.length == 0 ? undefined : engAcceptableSynonyms
        };
    }
}

export const getEnglishUKRecommendedFromDesignations = (designationArray: Array<ValueSetComposeIncludeConceptDesignation>): string => {
    return getRecommendedFromDesignations(designationArray, UK_English_en_x_sctlang)
}

export const getEnglishUSRecommendedFromDesignations = (designationArray: Array<ValueSetComposeIncludeConceptDesignation>): string => {
    return getRecommendedFromDesignations(designationArray, US_English_en_x_sctlang)
}

export const getAcceptableEnglishLanguageSynonymsFromDesignations = (designationArray: Array<ValueSetComposeIncludeConceptDesignation>): string[] => {
    let synonyms: string[] = [];
    for (let designation of designationArray) {
        const result = getAcceptableEnglishLanguageSynonymAsStringFromDesignation(designation);
        //  @ts-ignore
        if (result !== undefined) {
            //  @ts-ignore
            synonyms.push(designation.value)
        }
    }
    return synonyms;
}

export const getAcceptableEnglishLanguageSynonymFromDesignation = (designation: ValueSetComposeIncludeConceptDesignation): IConceptSynonym | undefined => {
    let englishLanguageSynonym: string | undefined = getAcceptableEnglishLanguageSynonymAsStringFromDesignation(designation);

    if (englishLanguageSynonym !== undefined) {
        return {value: englishLanguageSynonym, selected: false};
    } else {
        return undefined;
    }
}

export const getAcceptableEnglishLanguageSynonymAsStringFromDesignation = (designation: ValueSetComposeIncludeConceptDesignation): string | undefined => {
    let englishLanguageSynonym: string | undefined = undefined
    const useCode = getUseCodeFromDesignation(designation);
    //  @ts-ignore
    if (designation.language === snomedDefaultLanguageCodeEn &&
        useCode === snomedSynonymCode) {
        //  @ts-ignore
        englishLanguageSynonym = designation.value;
    }
    return englishLanguageSynonym;
}