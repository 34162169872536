import React, {FC, useEffect, useMemo, useRef, useState} from 'react';
import {
    MaterialReactTable,
    MaterialReactTableProps,
    MRT_ColumnDef,
    MRT_TableInstance,
} from 'material-react-table';
import {MRT_Localization_SV} from 'material-react-table/locales/sv';
import {
    muiTableBodyPropsStyle,
    muiTableHeadRowPropsStyle,
    muiTablePropsFiveColumnStyle,
} from "../../../../styles/TTStyles";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ImportExport from '@mui/icons-material/ImportExport';
import '@inera/ids-core/components/link/register';
import '@inera/ids-core/components/icon/register';
import {ICodeSystemConcept} from "fhir-typescript-models/dist/src/models/fhir/internal";
import {composeIncludeObjPath} from "../VSConstant";
import {useFormContext} from "react-hook-form";
import {getActiveCodesOnly, shouldFetchActiveCodeStatus} from "../../../../codesystem/snomed/expand/SnomedExpandUtil";
import {activeColumn} from "../../concept/table/ConceptColumns";


let renderCount = 0;


interface VSConceptTableProps {
    replace: any,
    index: number,
    system: string | undefined,
    version: string | undefined
    fields: any
}

const VSConceptTable: FC<VSConceptTableProps> = ({
                                                     replace,
                                                     index,
                                                     system,
                                                     version,
                                                     fields
                                                 }) => {

    // @ts-ignore
    let data = fields[index].concept;

    const methods = useFormContext();


    useEffect(() => {
        const fetchSnomedCodes: boolean = shouldFetchActiveCodeStatus(data, system);
        if (fetchSnomedCodes && version !== undefined) {
            collectSnomedCodeActiveStatus(version);
        }
    }, [methods.getValues(composeIncludeObjPath + "[" + index + "].system")]);

    const composeIncludeConceptPath = composeIncludeObjPath + "[" + index + "].concept";

    const collectSnomedCodeActiveStatus = async (version: string) => {
        // @ts-ignore
        const codes: string[] = data.map(a => a.code);
        const activeCodes = await getActiveCodesOnly(codes, version);
        // @ts-ignore
        data.filter(obj => {
            if (activeCodes.includes(obj.code)) {
                obj.inactive = false;
            } else {
                obj.inactive = true;
            }
        })

        fields[index].concept = data;

        var array = [...fields];

        replace(array);
        //   methods.setValue(composeIncludeObjPath + "[" + index + "].concept", array);
    }


    const columnNames = {
        code: 'Kod',
        display: 'Klartext',
        inactive: "Status"
    } as const;

    interface ICodeSystemConceptColumn {
        code?: string;
        display?: string;
        inactive?: boolean;
    }


    //create columns from data
    const columns = useMemo<MRT_ColumnDef<ICodeSystemConceptColumn>[]>(
        () =>
            (data !== undefined) && data.length
                ? Object.keys(data[0]).map((columnId) => {
                    if (columnId === "inactive") {
                        return activeColumn("Status");
                    } else {
                        return {
                            header: columnNames[columnId as keyof ICodeSystemConceptColumn] ?? columnId,
                            accessorKey: columnId,
                            id: columnId,
                            enableColumnFilters: true,
                            enableEditing: false,
                        }
                    }
                })
                : [],
        [data],
    );


    const commonTableSourceProps: Partial<MaterialReactTableProps<ICodeSystemConcept>> & {
        columns: MRT_ColumnDef<ICodeSystemConcept>[];
    } = {
        columns,
        enableFullScreenToggle: false,
    };

    const tableInstanceRef = useRef<MRT_TableInstance<ICodeSystemConcept>>(null);

    renderCount++;

    return (

        <div>
            <div>
                <MaterialReactTable
                    {...commonTableSourceProps}
                    autoResetPageIndex={false}
                    columns={columns}
                    data={(data === undefined || data.length == 0 || data[0].code === "") ? [] : data}
                    enableRowOrdering={false}
                    enableColumnFilters={true}
                    enableSorting={true}
                    enableRowActions={false}
                    localization={MRT_Localization_SV}
                    enableTopToolbar={false}
                    enableSelectAll={true}
                    enableEditing={true}
                    enableHiding={false}
                    editDisplayMode="table"
                    positionActionsColumn="last"
                    enableExpanding={false}
                    enableExpandAll={false}
                    initialState={{pagination: {pageSize: 5, pageIndex: 0}}}

                    muiTableHeadRowProps={{
                        sx: muiTableHeadRowPropsStyle
                    }}

                    muiTableProps={{
                        sx: muiTablePropsFiveColumnStyle
                    }}

                    muiTableBodyProps={{
                        sx: muiTableBodyPropsStyle
                    }}


                    muiTablePaperProps={{
                        elevation: 0, //change the mui box shadow
                    }}

                    icons={{
                        MoreVertIcon: () => <ImportExport/>,
                        MoreHorizIcon: () => <MoreVertIcon/>,
                    }}

                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            header: '',
                            size: 10,
                        },
                        'mrt-row-drag': {
                            header: '',
                            size: 10,
                        },
                    }}

                    getSubRows={(originalRow) => {
                        return (
                            originalRow.concept
                        )
                    }}

                    muiColumnActionsButtonProps={{
                        children: <ExpandMore/>
                    }}
                />
            </div>
        </div>
    );
};

export default VSConceptTable;
