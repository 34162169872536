import {IBundleEntry, IMeta, ICodeSystem} from "fhir-typescript-models";

export const createSlimRelatedArtefact = (artifact: IBundleEntry): IBundleEntry => {

    return createRelatedArtefact(
        // @ts-ignore
        artifact.resource?.id,
        // @ts-ignore
        artifact.resource?.title,
        // @ts-ignore
        artifact.resource?.version,
        // @ts-ignore
        artifact.resource?.status,
        // @ts-ignore
        artifact.resource?.url,
        artifact.resource?.meta?.lastUpdated
    )
}

export const createSlimRelatedArtefactFromCodeSystem = (resource: ICodeSystem): IBundleEntry => {

    return createRelatedArtefact(
        // @ts-ignore
        resource?.id,
        // @ts-ignore
        resource?.title,
        // @ts-ignore
        resource?.version,
        // @ts-ignore
        resource?.status,
        // @ts-ignore
        resource?.url,
        resource?.meta?.lastUpdated
    )
}

export const createRelatedArtefact = (id: string, title: string, version: string, status: string, url: string, lastUpdated: string): IBundleEntry => {

    let relatedArtefact: IBundleEntry | undefined = undefined;
    let codeSystem: ICodeSystem | undefined = undefined;
    let meta: IMeta | undefined = undefined;

    meta = {
        lastUpdated: lastUpdated,
    };

    codeSystem = {
        id: id,
        meta: meta,
        status: status,
        title: title,
        version: version,
        url: url
    };

    relatedArtefact = {
        resource: codeSystem
    };
    return relatedArtefact;
}
