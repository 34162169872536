import {ICodeSystem} from "fhir-typescript-models";
import {ICodeSystemConcept} from "fhir-typescript-models/dist/src/models/fhir/internal";
import {
    removeContactFormDefault, removeEmptyMetaDataFields,
    removeIdentifierFormDefault
} from "./MetaDataDefaultFormValue";
import {removeEmojiFromJson} from "../../components/common/CommonFunctions";
import {removeExtensionFormDefault} from "./MetaDataExtensionDefaultFormValue";


export const concept: ICodeSystemConcept = {
    code: "",
    display: "",
    definition: "",
};

export const conceptList: ICodeSystemConcept[] = [concept];


export function removeDefaultCodeSystemFormValues(data: ICodeSystem) {

    data = removeEmojiFromJson(data);

    removeConceptWarnings(data);

    removeConceptInfos(data);

    removeExtensionFormDefault(data);

    removeContactFormDefault(data);

    removeIdentifierFormDefault(data);

    removeConceptFormDefault(data);

    removeConceptEmptyValues(data);

    removeEmptyMetaDataFields(data)

    setContent(data);

    return data;
};

function setContent(data: any) {
    if (!data.concept) {
        data.content = "not-present";
    } else {
        data.content = "complete";
    }
}

function removeConceptWarnings(data: ICodeSystem) {

    if(data.concept !== undefined){

        for(let concept of data.concept){
            // @ts-ignore
            concept.displayWarning = undefined;
            // @ts-ignore
            concept.codeWarn = undefined;
        }
    }
}

function removeConceptInfos(data: ICodeSystem) {

    if(data.concept !== undefined){

        for(let concept of data.concept){
            // @ts-ignore
            concept.definitionInfo = undefined;
            // @ts-ignore
            concept.codeInfo = undefined;
            // @ts-ignore
            concept.displayInfo = undefined;
        }
    }
}

function removeConceptFormDefault(data: ICodeSystem) {
    if (JSON.stringify(data.concept) === JSON.stringify(conceptList)) {
        delete data.concept;
    } else if (data.concept) {
        const arrayWithoutEmptyConcept: ICodeSystemConcept[] = data.concept.filter(value => JSON.stringify(value) !== JSON.stringify(concept));
        data.concept = arrayWithoutEmptyConcept;
    }
}

function removeConceptEmptyValues(data: ICodeSystem) {

    if (data.concept !== undefined) {

        for (let i = 0; i < data.concept.length; i++) {
            let concept = data.concept[i];
            if (concept.code !== undefined && String(concept.code).trim() === "") {
                concept.code = undefined;
            }
            if (concept.definition !== undefined && String(concept.definition).trim() === "") {
                concept.definition = undefined;
            }
            if (concept.display !== undefined && String(concept.display)?.trim() === "") {
                concept.display = undefined;
            }

            //Remove row
            if (JSON.stringify(concept) === "{}") {
                data.concept.splice(i, 1);
            }
        }
    }
}





