import React, {useState} from "react";
import '@inera/ids-core/styles/themes/inera-admin.css';
import {IDSDialog} from "@inera/ids-react";
import CreateNewVersionDialogForm from "./CreateNewVersionDialogForm";

const CreateNewVersionDialog = (props) => {

    const closeDialog = () => props.setShowCreateDialog(false);

    return (
        <div>
            <IDSDialog overlay={true} persistent={true} dismissible="true" show={props.showCreateDialog}
                       headline={"Du skapar nu en ny version av:"}
                       onClosed={() => {
                           console.log("onClosed " + props.artifactType);
                       }}
            >
                <a href="javascript:void(0)" trigger="true" ref={props.createButtonRef} style={{color: '#FFF'}}></a>
                <p style={{color: '#FFF'}}>..................................................................................................................................</p>
                <CreateNewVersionDialogForm
                    artifactType={props.artifactType}
                    closeDialog={closeDialog}
                    data={props.data}
                    title={props.data.title}
                    name={props.data.name}
                    url={props.data.url}
                    setFoundHistory={props.setFoundHistory}/>
            </IDSDialog>
        </div>
    );
};
export default CreateNewVersionDialog;