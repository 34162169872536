import React from "react";
import {
    IDSCol,
    IDSContainer,
    IDSRow, IDSListItem, IDSListItemInfo
} from "@inera/ids-react";
import '@inera/ids-core/components/breadcrumbs/register';
import '@inera/ids-core/components/list/register';
import '@inera/ids-core/components/badge/register';
import '@inera/ids-core/components/link/register';
import '@inera/ids-core/components/icon/register';
import '@inera/ids-core/components/notification/badge/register';
import {
    codeSystemLinkColumnStyle,
    codeSystemBadgeColumnStyle,
    infoItemStyle, getSwedishStyledStatusText, versionCurrentStyle
} from "../../../styles/TTStyles";
import {codesystem, conceptmap, valueset} from "../../../model/defaultvalues/Constant";
import {formDateYearMonthDayTime, getArtifactSwedishLabel} from "../../common/CommonFunctions";


const SearchItem = (props) => {


    const prepareValueSetRef = () => {
        return (props.getCodeSystemForValueSet(props.id, props.versionId, props.tabKey, props.url));
    };

    const prepareConceptMapRef = () => {
        return (
            props.getCodeSystemForArtifact(props.id, props.versionId, props.type, props.tabKey))
    };

    const getHref = () => {

        if (props.belongsTo === valueset) {
            return (

                <a href="#" onClick={() => {
                    prepareValueSetRef()
                }}>Välj för att göra urval</a>
            )
                ;
        } else if (props.belongsTo === conceptmap) {
            var type;
            if (props.tabKey === 0) {
                type = "källa";
            } else {
                type = "mål"
            }
            return (
                <a href="#" onClick={() => {
                    prepareConceptMapRef()
                }}>Välj som {type}</a>);
        } else if (props.type === codesystem) {
            return (<a href={"/" + props.type + "/" + props.id}>Mer information</a>);
        } else if (props.type === valueset) {
            return (<a href={"/" + props.type + "/" + props.id}>Mer information</a>);
        } else if (props.type === conceptmap) {
            return (<a href={"/" + props.type + "/" + props.id}>Mer information</a>);
        }

    };

    return (

        <ids-list>
            <IDSListItem headline={props.title}
                         date={props.date} key={Math.random()}>
                <IDSContainer gutterless={true}>
                    <IDSRow>
                        <IDSCol cols="10">

                            {/* Of some strange reason Ontoserver is returning "identifier" as an Array for
                            CodeSystem and ValueSet search results but
                           "identifier" as an object for ConceptMap search results*/}
                            <div style={infoItemStyle}>
                                Sektor: <strong>{props.sectorTitle === undefined ? ""
                                : props.sectorTitle}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;
                                Förvaltare: <strong>{props.publisher === undefined ? ""
                                : props.publisher}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;
                                Identifierare:
                                {(props.identifier !== undefined) && Array.isArray(props.identifier) &&
                                    props.identifier?.map((object, i) =>
                                        <span>&nbsp;<span
                                            style={versionCurrentStyle}><strong>{object.system}:</strong>&nbsp;</span>
                                        <strong>{object.value}</strong>&nbsp;&nbsp;</span>
                                    )
                                }

                                {((props.identifier !== undefined) &&
                                        (typeof props.identifier === 'object') &&
                                        (!Array.isArray(props.identifier)) &&
                                        props.identifier !== null) &&
                                    <span>&nbsp;<span
                                        style={versionCurrentStyle}><strong>{props.identifier.system}:</strong>&nbsp;</span>
                                        <strong>{props.identifier.value}</strong>&nbsp;&nbsp;</span>
                                }
                                |&nbsp;&nbsp;
                                Version: <strong>{props.version === undefined ? ""
                                : props.version}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;

                                Versionsstatus: <strong>{getSwedishStyledStatusText(props.status)}</strong>
                            </div>
                        </IDSCol>
                        <IDSCol cols="2" style={codeSystemBadgeColumnStyle}>

                            <ids-badge type="neutral">{getArtifactSwedishLabel(props.type)}</ids-badge>

                        </IDSCol>
                    </IDSRow>
                    <IDSRow>
                        <IDSCol cols="10">
                            {props.description}
                            <IDSListItemInfo class="ids-mb-4">Senast
                                uppdaterad: {formDateYearMonthDayTime(props.date)}</IDSListItemInfo>
                        </IDSCol>
                        <IDSCol cols="2" style={codeSystemLinkColumnStyle}>
                            <ids-link activeicon="">
                                <ids-icon name="arrow"></ids-icon>
                                {getHref()}
                            </ids-link>
                        </IDSCol>
                    </IDSRow>
                </IDSContainer>
            </IDSListItem>

        </ids-list>
    );
};

export default SearchItem;
