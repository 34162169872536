import {addPaths, getConceptInHierarchy} from "../../artifact/concept/table/ConceptHierarchyUtil";
import {MRT_RowSelectionState} from "material-react-table";

export const modifySelectedConceptInArray = (arr: any[], code: string, display: string) => {
    const targetObj = arr.find(obj => obj.code === code);
    if (targetObj) {
        targetObj.selected = true;
        targetObj.display = display;
    }
};

export const modifySelectedConcept = (arr: any[], code: string, selected: boolean) => {
    const targetObj = arr.find(obj => obj.code === code);
    if (targetObj) {
        targetObj.selected = selected;
    }
};

const setRowSelectionViaNodesPathNumberArray = (nodesPath: number[], rowSelection: MRT_RowSelectionState) => {
    if (nodesPath.length > 0) {
        let rowSelectionPath = "";
        for (const num of nodesPath) {
            rowSelectionPath = rowSelectionPath === "" ? String(num) : (rowSelectionPath + "." + String(num));
        }
        rowSelection[rowSelectionPath] = true;
    }
}


export const syncRowSelectionWithConceptsToAddEvent = (menuName: string,
                                                       changeFromMenu: string | undefined,
                                                       reloadTableOk: boolean,
                                                       conceptCollection: any[],
                                                       snomedConceptsToAdd: any[] | undefined,
                                                       rowSelection: MRT_RowSelectionState,
                                                       setRowSelection: any) => {

    const changeFromDifferentMenuThanThis = (changeFromMenu === undefined) || changeFromMenu !== menuName;

    if (snomedConceptsToAdd !== undefined &&
        conceptCollection.length > 0 &&
        changeFromDifferentMenuThanThis &&
        reloadTableOk) {

        //handling rowselection if code is selected from other menue/view than this one.
        let paths: any = []
        addPaths(conceptCollection, null, paths);
        // @ts-ignore
        paths = paths.map(p => ({...p, root: conceptCollection[p.nodesPath[0]].code}))

        const lastAddedConceptCode: string | undefined = snomedConceptsToAdd[snomedConceptsToAdd.length - 1].code;
        if (lastAddedConceptCode !== undefined) {
            handlingOneRowselectFromOtherMenuThanThisOne(lastAddedConceptCode, paths, rowSelection);
        }

        //handling deSelection/removal in conceptToAdd, via summery table
        Object.keys(rowSelection).forEach((rowSelectionItem) => {
            var rowSelectionFoundInConceptsToAdd = snomedConceptsToAdd.filter(conceptToAdd => {

                const selectedConcept = getConceptInHierarchy(rowSelectionItem, conceptCollection);
                return conceptToAdd.code === selectedConcept.code;
            })
            if (rowSelectionFoundInConceptsToAdd.length == 0) {
                rowSelection[rowSelectionItem] = false;
            }
        })

        // @ts-ignore
        setRowSelection(state => ({...rowSelection}));
    } else if (changeFromDifferentMenuThanThis) {
        setRowSelection({});
    }
}

export const syncRowSelectionWithConceptsToAdd = (menuName: string,
                                                  changeFromMenu: string | undefined,
                                                  reloadTableOk: boolean,
                                                  conceptCollection: any[],
                                                  snomedConceptsToAdd: any[] | undefined,
                                                  rowSelection: MRT_RowSelectionState,
                                                  setRowSelection: any) => {

    // const changeFromDifferentMenuThanThis = (changeFromMenu === undefined) || changeFromMenu !== menuName;
    if (snomedConceptsToAdd !== undefined &&
        conceptCollection.length > 0
        // &&
        //  changeFromDifferentMenuThanThis &&
        //  reloadTableOk
    ) {

        //handling rowselection if code is saved in snomedConceptsToAdd.
        let paths: any = []
        addPaths(conceptCollection, null, paths);
        // @ts-ignore
        paths = paths.map(p => ({...p, root: conceptCollection[p.nodesPath[0]].code}))

        for (const conceptsToAdd of snomedConceptsToAdd) {
            handlingOneRowselectFromOtherMenuThanThisOne(conceptsToAdd.code, paths, rowSelection);
        }
        // @ts-ignore
        setRowSelection(state => ({...rowSelection}));
    } else if (Object.keys(rowSelection).length > 0) {
        setRowSelection({});
    }
}

const handlingOneRowselectFromOtherMenuThanThisOne = (conceptCode: string, paths: any = [], rowSelection: MRT_RowSelectionState) => {

    if (conceptCode !== undefined) {
        let nodesPath: number[] = [];
        try {
            // @ts-ignore
            const foundNode = paths.find(node => node.code === conceptCode);
            if (foundNode !== undefined) {
                nodesPath = foundNode.nodesPath;
            }
        } catch (error) {
            console.error("error: " + error);
        }
        setRowSelectionViaNodesPathNumberArray(nodesPath, rowSelection);
    }
}