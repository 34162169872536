import React, {FC, useEffect, useMemo, useRef, useState} from 'react';
import {
    MaterialReactTable,
    MaterialReactTableProps,
    MRT_ColumnDef, MRT_RowSelectionState,
    MRT_TableInstance,
} from 'material-react-table';
import {MRT_Localization_SV} from 'material-react-table/locales/sv';
import {
    muiSelectCheckboxPropsStyle,
    muiTableBodyCellPropsStyle,
    muiTableBodyPropsStyle,
    muiTableHeadRowPropsStyle,
    muiTablePropsFiveColumnStyle, muiTablePropsTwoColumnStyle,
} from "../../../../styles/TTStyles";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ImportExport from '@mui/icons-material/ImportExport';
import '@inera/ids-core/components/link/register';
import '@inera/ids-core/components/icon/register';

import {ICodeSystemConcept} from "fhir-typescript-models/dist/src/models/fhir/internal";
import {conceptList} from "../../../../model/defaultvalues/CodeSystemDefaultFormValue";
import {SNOMED_CT_URL} from "../../../../model/defaultvalues/Constant";
import {snomedRefsetMenu} from "../../../../constants/PanelConstant";
import {syncRowSelectionWithConceptsToAddEvent} from "../../../common/mrtable/CheckboxSelectUtil";
import {isDataColumn, isSearchResultDataColumn} from "../../../common/mrtable/MRTUtil";
import {setRowSelections} from "../../../../codesystem/snomed/util/RowSelectionUtil";
import {activeColumn, codeColumn, displayColumn} from "./ConceptColumns";

let renderCount = 0;

interface ConceptSNOMEDRefsetTableProps {
    snomedId: string,
    version: string,
    data: any,
    loadingRows: boolean,
    setLoadingRows: any,
    snomedConceptsToAdd?: any[],
    handleNodeClick: any,
    handleChange?: any,
    tabKey?: number,
    codeSystemIndex?: number,
    changeFromMenu?: string,
}

const ConceptSNOMEDRefsetTable: FC<ConceptSNOMEDRefsetTableProps> = ({
                                                                         snomedId,
                                                                         version,
                                                                         data,
                                                                         loadingRows,
                                                                         setLoadingRows,
                                                                         snomedConceptsToAdd,
                                                                         handleNodeClick,
                                                                         handleChange,
                                                                         tabKey,
                                                                         codeSystemIndex,
                                                                         changeFromMenu
                                                                     }) => {

    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
    const isCreateValueSetMode = tabKey !== undefined;
    const enableRowSelection = isCreateValueSetMode;
    const thisMenuName = snomedRefsetMenu;

    const snomedConceptsToAddString = JSON.stringify(snomedConceptsToAdd);


    /*    useEffect(() => {
            syncRowSelectionWithConceptsToAddEvent(thisMenuName,
                changeFromMenu,
                true,
                data,
                snomedConceptsToAdd,
                rowSelection,
                setRowSelection);
        }, [snomedConceptsToAddString]);*/


    useEffect(() => {
        //handling deSelection/removal in conceptToAdd, via summery table
        setRowSelections(data,
            snomedConceptsToAdd,
            rowSelection,
            setRowSelection);

    }, [snomedConceptsToAddString, data]);


    renderCount++;

    const columnDefinitions: MRT_ColumnDef<ICodeSystemConcept>[] = [
        codeColumn,
        displayColumn,
        activeColumn(' ')
    ];

    const columns = useMemo<MRT_ColumnDef<ICodeSystemConcept>[]>(
        //column definitions...
        () => columnDefinitions,
        [],
        //end
    );


    const commonTableSourceProps: Partial<MaterialReactTableProps<ICodeSystemConcept>> & {
        columns: MRT_ColumnDef<ICodeSystemConcept>[];
    } = {
        columns,
        enableFullScreenToggle: false,
    };

    const tableInstanceRef = useRef<MRT_TableInstance<ICodeSystemConcept>>(null);

    renderCount++;

    return (
        <div>

            <div>
                <>

                    <MaterialReactTable
                        {...commonTableSourceProps}
                        autoResetPageIndex={false}
                        columns={columns}
                        data={((data === undefined) || (data.length == 0) || (data[0].code === "")) ? conceptList : data}
                        enableRowOrdering={false}
                        enableColumnFilters={true}
                        enableSorting={true}

                        enableSelectAll={false}
                        enableSubRowSelection={false}
                        enableRowSelection={enableRowSelection}
                        onRowSelectionChange={setRowSelection}
                        enableRowActions={false}

                        localization={MRT_Localization_SV}
                        enableTopToolbar={false}
                        enableEditing={false}
                        enableHiding={false}
                        editDisplayMode="table"
                        positionActionsColumn="last"
                        enableExpanding={false}
                        enableExpandAll={false}
                        state={{
                            rowSelection,
                            isLoading: loadingRows
                        }}

                        muiSelectCheckboxProps={({row, table}) => ({
                            // @ts-ignore
                            defaultChecked: row.original.selected,
                            // @ts-ignore
                            disabled: row.original.inactive,
                            onClick: (event) => {
                                const id = row.id;
                                handleChange(event, row.original, SNOMED_CT_URL, version, row.id, codeSystemIndex, snomedId, thisMenuName);
                                // @ts-ignore
                                /*       handleRefSetRowClick(row.index);*/
                            },
                            // color: 'secondary',
                            sx: muiSelectCheckboxPropsStyle
                        })}


                        muiTableBodyCellProps={({cell, row, table}) => ({
                            sx: {
                                cursor: isDataColumn(cell) ? 'pointer' : 'default',
                                verticalAlign: 'top'
                            },
                            onClick: () => {
                                if (isDataColumn(cell)) {
                                    const code = row.original.code;
                                    const display = row.original.display;
                                    // @ts-ignore
                                    handleNodeClick(code, display, row.id);
                                }
                            },
                        })}


                        muiTableHeadRowProps={{
                            sx: muiTableHeadRowPropsStyle
                        }}

                        muiTableProps={{
                            sx: muiTablePropsTwoColumnStyle
                        }}

                        muiTableBodyProps={{
                            sx: muiTableBodyPropsStyle
                        }}


                        muiTablePaperProps={{
                            elevation: 0, //change the mui box shadow
                        }}

                        icons={{
                            MoreVertIcon: () => <ImportExport/>,
                            MoreHorizIcon: () => <MoreVertIcon/>,
                        }}

                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                header: '',
                                size: 10,
                            },
                            'mrt-row-drag': {
                                header: '',
                                size: 10,
                            },
                        }}

                        getSubRows={(originalRow) => {
                            return (
                                originalRow.concept
                            )
                        }}


                        muiColumnActionsButtonProps={{
                            children: <ExpandMore/>
                        }}

                    />
                </>
            </div>

        </div>
    );
};

export default ConceptSNOMEDRefsetTable;
