//@ts-nocheck
import React, {FC, useRef, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {useLocation} from "react-router-dom";
import {IDSCol, IDSContainer, IDSRow} from "@inera/ids-react";
import {IRHookSelectOption} from "../../common/rhookform/IRHookSelectOption";
import {getRenderCountText} from '../../common/RenderCount';
import VersionDescription from "./VersionDescription";
import VersionStatusDialog from "./VersionStatusDialog";
import StatusButton from "./StatusButton";
import RHookFormTextFieldSpan from "../../common/rhookform/RHookFormTextFieldSpan";
import {publishTabName} from "../../common/page/TabName";
import {IRHookFormValidationMessage} from "../../common/rhookform/IRHookFormValidationMessage";
import {permissionToEdit} from "../../../user/PermissionUtil";
import {codesystem, conceptmap, valueset} from "../../../model/defaultvalues/Constant";
import ValueSetWarnStatusDialog from "../valueset/update/ValueSetWarnStatusDialog";
import {
    perFormValidationInternalMetaDataWarnings_hasWarnings
} from "../../common/validation/ValidationInternalMetaDataWarningRulesUtil";


let renderCount = 0;

interface VersionInformationProps {
    artifactType: string,
    isLocked?: boolean
    editDisabled?: boolean
    errorMessageList?: IRHookFormValidationMessage[],
    warnMessageList?: IRHookFormValidationMessage[],
    handleWarnMessageList?: any,
    handleErrorMessageList?: any,
    hasError?: boolean,
    hasWarning?: boolean,
    isRequesting?: boolean,
    submitArtefact?: any,
    validateCodes?: any
}


const VersionInformation: FC<VersionInformationProps> = ({
                                                             artifactType,
                                                             isLocked,
                                                             editDisabled,
                                                             errorMessageList,
                                                             warnMessageList,
                                                             handleWarnMessageList,
                                                             handleErrorMessageList,
                                                             hasError,
                                                             hasWarning,
                                                             isRequesting,
                                                             submitArtefact,
                                                             validateCodes
                                                         }) => {

    const methods = useFormContext();
    const location = useLocation();

    renderCount++;

    const publicationStatusOptions: IRHookSelectOption[] = [
        {label: "Utkast", value: "draft"},
        {label: "Aktiv", value: "active"},
        {label: "Avställd", value: "retired"}
    ];

    const publicationStatusOptionsWhenActive: IRHookSelectOption[] = [
        {label: "Aktiv", value: "active"},
        {label: "Avställd", value: "retired"}
    ];

    const statusDialogRef = useRef("set-status-dialog");
    const valueSetWarnDialogRef = useRef("valueset-status-dialog");
    const [showStatusDialog, setShowStatusDialog] = useState(false);
    const [showValueSetCodesWarnDialog, setShowValueSetCodesWarnDialog] = useState(false);
    const [requestedStatus, setRequestedStatus] = useState(methods.getValues("status"));

    const closeDialog = (submittedForm: boolean) => {
        if (submittedForm == false) {
            if (methods.getValues("status") === "active") {
            } else if (methods.getValues("status") === "retired") {
                methods.setValue("status", "active")
            }
        }
        setShowStatusDialog(false);
    }

    const validateAndSetStatusFromDraftToActive = () => {
        let warningsExist = false;
        let formWarningsExist = false;
        let valueSetWarning = false
        if ((artifactType === valueset) && validateCodes()) {
            warningsExist = true;
            valueSetWarning = true;
        } else if ((artifactType === codesystem)) {
            warningsExist = validateCodes();
        }

        formWarningsExist = perFormValidationInternalMetaDataWarnings_hasWarnings(methods, handleWarnMessageList, warnMessageList, errorMessageList);

        //Dependencies has no empty values
        if (!warningsExist && !formWarningsExist &&
            errorMessageList.length === 0 && warnMessageList.length === 0) {
            setShowStatusDialog(true);
            setRequestedStatus("active");
            statusDialogRef.current.click();
        } else if (valueSetWarning) {
            methods.setValue("status", "draft");
            setShowValueSetCodesWarnDialog(true);
        } else {
            methods.setValue("status", "draft");
        }
    }

    const openRetireWindow = () => {
        setShowStatusDialog(true);
        setRequestedStatus("retired");
        statusDialogRef.current.click();
    }

    return (

        <div>
            <VersionStatusDialog dialogRef={statusDialogRef}
                                 editDisabled={editDisabled}
                                 artifactType={artifactType}
                                 showDialog={showStatusDialog}
                                 setShowDialog={setShowStatusDialog}
                                 isRequesting={isRequesting}
                                 requestedStatus={requestedStatus}
                                 closeDialog={closeDialog}
                                 submitArtefact={submitArtefact}/>

            {(artifactType === valueset) &&
                <ValueSetWarnStatusDialog dialogRef={valueSetWarnDialogRef}
                                          showDialog={showValueSetCodesWarnDialog}
                                          setShowDialog={setShowValueSetCodesWarnDialog}/>


            }
            <IDSContainer gutterless={true}>
                <IDSRow justify="space-between">
                    <IDSCol cols="6">
                        {getRenderCountText(renderCount)} <br/> <br/>
                    </IDSCol>
                </IDSRow>
                <IDSRow justify="space-between">
                    <IDSCol cols="6">
                        <div>
                            <RHookFormTextFieldSpan label="Version (obligatorisk)" name="version"
                                                    tabName={publishTabName}
                                                    handleErrorMessageList={handleErrorMessageList}
                                                    handleWarnMessageList={handleWarnMessageList}
                                                    disabled={isLocked}/>
                        </div>
                    </IDSCol>
                    <IDSCol cols="6">
                    </IDSCol>
                </IDSRow>
                <IDSRow justify="space-between">
                    <IDSCol cols="6">
                        {/*                 {!isSnomedPath(location.pathname) &&*/}
                        <VersionDescription editDisabled={isLocked}
                                            handleWarnMessageList={handleWarnMessageList}/>
                        {/*     }*/}
                        <br/>
                    </IDSCol>
                    <IDSCol cols="6">
                    </IDSCol>
                </IDSRow>
                <IDSRow justify="space-between">
                    <IDSCol cols="6">
                        {(methods.getValues("status") === "draft" && editDisabled == false) &&
                            <div className={'container'} key={"container_activatebutton"}>
                                <StatusButton
                                    labelForActionInSwedish={"Aktivera"}
                                    editDisabled={editDisabled}
                                    errorMessageList={errorMessageList}
                                    artifactType={artifactType}
                                    onClickMethod={validateAndSetStatusFromDraftToActive}/>
                                <br/> <br/>
                            </div>
                        }
                        {(methods.getValues("status") === "active" && permissionToEdit(methods)) &&
                            <div className={'container'} key={"container_retirebutton"}>
                                <StatusButton
                                    labelForActionInSwedish={"Ställ av"}
                                    editDisabled={editDisabled}
                                    errorMessageList={errorMessageList}
                                    artifactType={artifactType}
                                    onClickMethod={openRetireWindow}/>
                                <br/> <br/>
                            </div>
                        }
                    </IDSCol>
                </IDSRow>
            </IDSContainer>
        </div>
    );
};

export default VersionInformation;
