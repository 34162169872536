import {getObjectKeyValue} from "../../../common/CommonFunctions";


export const getConceptHierarchyPath = (indexNumberArray: number[]) => {

    let conceptHierarchyPath: string = "";

    for (let i = 0; i < indexNumberArray.length; i++) {
        const arrayNumber = indexNumberArray[i];
        if (i == 0) {
            conceptHierarchyPath = "concept." + arrayNumber
        } else if (i != 0) {
            conceptHierarchyPath = conceptHierarchyPath + ".concept." + arrayNumber;
        }
    }
    return conceptHierarchyPath;

}

export const getConceptChildHierarchyPath = (indexNumberArray: number[]) => {
    let conceptHierarchyPath: string = getConceptHierarchyPath(indexNumberArray);
    conceptHierarchyPath = conceptHierarchyPath + ".concept";
    return conceptHierarchyPath;
}


export const getConceptInHierarchy = (rowindex: string, conceptCollection: any[]): any | undefined => {

    const indexStringArray = rowindex.split(".");
    const indexNumberArray = indexStringArray.map((str) => Number(str));

    let selectedConcept = undefined;
    if (indexNumberArray.length == 1) {
        // @ts-ignore
        selectedConcept = conceptCollection[indexNumberArray[0]];
    } else {
        let conceptHierarchyPath = getConceptHierarchyPath(indexNumberArray.slice());
        let conceptHierarchyPathToSearchFor = conceptHierarchyPath.replace(/^(concept\.)/, "");
        selectedConcept = getObjectKeyValue(conceptCollection, conceptHierarchyPathToSearchFor);
    }

    return selectedConcept;
}


export function getConceptInHierarchyByCode(object: any, codeToSearchFor: string): any {
    if (codeToSearchFor !== undefined) {

        if (object.hasOwnProperty('code') && object["code"] == codeToSearchFor)
            return object;

        for (var i = 0; i < Object.keys(object).length; i++) {
            if (typeof object[Object.keys(object)[i]] == "object") {
                var o = getConceptInHierarchyByCode(object[Object.keys(object)[i]], codeToSearchFor);
                if (o != null) {
                    return o;
                }
            }
        }
    }
    return null;
}


export function addPaths(conceptCollection: any[], subPath : any , pathsAcc : any[]) {
    conceptCollection.forEach((kid, index) => {
        const newPath = subPath === null ? [index] : [...subPath, index];
        pathsAcc.push({
            code: kid.code,
            nodesPath: newPath
        });
        if(Array.isArray(kid.concept)) {
            addPaths(kid.concept, newPath, pathsAcc)
        }
    })
}

