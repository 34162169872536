import React, {useState} from "react";
import '@inera/ids-core/styles/themes/inera-admin.css';
import {IDSDialog} from "@inera/ids-react";
import CreateDialogForm from "./CreateDialogForm";
import {getArtifactSwedishLabel} from "../../CommonFunctions";

const CreateDialog = (props) => {

    const [resetDialog, setResetDialog] = useState(false);
    const closeDialog = () => props.setShowCreateDialog(false);


    return (
        <div>
            <IDSDialog overlay={true} persistent={true} dismissible="true" show={props.showCreateDialog}
                       headline={"Skapa " + getArtifactSwedishLabel(props.artifactType)}
                       onClosed={() => {
                       }}>
                <a href="javascript:void(0)"
                   tabindex="-1"
                   trigger="true" ref={props.createButtonRef} style={{color: '#FFF'}}></a>
                <p style={{color: '#FFF'}}>..................................................................................................................................</p>
                <CreateDialogForm artifactType={props.artifactType} closeDialog={closeDialog}
                                  resetDialog={resetDialog}/>
            </IDSDialog>
        </div>
    );
};
export default CreateDialog;