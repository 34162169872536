import React, {FC, useState} from 'react';

import {IDSCol, IDSContainer, IDSRow} from "@inera/ids-react";

import {IValueSetComposeIncludeConcept, ValueSet} from "fhir-typescript-models/dist/src/models/fhir/internal";
import {fhirUrl, SNOMED_CT_URL} from "../../../model/defaultvalues/Constant";
import {requestParent} from "../../../services/snomed/SnomedService";
import Client from "fhir-kit-client";
import {Grid, Tab, Tabs} from "@material-ui/core";
import {a11yProps, TabPanel} from "../../../components/common/tab/TabCommons";
import {useGmailTabItemStyles, useGmailTabsStyles} from "@mui-treasury/styles/tabs";
import SnomedTabPanelTaxonomi from "./SnomedTabPanelTaxonomi";
import SnomedTabPanelSearch from "./SnomedTabPanelSearch";
import {requestParentViaCodeOnly} from "../expand/SnomedParentHierarchyUtil";
import IConceptSearchResultItem from "../search/model/IConceptSearchResultItem";
import SnomedCodeDetailsColumn from "./SnomedCodeDetailsColumn";
import {getDataWithParentChildDirection, getExpandSystemVersionUrlPart} from "../expand/SnomedExpandUtil";
import {
    display_flex, display_width_4,
    display_width_48_leftAligned,
    display_width_48_rightAligned
} from "../../../styles/TTStyles";
import SnomedTabPanelRefset from "./SnomedTabPanelRefset";
import {collectContains} from "../transformer/SnomedTransformer";
import {getBaseId} from "../util/SnomedUrlUtil";


let renderCount = 0;
const indicatorColors = ['#A33662', '#A33662', '#A33662', '#A33662'];

const fhirClient = new Client({baseUrl: fhirUrl});

interface SnomedCodeDashBoardProps {
    id: string,
    version: string
    snomedConceptsToAdd?: Array<IValueSetComposeIncludeConcept>,
    handleChange?: any,
    tabKey?: number,
    codeSystemIndex?: number,
    changeFromMenu?: string
}


const SnomedCodeDashBoard: FC<SnomedCodeDashBoardProps> = ({
                                                               id,
                                                               version,
                                                               snomedConceptsToAdd,
                                                               handleChange,
                                                               tabKey,
                                                               codeSystemIndex,
                                                               changeFromMenu
                                                           }) => {

    const [selectedConceptToShow, setSelectedConceptToShow] = useState<IConceptSearchResultItem | undefined>(undefined);

    const [selectedCode, setSelectedCode] = useState<string>();
    const [selectedCodeDisplay, setSelectedCodeDisplay] = useState<string>();
    const [selectedCodeRelatedData, setSelectedCodeRelatedData] = useState<ValueSet>();

    const [isRequstingChildren, setIsRequstingChildren] = useState<boolean>(false);
    const [parentChildDirection, setParentChildDirection] = useState<boolean>(true);
    const [parentChildDirectionData, setParentChildDirectionData] = useState<ValueSet>();
    const [childConceptCollection, setChildConceptCollection] = useState<IConceptSearchResultItem[]>([]);
    const tabItem2Styles = useGmailTabItemStyles({color: indicatorColors[0]});
    const tabsStyles = useGmailTabsStyles({indicatorColors});
    const isCreateValueSetMode = tabKey !== undefined;
    const [onChangeCode, setOnChangeCode] = useState<string>();
    const [isChangeCodeSameAsSelected, setIsChangeCodeSameAsSelected] = useState<boolean>(false);

    const parentChildDirectionDataAsString = JSON.stringify(parentChildDirectionData);

    const handleSnomedChange = (e: any, concept: any,
                                system: string,
                                version: string,
                                rowindex: string,
                                codeSystemIndex: number,
                                id: string,
                                menuName: string) => {

        setOnChangeCode(concept.code);
        if (concept.code === selectedConceptToShow?.code) {
            setIsChangeCodeSameAsSelected(true)
        } else if (isChangeCodeSameAsSelected) {
            setIsChangeCodeSameAsSelected(false)
        }

        handleChange(e, concept, system, version, rowindex, codeSystemIndex, id, menuName);
    }

    const tabs = [
        {
            key: 0,
            id: 0,
            title: "Taxonomi"
        },
        {
            key: 1,
            id: 1,
            title: "Sök"
        },
        {
            key: 2,
            id: 2,
            title: "Refset"
        }]

    const [tabList, setTabList] = useState(tabs);


    const handleNodeClick = async (concept: IConceptSearchResultItem, version : string) => {

        if (concept.code !== selectedConceptToShow?.code) {

            setChildConceptCollection([]);
            setIsRequstingChildren(true);

            if (concept.synonyms !== undefined) {
                setSelectedConceptToShow(concept);
            } else {
                /*            let foundConcepts : IConceptSearchResultItem[] = [];
                            await lookupCodeSystemCodeWithChildConceptCodes(concept.code, foundConcepts);*/

                const url = getExpandSystemVersionUrlPart(concept.code, getBaseId(id), SNOMED_CT_URL);
                fhirClient.request(url, {}).then(response => {
                    const returnedValueSet: ValueSet = response as unknown as ValueSet;
                    const collectedSearchResult = collectContains(returnedValueSet);

                    if (collectedSearchResult.length > 0) {
                        setSelectedConceptToShow(collectedSearchResult[0]);
                        concept = collectedSearchResult[0];
                    }
                })
                    .catch(e => {
                        console.error("FhirClient Create Error Response" + JSON.stringify(e));
                        if (e.response.status == 400) {
                            console.error(JSON.stringify(e.response))
                        }
                    });

            }

            if (concept !== undefined) {
                let responseValueSet: ValueSet = await requestParent(concept.code, concept.display, "parent", concept.code, version);

                await getDataWithParentChildDirection(responseValueSet, setChildConceptCollection);

                //  performRequestParentViaCodeOnly(concept.code, concept.display);
            }
            setIsRequstingChildren(false);
        }
    };

    const performRequestParentViaCodeOnly = (code: string, display: string) => {
        requestParentViaCodeOnly(code, display, setParentChildDirection,
            setSelectedCode, setSelectedCodeDisplay,
            setParentChildDirectionData, handleSelectedCode, version);
    };


    const [tabValue, setTabValue] = useState(0);
    const handleTabChange = (event: any, value: any) => {
        setTabValue(value);
    };


    const handleSelectedCode = (code: string, display: string, valueSet: ValueSet) => {
        setSelectedCode(code);
        setSelectedCodeDisplay(display);
        setSelectedCodeRelatedData(valueSet);
    };

    /*    const lookupCodeSystemCode = async (code: string) => {
            const concept = await lookupSnomedCode(code);
            // @ts-ignore
            setSelectedConceptToShow(concept);
        };*/


    /*    const handleRowClickShowParent = (rowIndex: number) => {
            // @ts-ignore
            const code = refSetTableData[rowIndex].code;
            // @ts-ignore
            const display = refSetTableData[rowIndex].display;
            requestParentViaExpression(code, display);
        }*/


    renderCount++;

    return (
        <div>
            <IDSContainer gutterless={true}>
                <IDSRow justify="space-between">
                    <IDSCol>
                        <div>
                            <Grid container alignItems="center" justifyContent="flex-start">
                                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                    <Tabs
                                        classes={tabsStyles}
                                        value={tabValue}
                                        onChange={handleTabChange}
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        TabIndicatorProps={{
                                            children: <div className={`MuiIndicator-${tabValue}`}/>,
                                        }}
                                    >
                                        {tabList.map(tab => (
                                            <Tab
                                                // @ts-ignore
                                                key={tab.key.toString()}
                                                // @ts-ignore
                                                value={tab.id}
                                                label={
                                                    <div className={'MuiTabItem-labelGroup'}>
                                                        <div className={'MuiTabItem-label'}>

                                                            <strong>{
                                                                // @ts-ignore
                                                                tab.title
                                                            }</strong>
                                                            {/*<span className={'MuiTabItem-tag'}>Codesystem</span>*/}
                                                        </div>
                                                    </div>}
                                                /* className="mytab"*/
                                                classes={tabItem2Styles}
                                                {...a11yProps(
                                                    // @ts-ignore
                                                    tab.key.toString()
                                                )}
                                            />
                                        ))}
                                    </Tabs>
                                </Grid>
                                <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                                </Grid>
                            </Grid>
                            <Grid container alignItems="center" justifyContent="flex-start">
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                    {tabList.map((tab, ix) => (
                                        <div>
                                            <TabPanel value={tabValue} index={
                                                // @ts-ignore
                                                tab.key
                                            }>

                                                <div style={display_flex}>
                                                    <div style={display_width_48_leftAligned} id="scrollbar-style">
                                                        {
                                                            tab.key == 0 &&
                                                            <SnomedTabPanelTaxonomi
                                                                id={id}
                                                                version={version}
                                                                snomedConceptsToAdd={snomedConceptsToAdd}
                                                                handleNodeClick={handleNodeClick}
                                                                handleChange={handleSnomedChange}
                                                                tabKey={tabKey}
                                                                codeSystemIndex={codeSystemIndex}
                                                                onChangeFromMenu={changeFromMenu}/>
                                                        }
                                                        {
                                                            tab.key == 1 &&
                                                            <SnomedTabPanelSearch
                                                                id={id}
                                                                version={version}
                                                                snomedConceptsToAdd={snomedConceptsToAdd}
                                                                handleChange={handleSnomedChange}
                                                                handleNodeClick={handleNodeClick}
                                                                tabKey={tabKey}
                                                                codeSystemIndex={codeSystemIndex}/>
                                                        }
                                                        {
                                                            tab.key == 2 &&
                                                            <SnomedTabPanelRefset
                                                                id={id}
                                                                version={version}
                                                                handleNodeClick={handleNodeClick}
                                                                snomedConceptsToAdd={snomedConceptsToAdd}
                                                                handleChange={handleSnomedChange}
                                                                tabKey={tabKey}
                                                                codeSystemIndex={codeSystemIndex}
                                                                changeFromMenu={changeFromMenu}/>
                                                        }
                                                    </div>
                                                    <div style={display_width_4}></div>
                                                    {/*   <hr/>*/}
                                                    <div style={display_width_48_rightAligned}>
                                                        <SnomedCodeDetailsColumn
                                                            id={id}
                                                            version={version}
                                                            childConceptCollection={childConceptCollection}
                                                            setChildConceptCollection={setChildConceptCollection}
                                                            selectedConceptToShow={selectedConceptToShow}
                                                            isCreateValueSetMode={isCreateValueSetMode}
                                                            handleNodeClick={handleNodeClick}
                                                            handleChange={handleSnomedChange}
                                                            tabKey={tabKey}
                                                            codeSystemIndex={codeSystemIndex}
                                                            isRequestingChildren={isRequstingChildren}
                                                            setIsRequstingChildren={setIsRequstingChildren}
                                                            snomedConceptsToAdd={snomedConceptsToAdd}
                                                            changeFromMenu={changeFromMenu}
                                                            onChangeCode={onChangeCode}
                                                            isChangeCodeSameAsSelected={isChangeCodeSameAsSelected}/>
                                                    </div>
                                                    {/*         <hr/>*/}
                                                </div>
                                                <br/>

                                            </TabPanel>
                                        </div>
                                    ))}
                                </Grid>
                            </Grid>
                        </div>
                    </IDSCol>
                </IDSRow>
            </IDSContainer>
        </div>
    );
};

export default SnomedCodeDashBoard;
