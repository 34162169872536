import React from "react";
import {composeIncludeObjPath} from "../VSConstant";

export const removeConceptFromValueSet = (concept, system, version, conceptsToAdd, setConceptsToAdd) => {

    concept.selected = false;
    for (let i = 0; i < conceptsToAdd.length; i++) {

        let include = conceptsToAdd[i]

        if ((include.system === system && include.version === version) ||
            (include.system === system && include.version === undefined)) {

            const pos = include.concept.map(e => e.code).indexOf(concept.code);

            if (pos !== -1) {
                include.concept.splice(pos, 1);

                if (include.concept.length === 0) {
                    conceptsToAdd.splice(i, 1);
                }

                var array = [...conceptsToAdd]; // make a separate copy of the array
                setConceptsToAdd(array);
            }
        }

    }
}

export const removeCodeSystem = (id, system, version, tabKey, conceptsToAdd, setConceptsToAdd, methods) => {
    const hasSelectedCodes = hasSelectedCodesFromCodeSystemToRemove(id, system, version, tabKey, conceptsToAdd);
    if (hasSelectedCodes) {
        conceptsToAdd.splice(tabKey, 1); // 2nd parameter means remove one item only
        var newConceptsToAddArray = [...conceptsToAdd]; // make a separate copy of the array
        setConceptsToAdd(newConceptsToAddArray);
    }

    const includesInForm = methods.getValues(composeIncludeObjPath);
    if (includesInForm !== undefined && Array.isArray(includesInForm)) {
        let includesInFormAfterRemove = includesInForm.filter(include => include.system !== system && include.version !== version);
        var newincludesInForm = [...includesInFormAfterRemove]; // make a separate copy of the array
        methods.setValue(composeIncludeObjPath, newincludesInForm);
    }
}

export const hasSelectedCodesFromCodeSystemToRemove = (id, system, version, tabKey, conceptsToAdd) => {
    let hasSelectedCodes = false;
    const include = conceptsToAdd[tabKey];

    if (include && include.id !== undefined && include.id === id) {
        hasSelectedCodes = true;
    } else if (include && version &&
        include.version &&
        include.version.trim() !== "" &&
        include.version === version &&
        include.system === system) {
        hasSelectedCodes = true;
    } else if (include && system && include.system === system) {
        hasSelectedCodes = true;
    }
    return hasSelectedCodes;
}


export const addConceptToValueSet = async (concept, system, version, tabindex, e, conceptsToAdd, setConceptsToAdd, id) => {

    if (concept !== undefined && concept !== null) {

        let systemAndVersionInconceptsToAdd = false;
        for (let codesystem of conceptsToAdd) {
            if (codesystem.system === system && codesystem.version === version) {
                systemAndVersionInconceptsToAdd = true;
            }
        }

        if (!systemAndVersionInconceptsToAdd) {

            var ninclude = createInclude(concept, system, version, tabindex, id)

            setConceptsToAdd([
                ...conceptsToAdd,
                ninclude
            ]);
        } else {

            let conceptInAddArray = undefined;
            for (let conceptToAdd of conceptsToAdd) {
                if (conceptToAdd.system === system && conceptToAdd.version === conceptToAdd.version) {
                    conceptInAddArray = conceptToAdd;
                    break;
                }
            }

            if (conceptInAddArray !== null) {
                if (concept instanceof Array) {

                    for (let c of concept) {

                        //add if not already existing
                        if (conceptInAddArray.concept.find(conceptsToAddObj => {
                            return conceptsToAddObj.code === c.code
                        }) === undefined) {
                            c.selected = true;
                            conceptInAddArray.concept.push(c);
                        }
                    }
                } else {
                    if (conceptInAddArray !== undefined) {
                        let conceptInConceptsToAddArray = conceptInAddArray.concept.find(conceptsToAddObj => {
                            return conceptsToAddObj.code === concept.code
                        });


                        if (conceptInConceptsToAddArray === undefined) {
                            concept.selected = true;
                            //add if not already existing
                            conceptInAddArray.concept.push(concept);
                        } else {
                            //update
                            conceptInConceptsToAddArray.selected = true;
                            conceptInConceptsToAddArray.display = concept.display;
                        }
                    }
                }
            }

            setConceptsToAdd([
                ...conceptsToAdd
            ]);
        }
    }
};

const createInclude = (concept, system, version, tabindex, id) => {

    var ninclude = {};
    ninclude.system = system;
    ninclude.version = version;
    ninclude.tabindex = tabindex
    ninclude.id = id;

    let includeConceptList = [];

    if (concept instanceof Array) {
        for (let c of concept) {
            c.system = system;
            c.version = version;
            c.selected = true;
        }
        includeConceptList = concept;

    } else {
        concept.system = system;
        concept.version = version;
        concept.selected = true;
        includeConceptList.push(concept);
    }

    ninclude.concept = includeConceptList;
    return ninclude;
};


export const addOrRemoveAllCodeSystemConceptsToValueSet = (conceptArrayInCodeSystem, system, version, tabkey, allSelected, includeArraySummery, setConceptsToAdd, id) => {

    if (conceptArrayInCodeSystem !== undefined && conceptArrayInCodeSystem !== null) {
        let includeInSummery = includeArraySummery.find(o => o.system === system && (o.version === version));

        if (allSelected && includeInSummery === undefined) {
            var ninclude = {};
            ninclude.system = system;
            ninclude.version = version;
            ninclude.tabindex = tabkey;
            ninclude.id = id;

            for (let c of conceptArrayInCodeSystem) {
                c.selected = true;
            }

            let includeConceptList = []
            includeConceptList.push(...conceptArrayInCodeSystem)
            ninclude.concept = includeConceptList;

            setConceptsToAdd([
                ...includeArraySummery,
                ninclude
            ]);
        } else if (includeInSummery !== undefined) {

            if (includeInSummery.concept !== undefined) {
                let newConceptsToAdd = [];
                for (let conceptInCodeSystem of conceptArrayInCodeSystem) {
                    //Collect concepts to add
                    if (allSelected && (includeInSummery.concept.find(x => x.code === conceptInCodeSystem.code) === undefined)) {
                        conceptInCodeSystem.system = system;
                        conceptInCodeSystem.version = version;
                        conceptInCodeSystem.selected = true;
                        newConceptsToAdd.push(conceptInCodeSystem);
                    }
                    //Remove
                    else if (!allSelected && (includeInSummery.concept.find(x => x.code === conceptInCodeSystem.code) !== undefined)) {
                        let conceptInSummery = includeInSummery.concept.find(x => x.code === conceptInCodeSystem.code);
                        const index = includeInSummery.concept.indexOf(conceptInSummery);
                        conceptInCodeSystem.selected = false;

                        if (index > -1) { // only splice array when item is found
                            includeInSummery.concept.splice(index, 1); // 2nd parameter means remove one item only
                        }
                    }
                }

                //Add collection to summery
                if (newConceptsToAdd.length > 0) {
                    if (includeArraySummery.length > 0) {

                        let includeInSummeryToAddConceptsTo = includeArraySummery.find(include => (include.system === newConceptsToAdd[0].system)
                            && (include.version === newConceptsToAdd[0].version));
                        includeInSummeryToAddConceptsTo.concept.push(...newConceptsToAdd);
                    }
                }

                setConceptsToAdd([
                    ...includeArraySummery
                ]);
            }

        }
    }
};