export const handleUpdatedStatus = (data: any,
                                                       initialStatus: string | undefined,
                                                       setInitialStatus: any,
                                                       setIsFromDraftToActiveSave: any,
                                                       setIsFromActiveToRetiredSave: any) : boolean => {
    const updatedStatus = initialStatus !== data.status;
    if (updatedStatus) {
        if (initialStatus === "draft" && data.status === "active") {
            setIsFromDraftToActiveSave(true);
        } else if (initialStatus === "active" && data.status === "retired") {
            setIsFromActiveToRetiredSave(true);
        }
        setInitialStatus(data.status);
    }
    return updatedStatus;
}