import {TAB_TITLE_DEFAULT, TAB_SUMMERY_TITLE, TAB_VERSION_DEFAULT} from "../VSConstant";

export const getIsTabCodeSystemSelected = (tabList, tabKey) => {
    if (tabList.length !== 0) {
        return (tabList[tabKey].title === TAB_TITLE_DEFAULT && tabList[tabKey].version === TAB_VERSION_DEFAULT) ? false : true;
    } else {
        return false;
    }
}

export const isTabCodesSummery = (tabList, tabKey) => {
    if (tabList.length !== 0) {
        return (tabList[tabKey].title === TAB_SUMMERY_TITLE);
    }
}

export const getTabKeyAfterSummeryTabIncluded = (tabKeyWithoutSummeryTabIncluded) => {
    return tabKeyWithoutSummeryTabIncluded + 1;
}

export const getCodeSystemKeyFromTabKeyThatIncludeSummeryTab = (tabKeyWithSummeryTabIncluded) => {
    return tabKeyWithSummeryTabIncluded - 1;
}