import React from "react";
import {ValueSet} from "fhir-typescript-models/dist/src/models/fhir/internal";
import {requestParent} from "../../../services/snomed/SnomedService";

export async function requestParentViaCodeOnly(code: string, display: string, setParentChildDirection: any,
                                               setSelectedCode: any, setSelectedCodeDisplay: any,
                                               setParentChildDirectionData: any, handleSelectedCode: any, version : string) {
    // @ts-ignore
    let responseValueSet: ValueSet = await requestParent(code, display, "parent", code, version);

    await setParentChildDirection(true);
    await setSelectedCode(code);
    await setSelectedCodeDisplay(display);

    // @ts-ignore
    if (responseValueSet !== undefined && responseValueSet.expansion !== undefined && responseValueSet.expansion.total != 0) {
        await setParentChildDirectionData(responseValueSet);
    } else {
        requestParentViaExpression(code, display, setParentChildDirection, handleSelectedCode, version);
    }
}

export async function requestParentViaExpression(code: string, display: string, setParentChildDirection: any, handleSelectedCode: any, version : string) {
    // @ts-ignore
    let responseValueSet: ValueSet = await requestParent(code, display, "expression", ">> " + code);
    await setParentChildDirection(false);
    await handleSelectedCode(code, display, responseValueSet);
}