import React, {FC} from "react";
import {IRHookFormValidationMessage} from "../../common/rhookform/IRHookFormValidationMessage";
import {SaveNotification} from "../../common/page/header/SaveNotification";
import {IDSCard, IDSCol, IDSContainer, IDSIcon, IDSRow} from "@inera/ids-react";
import {
    emptyIndentifierFormValues,
    emptyIndentifierValues
} from "../../../model/defaultvalues/MetaDataDefaultFormValue";

interface SearchTotalMessageProps {
    searchPerformed: boolean,
    total: number,
    lastSearchPhrase: string
}


export const SearchTotalMessage: FC<SearchTotalMessageProps> = ({
                                                                    searchPerformed,
                                                                    total,
                                                                    lastSearchPhrase,
                                                                }) => {

    let hitsText = total > 1 ? "träffar" : "träff";

    return (
        <div>

            {searchPerformed &&
                <div>
                    Din sökning på "{lastSearchPhrase}" gav {total} {hitsText}
                </div>
            }
        </div>
    );
};



