import React, {FC, useState} from 'react';
import {useForm, UseFormProps, UseFormReturn} from 'react-hook-form';
import {IDSButton, IDSCol, IDSContainer, IDSInput, IDSRow, IDSSpinner} from "@inera/ids-react";
import Client from "fhir-kit-client";
import {fhirUrl, SNOMED_CT_URL} from "../../../model/defaultvalues/Constant";
import IConceptSearchItem from "./model/IConceptSearchItem";
import {getExpandSystemVersionUrlPart} from "../expand/SnomedExpandUtil";
import {ValueSet} from "fhir-typescript-models/dist/src/models/fhir/internal";
import {SearchTotalMessage} from "../../../components/artifact/search/SearchTotalMessage";
import SnomedSearchResult from "./SnomedSearchResult";
import IConceptSearchResultItem from "./model/IConceptSearchResultItem";
import Spinner from "react-bootstrap/Spinner";
import {collectContains} from "../transformer/SnomedTransformer";
import {getBaseId} from "../util/SnomedUrlUtil";


let renderCount = 0;

interface SNOMEDSearchFormProps {
    id: string,
    version: string
    snomedConceptsToAdd?: any[],
    handleChange?: any,
    handleNodeClick: any
    tabKey?: number,
    codeSystemIndex?: number
}

const fhirClient = new Client({baseUrl: fhirUrl});

export const SnomedSearchForm: FC<SNOMEDSearchFormProps> = ({
                                                                id,
                                                                version,
                                                                snomedConceptsToAdd,
                                                                handleChange,
                                                                handleNodeClick,
                                                                tabKey,
                                                                codeSystemIndex
                                                            }) => {


    const [isRequesting, setIsRequesting] = useState(false);
    const [searchResult, setSearchResult] = useState<IConceptSearchResultItem[]>([]);
    const [total, setTotal] = useState(0);
    const [searchPhrase, setSearchPhrase] = useState<string>("");
    const [lastSearchPhrase, setLastSearchPhrase] = useState<string>("");

    const emptyForm = {
        title: "",
        code: ""
    }

    const methods: UseFormReturn<IConceptSearchItem, UseFormProps> = useForm<IConceptSearchItem>({
        defaultValues: emptyForm
        /*        ,
                resolver: yupResolver(schemaSnomedSearch),*/
    });

    const onChangeSearchPhrase = (e: any) => {
        var trimmedStr = e.target.value.trimStart();
        setSearchPhrase(trimmedStr);
    };


    const handleSearchFormNodeClick = (code: string, display: string, rowid: string) => {
        const concept: IConceptSearchResultItem | undefined = searchResult.find(item => item.code === code);
        handleNodeClick(concept, version);
    };

    const submitSearchForm = () => {
        setLastSearchPhrase(searchPhrase)
        setIsRequesting(true);


        const url = getExpandSystemVersionUrlPart(searchPhrase, getBaseId(id), SNOMED_CT_URL);

        fhirClient.request(url, {}).then(response => {
            const returnedValueSet: ValueSet = response as unknown as ValueSet;
            const collectedSearchResult = collectContains(returnedValueSet);

            setTotal(collectedSearchResult.length);
            setSearchResult(collectedSearchResult);

            //     methods.reset(emptyForm)
            setIsRequesting(false);
        })
            .catch(e => {
                setIsRequesting(false);
                console.error("FhirClient Create Error Response" + JSON.stringify(e));
                if (e.response.status == 400) {
                    console.error(JSON.stringify(e.response))
                }
            });

    };

    const handleSearchIfEnter = (e: any) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            submitSearchForm();
        }
    }

    renderCount++;

    return (
        <div>
            <SearchTotalMessage
                searchPerformed={isRequesting}
                total={total}
                lastSearchPhrase={lastSearchPhrase}
            />
            <br/>
            <IDSContainer gutterless={true}>
                <IDSRow justify="space-between">
                    <IDSCol cols="12">
                        <IDSContainer gutterless={true}>
                            <IDSRow>
                                <IDSCol cols="12">
                                    <IDSInput onChange={onChangeSearchPhrase}>
                                        <input placeholder={"Sök på kod eller klartext"}
                                               type="text"
                                               className="form-control"
                                               onKeyDown={e => handleSearchIfEnter(e)}
                                        /></IDSInput>
                                </IDSCol>
                            </IDSRow>
                            <IDSRow align="end">
                                <IDSCol cols="8">
                                </IDSCol>
                                <IDSCol cols="4">
                                    <br/>
                                    <IDSButton
                                        className="btn btn-outline-secondary"
                                        type="button"
                                        onClick={submitSearchForm}>
                                        Sök
                                    </IDSButton>
                                    <br/> <br/>
                                </IDSCol>
                            </IDSRow>
                        </IDSContainer>
                    </IDSCol>
                </IDSRow>
                <IDSRow justify="space-between">
                    <IDSCol cols="12">
                        {isRequesting &&
                            <Spinner animation="border" role="status">
                                {/*<span className="visually-hidden">Söker i SNOMED...</span>*/}
                                <IDSSpinner live="polite" srtext="Söker i SNOMED..."/>
                            </Spinner>
                        }
                    </IDSCol>
                </IDSRow>
                {!isRequesting &&
                    <IDSRow align="end">
                        <IDSCol cols="7">
                            <SearchTotalMessage
                                searchPerformed={isRequesting}
                                total={total}
                                lastSearchPhrase={lastSearchPhrase}
                            />
                        </IDSCol>
                        <IDSCol cols="5"> &nbsp;
                        </IDSCol>
                    </IDSRow>
                }
                <IDSRow>
                    <IDSCol>
                        {(searchResult.length > 0) &&
                            <SnomedSearchResult
                                id={id}
                                version={version}
                                data={searchResult}
                                snomedConceptsToAdd={snomedConceptsToAdd}
                                handleNodeClick={handleSearchFormNodeClick}
                                handleChange={handleChange}
                                tabKey={tabKey}
                                codeSystemIndex={codeSystemIndex}/>
                        }
                        <br/><br/>
                    </IDSCol>
                </IDSRow>
            </IDSContainer>
        </div>
    );
};

export default SnomedSearchForm;