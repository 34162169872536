import React, {useEffect, useRef, useState} from "react";
import {Routes, Route, useParams, useLocation} from "react-router-dom";
import CodeSystemReadSnomed from "./pages/codesystem/CodeSystemReadSnomed";
import CodeSystemReadUpdate from "./pages/codesystem/CodeSystemReadUpdate";
import {Footer} from "./components/common/page/Footer";
import {RemoveFocus} from "./components/common/RemoveFocus";
import "./App.css";

import '@inera/ids-core/styles/themes/inera-admin.css';
import '@inera/ids-core/styles/themes/inera.css';
import '@inera/ids-core/styles/global.css';
import '@inera/ids-core/styles/reset.css';
import '@inera/ids-core/styles/util.css';
import '@inera/ids-core/styles/components/input.css';
import '@inera/ids-core/styles/components/radio.css';
import '@inera/ids-core/styles/components/range.css';
import '@inera/ids-core/styles/components/select.css';
import '@inera/ids-core/styles/components/table.css';
import '@inera/ids-core/styles/components/textarea.css';
import '@inera/ids-core/styles/components/time.css';

import {
    getCurrentUserInBrowserSession,
    getCurrentUser,
    selectSectorCode,
    requestLogout,
} from "./services/APIUtils";
import {
    ACCESS_TOKEN,
    ENV_TYPE,
    REFRESH_TOKEN,
    ROLE_USER,
    USER,
    SYSTEM_ADMIN,
    ROBOTS_CONTENT
} from "./constants";


import TestLogin from "./pages/login/TestLogin";
import OAuth2RedirectHandler from "./user/oauth2/OAuth2RedirectHandler";
import Alert from 'react-s-alert';
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";
import "./App.css";

import {Header} from "./components/common/page/header/Header";
import ArtifactSearch from "./pages/ArtifactSearch";
import ValueSetReadUpdate from "./pages/valueset/ValueSetReadUpdate";
import ConceptMapReadUpdateForm from "./pages/conceptmap/ConceptMapReadUpdate";
import {IDSButton, IDSDialog} from "@inera/ids-react";
import {getSectors} from "./services/SectorUtil";
import {getLogoutIdpUrl} from "./services/IdpUtil";
import {isSnomedPath} from "./codesystem/snomed/util/SnomedUrlUtil";
import SnomedCodeDashBoard from "./codesystem/snomed/panels/SnomedCodeDashBoard";
import Meta from "./pages/Meta";
import {Helmet, HelmetProvider} from 'react-helmet-async';
function App() {

    let renderCount = 0;
    renderCount++;

    const {idFromPath} = useParams();
    const location = useLocation();
    const [authenticated, setAuthenticated] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedSector, setSelectedSector] = useState(undefined);

    const buttonRef = useRef("accept-button");
    const [accepted, setAccepted] = useState(false);

    const fireEvent = (el, eventName) => {
        const event = new Event(eventName, {bubbles: true});
        el.dispatchEvent(event);
    };

    useEffect(() => {

        // add class to body element
        document.body.classList.add('ids');

        // checking if body element contains a class
        if (document.body.classList.contains('ids')) {
            console.log('body tag contains ids class');
        }

        getSectors();
        let currentUser = getCurrentUserInBrowserSession();

        let currentUserHasSelectedCode = false;
        if (currentUser !== undefined && currentUser != null) {
            currentUserHasSelectedCode = (currentUser.selectedSectorCode === undefined || currentUser.selectedSectorCode == null) ? false : true;
        }

        loadCurrentlyLoggedInUser(currentUserHasSelectedCode);

    }, [accepted]);


    const loadCurrentlyLoggedInUser = async (currentUserHasSelectedCode) => {
        setLoading(true);

        await getCurrentUser()
            .then(response => {

                if (response.status == 200) {
                    localStorage.setItem(USER, JSON.stringify(response.data));
                    setSelectedSector(response.data.selectedSectorCode)
                    setCurrentUser(response.data);
                    setAuthenticated(true);
                    setLoading(false);

                    if (!accepted && !loading && !currentUserHasSelectedCode) {

                        setTimeout(() => {
                            if (buttonRef.current instanceof Element) {
                                setAccepted(true);
                                if (selectedSector == undefined) {
                                    fireEvent(buttonRef.current, "click");
                                }
                            }
                        }, 500);
                    }
                } else {
                    setLoading(false);
                    handleLogout();
                }
            })
            .catch(error => {
                console.log("getCurrentUser: No currentUser")
                setLoading(false);
                handleLogout();
            });
    }

    const handleLogout = async (reload) => {

        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(REFRESH_TOKEN);
        localStorage.removeItem(USER);
        setAuthenticated(false);
        setCurrentUser(null);
        setSelectedSector(undefined);

        //  Alert.success("Du har nu loggat ut!");
        if (reload !== undefined) {
            window.location.reload(true);
        }

    }
    const handleLogoutOnClick = async (reload) => {
//    url: "/logouttest",
        let response = await requestLogout({
            url: "/user/logout",
            method: 'POST'
        });

        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(REFRESH_TOKEN);
        localStorage.removeItem(USER);

        setAuthenticated(false);
        setCurrentUser(null);
        setSelectedSector(undefined);

        clearCacheData();
        deleteCookies();

        //  Alert.success("Du har nu loggat ut!");
        const url = getLogoutIdpUrl(response);
        if (reload !== undefined) {
            window.location.href = url;
        }
    }

    const clearCacheData = () => {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });
        console.log("Complete Cache Cleared");
    };


    // function to delete cookies
    function deleteCookies() {
        var Cookies = document.cookie.split(';');

        // set 1 Jan, 1970 expiry for every cookies
        for (var i = 0; i < Cookies.length; i++)
            document.cookie = Cookies[i] + "=;expires=" + new Date(0).toUTCString();
    }


    const clickButtonRef = () => {
        buttonRef.current.click();
    }

    const selectSector = async (roleName, authority) => {

        let userResponse = await selectSectorCode(authority.sector.code, roleName);

        if (userResponse.status == 200) {
            let user = JSON.parse(localStorage.getItem(USER))
            user.selectedSectorCode = authority.sector.code;
            user.selectedRole = roleName;
            localStorage.setItem(USER, JSON.stringify(user));

            //TODO Move this to client request 'then':
            if (authority.sector !== undefined) {
                setSelectedSector(authority.sector);
            } else {
                console.error("authority.sector: IS UNDEFINED")
            }
            window.location.reload(false);
        }
    }


    const chooseSector = (currentUser) => {
        return (
            <div>
                {currentUser?.roles.map((role) => {
                    return <div key={role.roleType}>
                        <h1>{role.roleType}</h1>
                        {role?.authorities.map((authority, index) => {
                            return <span key={index}>{authority.title}</span>
                        })}
                    </div>
                })}
            </div>
        )
    }


    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <meta name='robots' content={ROBOTS_CONTENT} />
                </Helmet>
            {/* <RemoveFocus/>*/}
            <Header authenticated={authenticated}
                    onLogout={handleLogoutOnClick}
                    currentUser={currentUser}
                    selectedSector={selectedSector}
                    clickButtonRef={clickButtonRef}/>

            <div className="container mt-3">
                <Routes>
                    <Route path="/meta" element={<Meta/>}/>
                    <Route path="/oauth2/redirect" element={<OAuth2RedirectHandler/>}/>
                    <Route exact path={"/"} element={<ArtifactSearch/>}/>
                    <Route exact path="/search" element={<ArtifactSearch/>}/>
                    {isSnomedPath(location.pathname) ?
                        //Snomed has its own extra search form, forms are therefore seperated, no nested forms - form in a form
                        <Route path="/codesystem/:id" element={<CodeSystemReadSnomed/>}/>
                        :
                        <Route path="/codesystem/:id" element={<CodeSystemReadUpdate/>}/>
                    }
                    <Route path="/conceptmap/:id" element={<ConceptMapReadUpdateForm/>}/>
                    <Route path="/valueset/:id" element={<ValueSetReadUpdate/>}/>
                    <Route path="/header" element={<Header/>}/>
                    <Route path="/footer" element={<Footer/>}/>
                    {(ENV_TYPE === "dev" || ENV_TYPE === "test") &&
                        <Route path="/test/login" element={<TestLogin/>}/>
                    }
                    {/*       <Route path="/user/logout" element={<Logout/>}/>*/}
                    {/*              <Route component={NotFound}></Route>*/}

                </Routes>
            </div>
            <Alert stack={{limit: 3}}
                   timeout={3000}
                   position='top-right' effect='slide' offset={65}/>
            <div>
                <div>

                    <IDSDialog overlay={true} persistent={true} dismissible="true" headline="Välj sektor">
                        <a href="javascript:void(0)" trigger="true" ref={buttonRef} style={{color: '#FFF'}}></a>
                        <div>
                            {
                                currentUser?.roles?.map((role) => (
                                    role.roleType === ROLE_USER
                                        ? (<div key={role.roleType}>
                                            {role?.authorities.map((authority, index) => {
                                                return <div key={authority.sector.title}><IDSButton secondary
                                                                                                    onClick={() => {
                                                                                                        selectSector(role.roleType, authority);
                                                                                                    }}>
                                                    {authority.sector.title}
                                                </IDSButton>
                                                    <br/> <br/>
                                                </div>
                                            })}
                                        </div>)

                                        :

                                        (<div key={role.roleType}>
                                            {role?.authorities.map((authority, index) => {
                                                return <div key={authority.sector.title}><IDSButton secondary
                                                                                                    onClick={() => {
                                                                                                        selectSector(role.roleType, authority);
                                                                                                    }}>
                                                    {SYSTEM_ADMIN + authority.sector.title}
                                                </IDSButton>
                                                    <br/> <br/>
                                                </div>
                                            })}
                                        </div>)
                                ))
                            }
                        </div>
                    </IDSDialog>
                </div>
            </div>
            <Footer/>
                </HelmetProvider>
        </div>
    );
}

export default App;
