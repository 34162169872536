import React, {FC, useEffect, useState} from 'react';

import {
    IDSBadge,
    IDSCol,
    IDSContainer,
    IDSRadioGroup,
    IDSRadio,
    IDSRow, IDSSpinner
} from "@inera/ids-react";
import Spinner from "react-bootstrap/Spinner";
import IConceptSearchResultItem from "../search/model/IConceptSearchResultItem";
import ConceptSnomedHierarchy from "../../../components/artifact/concept/table/ConceptSnomedHierarchy";
import {
    SNOMED_CT_URL,
} from "../../../model/defaultvalues/Constant";
import {useForm, useWatch} from "react-hook-form"

import {
    padding,
    rcorners2,
    snomed_display_rightAligned_table_scroll
} from "../../../styles/TTStyles";
import {snomedRightColumnMenu} from "../../../constants/PanelConstant";
import RHookFormCheckBox from "../../../components/common/rhookform/RHookFormCheckBox";

let renderCount = 0;


interface SnomedRightColumnProps {
    id: string,
    version: string,
    childConceptCollection: IConceptSearchResultItem[],
    selectedConceptToShow: IConceptSearchResultItem | undefined,
    isCreateValueSetMode: boolean,
    setChildConceptCollection: any,
    snomedConceptsToAdd?: any[],
    handleNodeClick: any,
    handleChange?: any,
    tabKey?: number,
    codeSystemIndex?: number,
    isRequestingChildren: boolean,
    setIsRequstingChildren: any,
    changeFromMenu?: string,
    onChangeCode?: string,
    isChangeCodeSameAsSelected: boolean
}


const SnomedCodeDetailsColumn: FC<SnomedRightColumnProps> = ({
                                                                 id,
                                                                 version,
                                                                 childConceptCollection,
                                                                 selectedConceptToShow,
                                                                 isCreateValueSetMode,
                                                                 setChildConceptCollection,
                                                                 snomedConceptsToAdd,
                                                                 handleNodeClick,
                                                                 handleChange,
                                                                 tabKey,
                                                                 codeSystemIndex,
                                                                 isRequestingChildren,
                                                                 setIsRequstingChildren,
                                                                 changeFromMenu,
                                                                 onChangeCode,
                                                                 isChangeCodeSameAsSelected
                                                             }) => {


        const nbrOfChildNodes = childConceptCollection !== undefined ? childConceptCollection.length : 0;
        const nbrOfParentNodes = selectedConceptToShow?.parentCodes !== undefined ? selectedConceptToShow?.parentCodes.length : 0;
        const [latestSelectStatus, setLatestSelectStatus] = useState<boolean>(false);
        const [selectionDisabled, setSelectionDisabled] = useState<boolean>(false);
        const formId: string = "formId";
        const synonymRadioName: string = "synonymRadioName";
        const synonymRadioRecommendedId: string = "synonymRadioName";
        const thisMenuName = snomedRightColumnMenu;
        const [preSelectedSynonym, setPreSelectedSynonym] = useState();

        const getDefaultSelected = (): boolean => {
            if (selectedConceptToShow === undefined) {
                return false;
            } else if (isCreateValueSetMode && snomedConceptsToAdd !== undefined && snomedConceptsToAdd.length > 0) {
                const targetObj = snomedConceptsToAdd.find(obj => obj.code === selectedConceptToShow?.code);
                const result = targetObj ? true : false;
                return result;
            } else {
                return false;
            }
        }

        const getDefaultDisplay = () => {
            if (selectedConceptToShow === undefined) {
                return undefined;
            } else if (isCreateValueSetMode && snomedConceptsToAdd !== undefined && snomedConceptsToAdd.length > 0) {
                const targetObj = snomedConceptsToAdd.find(obj => obj.code === selectedConceptToShow.code);
                if (targetObj) {
                    return targetObj.display;
                } else {
                    return selectedConceptToShow.display;
                }
            } else {
                const result = selectedConceptToShow.display;
                return result;
            }
        }

        const getDisplayToUse = () => {
            const selectedDisplayFromForm = getValues(selectedDisplay);
            if (selectedDisplayFromForm === undefined) {
                return selectedConceptToShow?.display;
            } else {
                return selectedDisplayFromForm;
            }
        }

        const getPreSelectedSynonym = () => {
            let result: undefined | string = undefined;
            if (selectedConceptToShow?.synonyms?.synonymsSwedish?.acceptableList !== undefined &&
                selectedConceptToShow?.synonyms?.synonymsSwedish?.acceptableList.length > 0 &&
                snomedConceptsToAdd !== undefined && snomedConceptsToAdd.length > 0) {
                const targetObj = snomedConceptsToAdd.find(obj => obj.code === selectedConceptToShow?.code);
                if (targetObj) {
                    setValue(selectedCode, true);
                    setLatestSelectStatus(true);
                    if (selectedConceptToShow !== undefined &&
                        (selectedConceptToShow.display !== targetObj.display)) {

                        if (selectedConceptToShow.synonyms !== undefined &&
                            selectedConceptToShow.synonyms.synonymsSwedish !== undefined &&
                            selectedConceptToShow.synonyms.synonymsSwedish.acceptableList !== undefined &&
                            !result) {
                            for (const sweSynonym of selectedConceptToShow.synonyms.synonymsSwedish.acceptableList) {
                                if (sweSynonym.value === targetObj.display) {
                                    result = sweSynonym.value;
                                    break;
                                }
                            }
                        }
                    }
                }
            }
            return result;
        };

        const form = useForm({
            defaultValues: {
                selectedCode: getDefaultSelected(),
                selectedDisplay: getDefaultDisplay()
            },
            mode: "onChange"
        });

        const selectedCode = "selectedCode";
        const selectedDisplay = "selectedDisplay";

        const {
            register,
            reset,
            getValues,
            setValue,
            handleSubmit,
            control
        } = form;

        const selectedCodeWatch = useWatch({
            control,
            name: selectedCode,
            defaultValue: getDefaultSelected()
        });

        const selectedDisplayWatch = useWatch({
            control,
            name: selectedDisplay,
            defaultValue: getDefaultDisplay()
        });


        useEffect(() => {
            if (isCreateValueSetMode && (latestSelectStatus != selectedCodeWatch)) {
                let event = {target: {checked: selectedCodeWatch}}
                let clone = {code: selectedConceptToShow?.code, display: getDisplayToUse()}
                setLatestSelectStatus(selectedCodeWatch);
                handleChange(event, clone, SNOMED_CT_URL, version, 0, codeSystemIndex, id, thisMenuName);
            }
        }, [selectedCodeWatch]);


        useEffect(() => {
            if (isCreateValueSetMode && selectedConceptToShow !== undefined && selectedDisplayWatch !== undefined) {
                let event = {target: {checked: selectedCodeWatch}}
                let clone = {code: selectedConceptToShow?.code, display: getDisplayToUse()}
                handleChange(event, clone, SNOMED_CT_URL, version, 0, codeSystemIndex, id, thisMenuName);

            }
        }, [selectedDisplayWatch]);


        const snomedConceptsToAddString = JSON.stringify(snomedConceptsToAdd);


        useEffect(() => {
            if (thisMenuName !== changeFromMenu) {
                if (selectedConceptToShow !== undefined && snomedConceptsToAdd === undefined && latestSelectStatus) {
                    setLatestSelectStatus(false);
                    setValue(selectedCode, false);
                } else if (selectedConceptToShow !== undefined && snomedConceptsToAdd !== undefined) {
                    const foundConcept = snomedConceptsToAdd.find(conceptToAdd => conceptToAdd.code === selectedConceptToShow.code)

                    if (foundConcept !== undefined && getValues(selectedCode) == false) {
                        setLatestSelectStatus(true);
                        setValue(selectedCode, true)
                    } else if (foundConcept === undefined && getValues(selectedCode) == true) {
                        setLatestSelectStatus(false);
                        setValue(selectedCode, false)
                    }
                }
            }

        }, [snomedConceptsToAddString]);

        const getSelectionDisabled = () => {
            let selectionDisabled = false;
            if (selectedConceptToShow !== undefined && selectedConceptToShow.inactive !== undefined) {
                selectionDisabled = selectedConceptToShow.inactive;
            }
            return selectionDisabled;
        };


        useEffect(() => {

            reset();
            let element = (document.getElementById(formId) as HTMLInputElement);
            if (element != null) {
                // @ts-ignore
                element.reset()
            }

            var synonymRecommendedArray = document.getElementsByName(synonymRadioName);

            for (var i = 0; i < synonymRecommendedArray.length; i++) {
                let input = synonymRecommendedArray[i];
                // @ts-ignore
                let value = input.checked;


                // @ts-ignore
                input.checked = false;

            }

            var recommendedRadio = document.getElementById(synonymRadioRecommendedId);

            if (recommendedRadio != null) {
                // @ts-ignore
                let value = recommendedRadio.checked;
                // @ts-ignore
                recommendedRadio.checked = true;
            }

            /*       var synonymAcceptableArray = document.getElementsByName(synonymAcceptable);
                    for (var i = 0; i < synonymAcceptableArray.length; i++) {
                        let input = synonymAcceptableArray[i];
                        // @ts-ignore
                        let value = input.checked;
                        // @ts-ignore
                        input.checked = false;
                    }*/

            //if in "snomedConceptsToAdd" array then mark as selected
            if (snomedConceptsToAdd !== undefined && snomedConceptsToAdd.length > 0) {
                const targetObj = snomedConceptsToAdd.find(obj => obj.code === selectedConceptToShow?.code);
                if (targetObj) {
                    setValue(selectedCode, true);
                    setLatestSelectStatus(true);

                    const preSelectSynonym = getPreSelectedSynonym();
                    // @ts-ignore
                    setPreSelectedSynonym(preSelectSynonym);
                }
            }

            setSelectionDisabled(getSelectionDisabled())

        }, [selectedConceptToShow]);

        const hasSwedishSynonyms = (): boolean => {
            return selectedConceptToShow?.synonyms?.synonymsSwedish !== undefined;
        };

        const hasEnglishSynonyms = (): boolean => {
            return selectedConceptToShow?.synonyms?.synonymsEnglish !== undefined;
        };

        const hasSwedishRecommended = (): boolean => {
            let has = false;
            if (selectedConceptToShow !== undefined &&
                selectedConceptToShow.synonyms !== undefined &&
                selectedConceptToShow.synonyms?.synonymsSwedish !== undefined &&
                selectedConceptToShow.synonyms?.synonymsSwedish.recommended !== undefined) {
                has = true;
            }
            return has;
        };

        const hasSwedishAcceptable = (): boolean => {
            let has = false;
            if (selectedConceptToShow !== undefined &&
                selectedConceptToShow.synonyms !== undefined &&
                selectedConceptToShow.synonyms?.synonymsSwedish !== undefined &&
                selectedConceptToShow.synonyms?.synonymsSwedish.acceptableList !== undefined) {
                has = true;
            }
            return has;
        };

        const hasEnglishAcceptableSynonyms = (): boolean => {
            let has = false;
            if (selectedConceptToShow !== undefined &&
                selectedConceptToShow.synonyms !== undefined &&
                selectedConceptToShow.synonyms?.synonymsEnglish !== undefined &&
                selectedConceptToShow.synonyms?.synonymsEnglish.acceptableList !== undefined) {
                has = true;
            }
            return has;
        };


        const hasRecommendedUS = (): boolean => {
            let has = false;
            if (selectedConceptToShow !== undefined &&
                selectedConceptToShow.synonyms !== undefined &&
                selectedConceptToShow.synonyms?.synonymsEnglish !== undefined &&
                selectedConceptToShow.synonyms?.synonymsEnglish.recommendedUS !== undefined) {
                has = true;
            }
            return has;
        };

        const hasRecommendedUK = (): boolean => {
            let has = false;
            if (selectedConceptToShow !== undefined &&
                selectedConceptToShow.synonyms !== undefined &&
                selectedConceptToShow.synonyms?.synonymsEnglish !== undefined &&
                selectedConceptToShow.synonyms?.synonymsEnglish.recommendedUK !== undefined) {
                has = true;
            }
            return has;
        };

        const getRecommendedUSText = () => {
            return (
                <>
                    <i>en &nbsp;</i><strong>{selectedConceptToShow?.synonyms?.synonymsEnglish?.recommendedUS?.value}</strong> &nbsp;(rekommenderad)
                </>
            );
        };

        const getRecommendedUKText = () => {
            return (
                <>
                    <i>en
                        (UK) &nbsp;</i><strong>{selectedConceptToShow?.synonyms?.synonymsEnglish?.recommendedUK?.value}</strong> &nbsp;(rekommenderad)
                </>
            );
        };

        const getRecommendedSwedishText = () => {
            return (
                <>
                    <i>sv &nbsp;</i>
                    <strong>{selectedConceptToShow?.synonyms?.synonymsSwedish?.recommended?.value}
                    </strong> &nbsp;(rekommenderad)
                </>
            );
        };


        const getEnglishAcceptedSynonymText = (item: any) => {
            return (
                <>
                    <i>en &nbsp;</i><strong>{item.value}</strong> &nbsp;(accepterad)
                </>
            );
        };

        const getSwedishAcceptedSynonymText = (item: any) => {
            return (
                <>
                    <i>sv &nbsp;</i><strong>{item.value}</strong> &nbsp;(accepterad)
                </>
            );
        };

        const getActiveStatusText = (): any => {

            const active = (<div>
                Aktiv: <IDSBadge className="ids-mr-2" type="success">ja</IDSBadge>
            </div>);

            const inactive = (<div>
                Aktiv: <IDSBadge className="ids-mr-2" type="error">Nej</IDSBadge>
            </div>);

            let sweStatusText: any = undefined;
            if (selectedConceptToShow !== undefined && selectedConceptToShow.inactive !== undefined) {
                sweStatusText = selectedConceptToShow.inactive ?
                    inactive
                    :
                    active
            } else if (selectedConceptToShow !== undefined) {
                sweStatusText = active
            }
            return sweStatusText;
        };

        const getNbrOfChildNodes = () => {
            if (nbrOfChildNodes == 0 && isRequestingChildren) {
                return (
                    <></>
                );
            } else if (nbrOfChildNodes == 0) {
                return (
                    <>(0)</>
                );
            } else {
                return (
                    <>({nbrOfChildNodes})</>
                );
            }
        };

        const printParentNodes = () => {
            if (selectedConceptToShow?.parentCodes !== undefined && selectedConceptToShow.parentCodes.length != 0) {
                return (
                    <ul>
                        {selectedConceptToShow.parentCodes.map(concept => (
                            <li>{concept.code + ": , " + concept.display}</li>
                        ))}
                    </ul>
                );
            } else {
                return (
                    <div></div>
                );
            }
        }


        renderCount++;

        return (
            <div>

                {selectedConceptToShow !== undefined &&
                    <div>
                        {/*         <IDSContainer gutterless={true}>
                        <IDSRow justify="space-between">
                            <IDSCol cols="12">
                                {(selectedConceptToShow !== undefined) &&
                                    <div>
                                        <br/> <br/>
                                        <h6 className="ids-heading-6">Överordnade begrepp ({nbrOfParentNodes})</h6>
                                    </div>
                                }
                            </IDSCol>
                        </IDSRow>
                        <IDSRow justify="space-between">
                            <IDSCol>
                                {printParentNodes()}
                            </IDSCol>
                        </IDSRow>
                        <IDSRow justify="space-between">
                            <IDSCol>
                                <br/>
                            </IDSCol>
                        </IDSRow>
                    </IDSContainer>
*/}
                        {/*   <IDSCard>*/}
                        <div style={rcorners2}>
                            <div style={padding}>
                                <IDSContainer gutterless={true}>
                                    <IDSRow justify="space-between">
                                        <IDSCol>
                                            {/*        <div style={{paddingLeft: '20px'}}>*/}

                                            {/*        <IDSCard >*/}
                                            <IDSContainer gutterless={true}>
                                                <IDSRow justify="space-between">
                                                    <IDSCol><h4 className="ids-heading-4">Begrepp</h4></IDSCol>
                                                </IDSRow>
                                                <IDSRow justify="space-between">
                                                    <IDSCol><br/>
                                                        {preSelectedSynonym}
                                                    </IDSCol>
                                                </IDSRow>
                                            </IDSContainer>

                                            <form id={formId}>
                                                <IDSContainer gutterless={true}>
                                                    <IDSRow justify="space-between">
                                                        <IDSCol>
                                                            <strong>{selectedConceptToShow.display}</strong>
                                                            {/*     }*/}
                                                        </IDSCol>
                                                    </IDSRow>
                                                    <IDSRow justify="space-between">
                                                        <IDSCol>
                                                            {isCreateValueSetMode ?
                                                                <div>
                                                                    <RHookFormCheckBox
                                                                        name={selectedCode}
                                                                        disabled={selectionDisabled}
                                                                        control={control}/>
                                                                    <label>SCTID <strong>{selectedConceptToShow.code}</strong></label>
                                                                </div>
                                                                :
                                                                <div>SCTID <strong>{selectedConceptToShow.code}</strong>
                                                                </div>
                                                            }
                                                        </IDSCol>
                                                    </IDSRow>
                                                    <IDSRow justify="space-between">
                                                        <IDSCol><br/>
                                                        </IDSCol>
                                                    </IDSRow>
                                                    {hasSwedishSynonyms() &&
                                                        <IDSRow justify="space-between">
                                                            <IDSCol>
                                                                {/*          <IDSRadioGroup name="sweSynonyms" required={true}
                                                                   legend="Svenska synonymer">*/}
                                                                <IDSRadioGroup name="artifactTypegfgdf" required={true}>
                                                                    <IDSContainer gutterless={true}>
                                                                        <IDSRow>
                                                                            <IDSCol>
                                                                                {(hasSwedishRecommended() &&
                                                                                    hasSwedishAcceptable() &&
                                                                                    isCreateValueSetMode && selectedCodeWatch &&
                                                                                    preSelectedSynonym === undefined) ?
                                                                                    (
                                                                                        <IDSRadio>
                                                                                            <input type="radio"
                                                                                                   value={selectedConceptToShow.display}
                                                                                                   {...register(selectedDisplay)}
                                                                                                   defaultChecked
                                                                                                   disabled={selectionDisabled}
                                                                                                /*          onClick={onChangeSelectedSynonym}
                                                                                                          onChange={onChangeSelectedSynonym}*/
                                                                                            >

                                                                                            </input>
                                                                                            <label>{getRecommendedSwedishText()}</label>
                                                                                        </IDSRadio>
                                                                                    ) : (hasSwedishRecommended() &&
                                                                                        hasSwedishAcceptable() &&
                                                                                        isCreateValueSetMode && selectedCodeWatch &&
                                                                                        preSelectedSynonym !== undefined) ?
                                                                                        (
                                                                                            <IDSRadio>
                                                                                                <input type="radio"
                                                                                                       value={selectedConceptToShow.display}
                                                                                                       {...register(selectedDisplay)}
                                                                                                       disabled={selectionDisabled}
                                                                                                    /*          onClick={onChangeSelectedSynonym}
                                                                                                              onChange={onChangeSelectedSynonym}*/
                                                                                                >
                                                                                                </input>
                                                                                                <label>{getRecommendedSwedishText()}</label>
                                                                                            </IDSRadio>
                                                                                        )
                                                                                        : (hasSwedishRecommended()) ?
                                                                                            <div>{getRecommendedSwedishText()}<br/>
                                                                                            </div>
                                                                                            : (<div></div>)
                                                                                }
                                                                            </IDSCol>
                                                                        </IDSRow>
                                                                        <IDSRow>
                                                                            <IDSCol>
                                                                                {(hasSwedishAcceptable() && hasSwedishRecommended()) &&
                                                                                    // @ts-ignore
                                                                                    selectedConceptToShow?.synonyms?.synonymsSwedish?.acceptableList.map((item) => (
                                                                                        (isCreateValueSetMode && selectedCodeWatch && preSelectedSynonym !== item.value) ?
                                                                                            (
                                                                                                <IDSRadio>
                                                                                                    <input type="radio"
                                                                                                           value={item.value}
                                                                                                           {...register(selectedDisplay)}
                                                                                                           disabled={selectionDisabled}
                                                                                                    ></input>
                                                                                                    <label>{getSwedishAcceptedSynonymText(item)}</label>
                                                                                                </IDSRadio>
                                                                                            )
                                                                                            : (isCreateValueSetMode && selectedCodeWatch && preSelectedSynonym === item.value) ?
                                                                                                (
                                                                                                    <IDSRadio>
                                                                                                        <input type="radio"
                                                                                                               value={item.value}
                                                                                                               {...register(selectedDisplay)}
                                                                                                               defaultChecked
                                                                                                               disabled={selectionDisabled}
                                                                                                        ></input>
                                                                                                        <label>{getSwedishAcceptedSynonymText(item)}</label>
                                                                                                    </IDSRadio>
                                                                                                )
                                                                                                :
                                                                                                <div>{getSwedishAcceptedSynonymText(item)}<br/>
                                                                                                </div>
                                                                                    ))
                                                                                }
                                                                            </IDSCol>
                                                                        </IDSRow>
                                                                    </IDSContainer>
                                                                </IDSRadioGroup>
                                                            </IDSCol>
                                                        </IDSRow>
                                                    }
                                                    {hasSwedishSynonyms() &&
                                                        <IDSRow justify="space-between">
                                                            <IDSCol><br/>
                                                            </IDSCol>
                                                        </IDSRow>
                                                    }
                                                    {hasEnglishSynonyms() &&
                                                        <IDSRow justify="space-between">
                                                            <IDSCol>
                                                                <div>
                                                                    <IDSRadioGroup legend="Engelska synonymer"
                                                                                   name="enSynonyms"
                                                                                   required={true}>

                                                                        {(hasRecommendedUS() && (!hasSwedishRecommended && isCreateValueSetMode)) ?
                                                                            (<IDSRadio>
                                                                                <input type="radio"
                                                                                       value={"engRecommended"}
                                                                                       defaultChecked
                                                                                       disabled={selectionDisabled}></input>
                                                                                <label>{getRecommendedUSText()}</label>
                                                                            </IDSRadio>)
                                                                            : (hasRecommendedUS()) ?
                                                                                <div>{getRecommendedUSText()}<br/></div>
                                                                                : (<div></div>)
                                                                        }
                                                                        {hasEnglishAcceptableSynonyms() &&
                                                                            // @ts-ignore
                                                                            selectedConceptToShow?.synonyms?.synonymsEnglish?.acceptableList.map((item) => (
                                                                                (!hasSwedishRecommended() && isCreateValueSetMode) ?
                                                                                    (<IDSRadio>
                                                                                        <input type="radio"
                                                                                               value={"engAccepted-" + item.value}
                                                                                               disabled={selectionDisabled}></input>
                                                                                        <label>{getEnglishAcceptedSynonymText(item)}</label>
                                                                                    </IDSRadio>)
                                                                                    :
                                                                                    <div>{getEnglishAcceptedSynonymText(item)}<br/>
                                                                                    </div>
                                                                            ))
                                                                        }
                                                                    </IDSRadioGroup>
                                                                    <br/>
                                                                    {getActiveStatusText()}
                                                                </div>
                                                            </IDSCol>
                                                        </IDSRow>
                                                    }
                                                </IDSContainer>
                                            </form>
                                            {/*      </IDSCard>*/}
                                            {/*   </div>*/}

                                        </IDSCol>
                                    </IDSRow>
                                    <IDSRow>
                                        <IDSCol>
                                            <br/>
                                        </IDSCol>
                                    </IDSRow>
                                </IDSContainer>
                            </div>
                            <IDSContainer gutterless={true}
                                /*style={{backgroundColor: '#f6f1e9'}}*/
                            >
                                {/*                        <IDSRow>
                            <IDSCol>
                                <br/>
                            </IDSCol>
                        </IDSRow>*/}
                                <IDSRow justify="space-between">
                                    <IDSCol cols="12">
                                        {(childConceptCollection !== undefined) &&
                                            <div>
                                                <h6 className="ids-heading-6">
                                                    &nbsp;Underordnade begrepp {getNbrOfChildNodes()}</h6>
                                            </div>
                                        }
                                    </IDSCol>
                                </IDSRow>
                                {(isRequestingChildren && nbrOfChildNodes == 0) &&
                                    <IDSRow justify="space-between">
                                        <IDSCol cols="12">
                                            <Spinner animation="border" role="status">
                                                {/*<span className="visually-hidden">Söker i SNOMED...</span>*/}
                                                <IDSSpinner live="polite" srtext="Hämtar underordnade..."/>
                                            </Spinner>

                                        </IDSCol>
                                    </IDSRow>
                                }
                                <IDSRow justify="space-between">
                                    <IDSCol>
                                        {(nbrOfChildNodes != 0) &&
                                            <div style={snomed_display_rightAligned_table_scroll} id="scrollbar-style">
                                                <ConceptSnomedHierarchy
                                                    id={id}
                                                    version={version}
                                                    menuName={thisMenuName}
                                                    loadingRows={isRequestingChildren}
                                                    setLoadingRows={setIsRequstingChildren}
                                                    conceptCollection={childConceptCollection}
                                                    setConceptCollection={setChildConceptCollection}
                                                    snomedConceptsToAdd={snomedConceptsToAdd}
                                                    handleTaxonomyNodeClick={handleNodeClick}
                                                    handleChange={handleChange}
                                                    tabKey={tabKey}
                                                    codeSystemIndex={codeSystemIndex}
                                                    changeFromMenu={changeFromMenu}
                                                    isChangeCodeSameAsSelected={isChangeCodeSameAsSelected}
                                                />
                                            </div>
                                        }
                                    </IDSCol>
                                </IDSRow>
                            </IDSContainer>
                        </div>
                        {/*  </IDSCard>*/}
                    </div>
                }
            </div>
        )
            ;
    }
;

export default SnomedCodeDetailsColumn;
