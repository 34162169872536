import ITabName from "../page/ITabName";
import {getErrorCode} from "../rhookform/RHookFormMessageUtil";
import {collectValidationMessage} from "./MetaValidationRules";
import {
    validateDescriptionForPublish,
    validatePublisherForPublish,
    validateVersionForPublish
} from "../../artifact/versioninfo/draft/validate/FormFieldPublishValidator";
import {identifierList} from "../../../model/defaultvalues/MetaDataDefaultFormValue";
import {noIdentifier, validationInternalMetaDataWarning} from "../../artifact/meta/MetaWarnInfoConstant";
import {metaDataTabName} from "../page/TabName";
import {
    validateVersionDescription
} from "../../artifact/versioninfo/draft/validate/ExtensionValueStringPublishValidator";
import {validateAuthor} from "../../artifact/versioninfo/draft/validate/ExtensionContactDetailPublishValidator";
import {extensionList} from "../../../model/defaultvalues/MetaDataExtensionDefaultFormValue";

const createLabelStartsWith = (name: string) => {
    return name + validationInternalMetaDataWarning;
};


export const createValidationWarningLevelAfterSaveName = (validationName: string) => {
    return validationInternalMetaDataWarning + validationName
};
export const hasValidationInternalMetaDataWarning = (name: string, errorCode: string) => {
    if (errorCode !== undefined) {
        return errorCode.startsWith(validationInternalMetaDataWarning) ? true : false;
    } else {
        return false;
    }
};
export const removeValidationInternalMetaDataWarningFromValidationCollectionList = (name: string, handleMessageList: any, methods: any, tabName: ITabName, rowindex?: string) => {

    const errorCode = getErrorCode(name, methods)

    let hasValidation: boolean = hasValidationInternalMetaDataWarning(name, errorCode);
    // @ts-ignore
    if (errorCode === undefined || hasValidation == false) {

        const labelStartsWith = createLabelStartsWith(name);
        collectValidationMessage("", labelStartsWith, false, handleMessageList, tabName, rowindex);
    }
}


export const perFormValidationInternalMetaDataWarnings_hasWarnings = (methods: any, handleWarnMessageList: any) => {

    const status = methods.getValues("status");

    //Check hasEmptyVersion, hasEmptyDescription
    let hasEmptyVersion : boolean = validateVersionForPublish(methods, handleWarnMessageList);
    let hasEmptyDescription : boolean = validateDescriptionForPublish(methods, handleWarnMessageList);


    //Check identifier

    let hasNoIdentifier = false;
    const identifierArrayName = "identifier";
    const identifierArray = methods.getValues(identifierArrayName);
    const fieldName = identifierArrayName + ".0.system"
    const messageNoIdentifier = "Ange minst en unik identifierare för att kunna aktivera";
    if (identifierArray === undefined || Array.isArray(identifierArray) == false || identifierArray.length == 0 ||
        (JSON.stringify(identifierArray) === JSON.stringify(identifierList)) ||
        (identifierArray.length == 1 && identifierArray[0] === undefined)) {
        hasNoIdentifier = true;
        methods.setError(fieldName, {
            type: "WARNING",
            // @ts-ignore
            code: noIdentifier,
            message: messageNoIdentifier,
        });
        collectValidationMessage(messageNoIdentifier, fieldName + noIdentifier, true, handleWarnMessageList, metaDataTabName, "0");
    } else {
        for (let i = 0; i < identifierArray.length; i++) {
            let obj = identifierArray[i];
            if ((obj.system === undefined || obj.system === "") || (obj.system !== "OID" && obj.system !== "UUID") &&
                (obj.value !== undefined || obj.value.trim() !== "")) {
                methods.trigger(identifierArrayName + "." + i + ".system");
                hasNoIdentifier = true;
            } else if ((obj.value === undefined || obj.value === "") && (obj.system === "OID" || obj.system === "UUID")) {
                methods.trigger(identifierArrayName + "." + i + ".value");
                hasNoIdentifier = true;
            }
        }
        collectValidationMessage(messageNoIdentifier, fieldName + noIdentifier, false, handleWarnMessageList, metaDataTabName, "0");
    }

    //Check extensions
    let extensionArray = methods.getValues("extension");
    if (extensionArray === undefined || Array.isArray(extensionArray) == false) {
        extensionArray = extensionList;
    }

    var hasEmptyVersionDescription = validateVersionDescription(extensionArray, methods, handleWarnMessageList);
    let hasEmptyAuthorName = validateAuthor(extensionArray, methods, handleWarnMessageList);
    let hasEmptyPublisher = validatePublisherForPublish(methods, handleWarnMessageList);

    //Check concept.display:s
    const hasDisplayRequiredWarning = false;
    //displayHasRequiredWarningAfterSubmitting(handleWarnMessageList, methods);

    //Dependencies has no empty values
    if (hasEmptyVersion == false && hasEmptyDescription == false && hasEmptyVersionDescription == false &&
        hasEmptyPublisher == false && hasEmptyAuthorName == false && hasNoIdentifier == false &&
        hasDisplayRequiredWarning == false) {
        return false;
    } else {
        return true;
    }
}