import React, {FC, useEffect, useMemo, useRef, useState} from 'react';
import {
    MaterialReactTable,
    MRT_ColumnDef, MRT_RowSelectionState,
    MRT_TableInstance,
} from 'material-react-table';
import {MRT_Localization_SV} from 'material-react-table/locales/sv';
import {
    muiTableBodyPropsStyle,
    muiTableHeadRowPropsStyle,
    muiTablePropsFiveColumnStyle,
} from "../../../styles/TTStyles";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ImportExport from '@mui/icons-material/ImportExport';
import '@inera/ids-core/components/link/register';
import '@inera/ids-core/components/icon/register';
import {ICodeSystemConcept,} from "fhir-typescript-models/dist/src/models/fhir/internal";
import {valueSetCreateSummmaryMenu} from "../../../constants/PanelConstant";
import {activeColumn, codeColumn, displayColumn} from "../concept/table/ConceptColumns";
import {isSnomedSystem} from "../../../codesystem/snomed/util/SnomedUrlUtil";

let renderCount = 0;

interface VSIncludeCodeSummaryTableProps {
    index?: number,
    conceptsToAdd?: any[],
    removeConceptToValueSet: any
}


const VSIncludeCodeSummaryTable: FC<VSIncludeCodeSummaryTableProps> = ({
                                                                           index,
                                                                           conceptsToAdd,
                                                                           removeConceptToValueSet
                                                                       }) => {


    renderCount++;

    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
    const [summeryTableContent, setSummeryTableContent] = useState<any[]>([]);
    const thisMenuName = valueSetCreateSummmaryMenu;

    useEffect(() => {
        if (conceptsToAdd !== undefined && index !== undefined) {

            let conceptArrayWithSystemSpecified: any[] = [];

            let include = conceptsToAdd[index];
            if (include.concept !== undefined) {
                conceptArrayWithSystemSpecified.push(...include.concept);
            }

            setSummeryTableContent(conceptArrayWithSystemSpecified);
            //TODO refactor rowselection in previous for loop
            for (let i = 0; i < conceptArrayWithSystemSpecified.length; i++) {
                rowSelection[i] = true;
            }
            setRowSelection({...rowSelection});
        }

    }, [conceptsToAdd])


    const columnNames = {
        code: 'Kod',
        display: 'Klartext',
        inactive: "Status"
    } as const;

    interface ICodeSystemConceptColumn {
        code?: string;
        display?: string;
        inactive?: boolean;
    }
    const columnDefinitionsSnomed: MRT_ColumnDef<ICodeSystemConcept>[] = [
        codeColumn,
        displayColumn,
        activeColumn('Status')
    ];

    const columnDefinitions: MRT_ColumnDef<ICodeSystemConcept>[] = [
        codeColumn,
        displayColumn
    ];

    const getColumnDefinitions = () => {
        if (conceptsToAdd !== undefined && index !== undefined && isSnomedSystem(conceptsToAdd[index].system)) {
            return columnDefinitionsSnomed;
        }else {
            return columnDefinitions;
        }
    }

/*    const isSnomed = */

    //create columns from data
    const columns = useMemo<MRT_ColumnDef<ICodeSystemConcept>[]>(
        //column definitions...
        () => getColumnDefinitions(),
        [],
        //end
    );


    const tableInstanceRef = useRef<MRT_TableInstance<ICodeSystemConcept>>(null);

    return (
        <div>
            <div>
                <MaterialReactTable
                    {...columns}
                    autoResetPageIndex={false}
                    columns={columns}
                    data={(summeryTableContent.length === 0) ? [] : summeryTableContent}
                    enableRowOrdering={false}
                    enableColumnFilters={true}
                    enableSorting={true}
                    localization={MRT_Localization_SV}
                    enableTopToolbar={false}
                    enableSelectAll={false}
                    enableRowSelection
                    onRowSelectionChange={setRowSelection}
                    state={{rowSelection}}
                    enableEditing={true}
                    enableHiding={false}
                    editDisplayMode="table"
                    positionActionsColumn="last"
                    enableExpanding={false}
                    enableExpandAll={false}
                    initialState={{pagination: {pageSize: 50, pageIndex: 0}}}

                    muiSelectCheckboxProps={({row, table}) => ({
                        defaultChecked: true,
                        onChange: (event) => {
                            // @ts-ignore
                            removeConceptToValueSet(row.original, conceptsToAdd[index].system, conceptsToAdd[index].version, thisMenuName);
                        },
                        // color: 'secondary',
                    })}

                    /*                    muiSelectAllCheckboxProps={({table}) => ({
                                            onClick: (event) => {
                                                setConceptsToAdd([]);
                                           },
                                            // color: 'secondary',
                                        })}*/


                    muiTableHeadRowProps={{
                        sx: muiTableHeadRowPropsStyle
                    }}

                    muiTableProps={{
                        sx: muiTablePropsFiveColumnStyle
                    }}

                    muiTableBodyProps={{
                        sx: muiTableBodyPropsStyle
                    }}


                    muiTablePaperProps={{
                        elevation: 0, //change the mui box shadow
                    }}

                    icons={{
                        MoreVertIcon: () => <ImportExport/>,
                        MoreHorizIcon: () => <MoreVertIcon/>,
                    }}

                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            header: '',
                            size: 10,
                        },
                        'mrt-row-drag': {
                            header: '',
                            size: 10,
                        },
                    }}

                    getSubRows={(originalRow) => {
                        return (
                            originalRow.concept
                        )
                    }}

                    muiColumnActionsButtonProps={{
                        children: <ExpandMore/>
                    }}

                />
            </div>
        </div>
    );
};

export default VSIncludeCodeSummaryTable;
