// @ts-nocheck
import {object, array, string, addMethod, number, mixed} from "yup";
import {
    extensionVersionDescriptionCodeSystemUrl,
    extensionRetireReasonSystemUrl} from "../../../model/defaultvalues/Constant";
import {hasValidSystemNameCharacters} from "../CommonValidationFunctions";
import {replaceLast} from "../CommonFunctions";
import {formOID, formUUID} from "../../../model/defaultvalues/MetaDataDefaultFormValue";


export const YUPErrorLevelValidation = "yupErrorLevelValidation";

function hasValidSystemName(message: string) {
    // @ts-ignore
    return this.test({
        name: YUPErrorLevelValidation + '-valid-charachers-systemname',
        // @ts-ignore
        test: (value, {createError}) => {
            const {path} = this;
            if (!hasValidSystemNameCharacters(value)) {
                return createError({path: path, message: message});
            }
            return true;
        }
    });
}

function hasOnlyBlankSpaces(message: string) {
    // @ts-ignore
    return this.test({
        name: YUPErrorLevelValidation + '-only-blankspaces',
        // @ts-ignore
        test: (value, {createError}) => {
            const {path} = this;
            if (value !== undefined && (value.length > 0) && (value.trim() === "")) {
                return createError({path: path, message: message});
            }
            return true;
        }
    });
}

function startsWithBlankSpace(message: string) {
    // @ts-ignore
    return this.test({
        name: YUPErrorLevelValidation + '-startWith-blankspaces',
        // @ts-ignore
        test: (value, {createError}) => {
            const {path} = this;
            if (value !== undefined && value.startsWith(" ")) {
                return createError({path: path, message: message});
            }
            return true;
        }
    });
}

function endsWithBlankSpace(message: string) {
    // @ts-ignore
    return this.test({
        name: YUPErrorLevelValidation + '-endsWith-blankspaces',
        // @ts-ignore
        test: (value, {createError}) => {
            const {path} = this;
            if (value !== undefined && value.endsWith(" ")) {
                return createError({path: path, message: message});
            }
            return true;
        }
    });
}

function identifierValueRequiredWhenSystemIsSelected(message: string) {

    // @ts-ignore
    return this.test({
        name: YUPErrorLevelValidation + '-identifier-value-required',
        // @ts-ignore
        test: (value, {createError, path, parent}) => {
            //  const [parent1, parent2] = context.from;
            var hasNoValue = false;
            var indexOfHasNoValue = 0;
            if (value !== undefined && value.trim().length != 0) {
                if (parent.value === undefined || parent.value.trim() === "") {
                    hasNoValue = true;
                    indexOfHasNoValue = path.substring(path.indexOf("[") + 1, path.indexOf("]"));
                }
            }
            if (hasNoValue) {
                return createError({path: "identifier[" + indexOfHasNoValue + "].value", message: message});
            }
            return true;
        }
    });
}

function identifierUUIDValueHasCorrectFormat(message: string) {
    // @ts-ignore
    return this.test({
        name: YUPErrorLevelValidation + '-identifier-uuid-value-format',
        // @ts-ignore
        test: (value, {createError, path, parent}) => {
            //  const [parent1, parent2] = context.from;
            var hasCorrectFormat = true;
            var indexOfWrongUUIDFormat = 0;
            if (value !== undefined && value.trim().length != 0) {
                if (parent.system === "UUID" & (parent.value !== undefined || parent.value.trim() !== "")) {
                    let regex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i;
                    hasCorrectFormat = regex.test(parent.value);
                    indexOfWrongUUIDFormat = path.substring(path.indexOf("[") + 1, path.indexOf("]"));
                }
            }
            if (!hasCorrectFormat) {
                return createError({path: "identifier[" + indexOfWrongUUIDFormat + "].value", message: message});
            }
            return true;
        }
    });
}

function identifierSystemRequiredWhenValueIsSpecified(message: string) {
    const objectName = 'identifier';
    // @ts-ignore
    return this.test(systemRequiredWhenValueIsSpecifiedTest(message, objectName));
}

function publisherTelecomSystemRequiredWhenValueIsSpecified(message: string) {
    const objectName = "contact[0].telecom"
    // @ts-ignore
    return this.test(systemRequiredWhenValueIsSpecifiedTest(message, objectName));
}

function authorTelecomSystemRequiredWhenValueIsSpecified(message: string) {
    const objectName = "extension." + "unknown" + ".valueContactDetail.telecom"
    // @ts-ignore
    return this.test(systemRequiredWhenValueIsSpecifiedTest(message, objectName));
}


function systemRequiredWhenValueIsSpecifiedTest(message: string, objectName: string) {
    return {
        name: YUPErrorLevelValidation + '-' + objectName + '-system-required',
        // @ts-ignore
        test: (value, {createError, path, parent}) => {
            //  const [parent1, parent2] = context.from;
            var objPath = replaceLast(path, '.value', "");
            return systemRequiredWhenValueIsSpecified(message, objPath, value, createError, path, parent);
        }
    };
}

function systemRequiredWhenValueIsSpecified(message: string, objPath: string, value: any, createError: any, path: string, parent: any) {
    var hasNoValue = false
    if (value !== undefined && value.trim().length != 0) {
        if (parent.system === undefined || parent.system.trim() === "") {
            hasNoValue = true;
        }
    }
    if (hasNoValue) {
        const errorPath = objPath + ".system";
        return createError({path: errorPath, message: message});
    }
    return true;
}


function identifierDuplicateValue(message: string) {

    // @ts-ignore
    return this.test({
        name: YUPErrorLevelValidation + '-identifier-duplicate-value',
        // @ts-ignore
        test: (value, {createError, path, from}) => {
            const [parent1, parent2] = from;
            let isDuplicateId = false;
            const indexStartOfArray = path.indexOf("[");
            const indexEndOfArray = path.indexOf("]");
            const valueIndexInArray = path.substring(indexStartOfArray + 1, indexEndOfArray);

            for (let i = 0; i < parent2.value.identifier.length; i++) {
                if (i.toString() !== valueIndexInArray) {
                    let identifier = parent2.value.identifier[i];
                    //OID case sensitive
                    if(parent1.value.system === formOID){
                        if (identifier.value !== undefined && identifier.system === formOID
                            && (identifier.value === value)) {
                            isDuplicateId = true;
                        }
                    }else {
                        //UUID case insensitive
                        if (identifier.value !== undefined && identifier.system === formUUID &&
                            (identifier.value.toUpperCase() === value.toUpperCase())) {
                            isDuplicateId = true;
                        }
                    }
                }
            }
            if (isDuplicateId) {
                return createError({path: path, message: message});
            }
            return true;
        }
    });
}


function hasRetiredReason(message: string) {

    // @ts-ignore
    return this.test({
        name: YUPErrorLevelValidation + '-retire-reason',
        // @ts-ignore
        test: (value, {createError, path, parent}) => {
            var indexOfExtension = 0;
            var requiredRetireReasonMissing = true;
            if (value !== null && value === "retired" && parent.extension !== undefined && Array.isArray(parent.extension)) {
                for (let i = 0; i < parent.extension.length; i++) {
                    let ext = parent.extension[i];
                    if ((ext.url !== undefined && ext.url === extensionRetireReasonSystemUrl)) {
                        if (ext.valueString === undefined ||
                            ext.valueString.trim().length == 0 ||
                            ext.valueString.trim().length < 10) {
                            requiredRetireReasonMissing = true;
                            indexOfExtension = i;
                            break;
                        } else {
                            requiredRetireReasonMissing = false;
                        }
                    }
                }
            } else if (value === "draft" || value === "active") {
                requiredRetireReasonMissing = false;
            }

            if (requiredRetireReasonMissing == true) {
                return createError({path: "extension[" + indexOfExtension + "].valueString", message: message});
            }
            return true;
        }
    });
}

addMethod(string, "hasValidSystemName", hasValidSystemName);
addMethod(string, "hasOnlyBlankSpaces", hasOnlyBlankSpaces);
addMethod(string, "startsWithBlankSpace", startsWithBlankSpace);
addMethod(string, "endsWithBlankSpace", endsWithBlankSpace);
/*addMethod(string, "systemRequiredWhenValueIsSpecified", systemRequiredWhenValueIsSpecified);*/
addMethod(string, "publisherTelecomSystemRequiredWhenValueIsSpecified", publisherTelecomSystemRequiredWhenValueIsSpecified);
addMethod(string, "authorTelecomSystemRequiredWhenValueIsSpecified", authorTelecomSystemRequiredWhenValueIsSpecified);
addMethod(string, "identifierValueRequiredWhenSystemIsSelected", identifierValueRequiredWhenSystemIsSelected);
addMethod(string, "identifierUUIDValueHasCorrectFormat", identifierUUIDValueHasCorrectFormat);
addMethod(string, "identifierSystemRequiredWhenValueIsSpecified", identifierSystemRequiredWhenValueIsSpecified);
addMethod(string, "identifierDuplicateValue", identifierDuplicateValue);
addMethod(string, "hasRetiredReason", hasRetiredReason);


const identifierSchema = {
    value: string().identifierSystemRequiredWhenValueIsSpecified("Typ av Identifierare är obligatorisk")
        .identifierDuplicateValue("Identifierare får inte ha samma värde som en annan identifierare")
        .identifierUUIDValueHasCorrectFormat("UUID måste vara i giltligt UUID format"),
    system: string().identifierValueRequiredWhenSystemIsSelected("Identifierare är obligatorisk")
};

const typeOfTelecomSystemIsMandatoryWhenValueIsSpecifiedMessege = "Typ av kontaktuppgift är obligatorisk när kontaktuppgift är ifylld";

const publisherTelecomSchema = {
    value: string().publisherTelecomSystemRequiredWhenValueIsSpecified(typeOfTelecomSystemIsMandatoryWhenValueIsSpecifiedMessege)
};

const authorTelecomSchema = {
    value: string().authorTelecomSystemRequiredWhenValueIsSpecified(typeOfTelecomSystemIsMandatoryWhenValueIsSpecifiedMessege)
};

const contactDetailSchema = {
    telecom: array().of(object().shape(publisherTelecomSchema))
};

const authorContactDetailSchema = {
    telecom: array().of(object().shape(authorTelecomSchema))
};

/*const conceptSchema = {
    code: string(),
    display: string().when("code", {
        is: (code) => code !== undefined && code !== "",
        then: string().required('Klartext ofullständig. Ange minst 2 tecken.')
    }),
};*/

export const schema = object().shape({
    name: string().required("Namn är obligatoriskt").min(2, "namn Minimum 2 tecken").max(255, "Max 255 tecken")
        .matches(/^(\S+$)/g, "Inga mellanslag i systemnamn")
        .hasValidSystemName("Systemnamnet innehåller otillåtna tecken. Använd följande tecken:" +
            "a-z, A-Z, 0-9, \"-\", \".\", \"_\", \"~\""),
    title: string().required("Titel är obligatoriskt").min(2, "titel Minimum 2 tecken").max(255, "Max 255 tecken"),
    url: string().required("Url är obligatoriskt").min(2, "Minimum 2 tecken").matches(/^(\S+$)/g, "Inga mellanslag i url"),
    identifier: array().of(object().shape(identifierSchema)),
    contact: array().of(object().shape(contactDetailSchema)),
    // concept: array().of(object().shape(conceptSchema)),
    //.min(1, "Ange minst en unik identifierare").of(object().shape(identifierSchema)),
    // @ts-ignore
    /*    status: array().of(string()),*/
    status: string().hasRetiredReason("Ge en utförligare beskrivning. Minimum 10 tecken."),
    description: string().hasOnlyBlankSpaces('Bara mellanslag i version tillåts inte'),

    // @ts-ignore
    version: string().max(1000, `Max 1000 tecken`).hasOnlyBlankSpaces('Bara mellanslag i version tillåts inte')
        .startsWithBlankSpace('Mellanslag som första tecken tillåts inte')
        .endsWithBlankSpace('Mellanslag som sista tecken tillåts inte'),
    extension: array()
        .of(
            object().shape({
                url: string(),
                valueString: string().max(1000, `Max 1000 tecken`),
                valueContactDetail: object().shape(authorContactDetailSchema)
            })
        )
        .test({
            name: YUPErrorLevelValidation + '-extension-version-descr-only-blankspaces-blocks-active',
            test: (val, {createError}) => {
                let onlyBlankSpaces = false;
                let startWithBlankSpace = false;
                let indexWithVersionDesc = 0;
                if (val !== undefined) {
                    val.every(
                        ({url, valueString}, index) => {
                            if (url !== undefined && url.trim() !== "" && url === extensionVersionDescriptionCodeSystemUrl) {
                                if (valueString !== undefined && valueString.length != 0 && valueString.trim() === "") {
                                    onlyBlankSpaces = true;
                                    indexWithVersionDesc = index
                                } else if (valueString !== undefined && valueString.startsWith(" ")) {
                                    startWithBlankSpace = true;
                                    indexWithVersionDesc = index;
                                }
                            }
                        }
                    )
                }
                if (onlyBlankSpaces) {
                    return createError({
                        path: "extension." + indexWithVersionDesc + ".valueString",
                        message: 'Bara mellanslag tillåts inte'
                    });
                } else if (startWithBlankSpace) {
                    return createError({
                        path: "extension." + indexWithVersionDesc + ".valueString",
                        message: 'Mellanslag som första tecken tillåts inte'
                    });
                }
                return true;
            }
        }),
});


/*
    status: string().when("version", {
    // @ts-ignore
    is: (version) => version === undefined,
    then: string().required("Fyll i version för att kunna aktivera")
})
*/


export const schemaNewVersion = object().shape({
    version: string().max(1000, `Max 1000 tecken`).hasOnlyBlankSpaces('Bara mellanslag i version tillåts inte')
        .startsWithBlankSpace('Mellanslag som första tecken tillåts inte')
        .endsWithBlankSpace('Mellanslag som sista tecken tillåts inte')
});


