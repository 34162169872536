
export const handleKeyUp = (event, handleChange) => {
    let timer;
    const waitTime = 2000;
    clearTimeout(timer);
    timer = setTimeout(() => {
        handleChange(event);
    }, waitTime);
};

export const onKeyDown = (e) => {
    if (e.key === "Tab") {
        e.preventDefault();
    }
};

export const removeTabsInValue = (content) => {
    return  content.replace(/\t/g, '');
};
