import React, {FC, useEffect, useState} from 'react';
import {IDSAlert} from "@inera/ids-react";
import {getArtifactSwedishLabelNTgenus, getStatusInSwedishSupinum} from "../../CommonFunctions";
import {useFormContext} from "react-hook-form";


interface SaveNotificationProps {
    activateSaveNotice?: boolean,
    setActivateSaveNotice?: any,
    idsAlertType?: string,
    artefactType?: string,
    isFromDraftToActiveSave?: boolean,
    setIsFromDraftToActiveSave?: any,
    isFromActiveToRetiredSave?: boolean,
    setIsFromActiveToRetiredSave?: any
}

export const SaveNotification: FC<SaveNotificationProps> = ({
                                                                activateSaveNotice,
                                                                setActivateSaveNotice,
                                                                idsAlertType,
                                                                artefactType,
                                                                isFromDraftToActiveSave,
                                                                setIsFromDraftToActiveSave,
                                                                isFromActiveToRetiredSave,
                                                                setIsFromActiveToRetiredSave
                                                            }) => {

    const methods = useFormContext();

    useEffect(() => {
        const timer = setTimeout(() => {
            setActivateSaveNotice(false);
            resetStatusNotificationIfAny();
        }, 3000);


        // To clear or cancel a timer, you call the clearTimeout(); method,
        // passing in the timer object that you created into clearTimeout().

        return () => clearTimeout(timer);
    }, []);

    const getSricontitle = () => {
        if (idsAlertType === "success") {
            return "success icon";
        } else if (idsAlertType === "error") {
            return "error icon";
        }
    };

    const getNoticeMessage = () => {
        if (idsAlertType === "success") {
            let text = getArtifactSwedishLabelNTgenus(artefactType) + " har " + getActionText();
            return text;
        } else if (idsAlertType === "error") {
            let text = getArtifactSwedishLabelNTgenus(artefactType) + " sparades inte";
            return text;
        }
    };

    const getActionText = () => {
        if (isFromDraftToActiveSave) {
            return  getStatusInSwedishSupinum("active");
        } else if (isFromActiveToRetiredSave) {
            return getStatusInSwedishSupinum("retired");
        } else {
            return "sparats";
        }
    };


    const resetStatusNotificationIfAny = async () => {
        if (isFromDraftToActiveSave) {
            await setIsFromDraftToActiveSave(false);
        } else if (isFromActiveToRetiredSave) {
            await setIsFromActiveToRetiredSave(false);
        }
    };


    return (
        <div>
            {(activateSaveNotice !== undefined && activateSaveNotice == true) &&
                // @ts-ignore
                <IDSAlert sricontitle={getSricontitle()} type={idsAlertType}>
                    {getNoticeMessage()}
                </IDSAlert>
            }
        </div>
    );
};
