import React from "react";
import IConceptSearchResultItem from "../search/model/IConceptSearchResultItem";

export function compare(a: IConceptSearchResultItem, b: IConceptSearchResultItem) {
    if (a.display.toLowerCase() < b.display.toLowerCase()) {
        return -1;
    }
    if (a.display.toLowerCase() > b.display.toLowerCase()) {
        return 1;
    }
    return 0;
}