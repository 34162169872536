import {IBundleEntry} from "fhir-typescript-models";
import IIncludeMeta from "../read/IIncludeMeta";

export const getCodeSystemMetaArray = (relatedArtifactsArray: IBundleEntry[]) => {
    var relatedCodeSystemMeta: IIncludeMeta[] = []
    if (relatedArtifactsArray !== undefined && relatedArtifactsArray?.length !== 0) {
        for (let bundleEntry of relatedArtifactsArray) {
            let includeMeta = getCodeSystemMeta(bundleEntry);
            if (includeMeta !== undefined) {
                relatedCodeSystemMeta.push(includeMeta);
            }
        }
    }
    return relatedCodeSystemMeta;
};

export const getCodeSystemMeta = (bundleEntry: IBundleEntry) => {

    if (bundleEntry !== undefined && bundleEntry.resource !== undefined) {
        let includeMeta = {} as IIncludeMeta;
        // @ts-ignore
        includeMeta.system = bundleEntry.resource.url;
        // @ts-ignore
        includeMeta.title = bundleEntry.resource.title;
        // @ts-ignore
        includeMeta.id = bundleEntry.resource.id;
        // @ts-ignore
        includeMeta.version = bundleEntry.resource.version;
        // @ts-ignore
        includeMeta.status = bundleEntry.resource.status;
        return includeMeta;
    } else {
        return undefined;
    }
};

