import {
    ValueSetComposeIncludeConceptDesignation,
    ValueSetExpansionContains
} from "fhir-typescript-models/dist/src/models/fhir/internal";
import IConceptSynonym from "../../search/model/IConceptSynonym";
import {getUseCodeFromDesignation, snomedSynonymCode} from "../SnomedTransformerUtil";
import IConceptSynonymsSwedish from "../../search/model/IConceptSynonymsSwedish";
import {getRecommendedFromDesignations} from "./SynonymRecommendedTransformer";


const snomedSwedishLanguageCodeSv = "sv";
export const snomedSwedishLanguageCode = "sv-x-sctlang-46011000-052107";

export const getSwedishSynonyms = (swedishAcceptableSynonyms: IConceptSynonym[], swedishRecommended: IConceptSynonym | undefined): IConceptSynonymsSwedish | undefined => {
    let synonymsSwedish: IConceptSynonymsSwedish | undefined = undefined;
    if (swedishAcceptableSynonyms.length !== 0 || swedishRecommended !== undefined) {
        synonymsSwedish = {};
        if (swedishAcceptableSynonyms.length !== 0) {
            synonymsSwedish.acceptableList = swedishAcceptableSynonyms;
        }
        synonymsSwedish.recommended = swedishRecommended;
    }
    return synonymsSwedish;
}


export const getSwedishSynonymsFromContains = (contains: ValueSetExpansionContains): IConceptSynonymsSwedish | undefined => {
    let sweAcceptableSynonyms: IConceptSynonym[] = [];
    let recommended: IConceptSynonym | undefined = undefined;
    if (contains.designation !== undefined) {
        sweAcceptableSynonyms = getSwedishAcceptableSynonymsFromDesignations(contains.designation);
        let sweRecommended = getSwedishRecommendedFromDesignations(contains.designation);
        if (sweRecommended !== undefined) {
            recommended = {value: sweRecommended}
        }
    }
    if (sweAcceptableSynonyms.length == 0 && recommended === undefined) {
        return undefined;
    } else {
        return {
            recommended: recommended,
            acceptableList: sweAcceptableSynonyms.length == 0 ? undefined : sweAcceptableSynonyms
        };
    }
}

export const getSwedishRecommendedFromDesignations = (designationArray: Array<ValueSetComposeIncludeConceptDesignation>): string => {
    return getRecommendedFromDesignations(designationArray, snomedSwedishLanguageCode)
}

export const getSwedishAcceptableSynonymsFromDesignations = (designationArray: ValueSetComposeIncludeConceptDesignation[]): IConceptSynonym[] => {
    let swedishSynonyms: IConceptSynonym[] = [];
    for (let designation of designationArray) {
        const swedishSynonym: IConceptSynonym | undefined = getSwedishAcceptableSynonymsFromDesignation(designation);
        if (swedishSynonym !== undefined) {
            swedishSynonyms.push(swedishSynonym);
        }
    }
    return swedishSynonyms;
}

export const getSwedishAcceptableSynonymsFromDesignation = (designation: ValueSetComposeIncludeConceptDesignation): IConceptSynonym | undefined => {
    const useCode = getUseCodeFromDesignation(designation);
    //  @ts-ignore
    if (designation.language === snomedSwedishLanguageCodeSv &&
        useCode === snomedSynonymCode) {
        if (designation.value !== undefined) {
            //  @ts-ignore
            const sweSynonym: IConceptSynonym =
                {
                    //  @ts-ignore
                    value: designation.value,
                    selected: false
                }
            return sweSynonym;
        }
    }
    return undefined;
}

export const getSwedishSynonymsAsStringsFromDesignation = (designationArray: any[]): string[] => {
    let swedishSynonyms: string[] = [];
    for (let designation of designationArray) {
        const useCode = getUseCodeFromDesignation(designation);
        if (designation.language === snomedSwedishLanguageCodeSv &&
            useCode === snomedSynonymCode) {
            swedishSynonyms.push(designation.value)
        }
    }
    return swedishSynonyms;
}

const isDesignationSwedishLanguage = (designation: ValueSetComposeIncludeConceptDesignation): boolean => {
    //  @ts-ignore
    if (designation.language === snomedSwedishLanguageCodeSv || designation.language === snomedSwedishLanguageCode) {
        return true;
    } else {
        return false;
    }
}