import React, {FC} from "react";
import {IDSButton, IDSLink} from "@inera/ids-react";
import '@inera/ids-core/components/expandable/register';
import '@inera/ids-core/components/expandable/register';


interface VSImportCancelButtonProps {
    ix: number,
    setImportTab: any,
    buttonSize: string
}


const VSImportCancelLink: FC<VSImportCancelButtonProps> = ({ix, setImportTab}) => {

    return (
            <IDSLink underlined>
                <a href="#" onClick={() => {
                    setImportTab(ix, false)
                }}>AVBRYT </a>
            </IDSLink>
    );
}
export default VSImportCancelLink;