import {ICodeSystem} from "fhir-typescript-models";


export const createSlimCodeSystem = (data: ICodeSystem): ICodeSystem => {

    const meta = {
        lastUpdated: data?.meta?.lastUpdated
    };

    const codeSystem = {
        concept: data.concept,
        id: data.id,
        name: data.name,
        resourceType: data.resourceType,
        status: data.status,
        title: data.title,
        url: data.url,
        version: data.version,
        meta: meta
    };

    return codeSystem;
}