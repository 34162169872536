import React, {FC} from 'react';

import {Controller} from "react-hook-form"
import clsx from 'clsx';

import {Checkbox} from "@material-ui/core";
import {checkBoxStyles, muiSelectCheckboxPropsStyle} from "../../../styles/TTStyles";

import {
    makeStyles,
    createMuiTheme,
    ThemeProvider,
} from '@material-ui/core/styles';
import {blue} from "@mui/material/colors";

let renderCount = 0;
interface RHookFormCheckBoxProps {
    name: string,
    control: any,
    disabled: boolean

}

const RHookFormCheckBox: FC<RHookFormCheckBoxProps> = ({
                                                           name,
                                                           control,
                                                           disabled
                                                       }) => {

    const classes = checkBoxStyles();




    renderCount++;

    return (

        <Controller
            name={name}
            control={control}
            render={({field}) => (
                <Checkbox
                    className={classes.backgroundColorOnInnerBoxOfCustomIcon}

                    disableRipple
                    color={"default"}
                    checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                    icon={<span className={classes.icon} />}
                    inputProps={{ 'aria-label': 'decorative checkbox' }}
                    /*        style={muiSelectCheckboxPropsStyle}*/

                    onChange={e => {
                        field.onChange(e.target.checked);
                    }}
                    checked={field.value}
                    disabled={disabled}
                />
            )}
        />

    );
};

export default RHookFormCheckBox;
