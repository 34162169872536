import {ValueSet, ValueSetExpansionContains} from "fhir-typescript-models/dist/src/models/fhir/internal";
import IConceptSearchResultItem from "../search/model/IConceptSearchResultItem";
import IConceptDetails from "../search/model/IConceptDetails";
import IConceptSynonym from "../search/model/IConceptSynonym";
import {getSwedishDisplayOrDefault} from "./DisplayTransformer";
import {
    getSwedishAcceptableSynonymsFromDesignation,
    getSwedishSynonyms,
    snomedSwedishLanguageCode
} from "./synonym/SynonymSwedishLanguageTransformer";
import {getRecommendedFromDesignation} from "./synonym/SynonymRecommendedTransformer";
import {
    getAcceptableEnglishLanguageSynonymFromDesignation,
    getEnglishSynonyms,
    snomedDefaultLanguageCodeEn,
    UK_English_en_x_sctlang,
    US_English_en_x_sctlang
} from "./synonym/SynonymEnglishLanguageTransformer";
import {getFullySpecifiedNameFromDesignation} from "./FullySpecifiedNameTransformer";
import IConceptSynonymsSwedish from "../search/model/IConceptSynonymsSwedish";
import IConceptSynonymsEnglish from "../search/model/IConceptSynonymsEnglish";
import IConceptSynonymsAll from "../search/model/IConceptSynonymsAll";
import {
    getAllSynonyms,
    getSwedishOrDefaultSynonymsAsStringsFromConceptSynonymsAll
} from "./synonym/SynonymAllLanguageTransformer";
import {transformExtensionsAndSetProperties} from "./ExtensionTransformer";


export const collectContains = (valueset: ValueSet): IConceptSearchResultItem[] => {
    let collection: IConceptDetails[] = [];
    if (valueset.expansion !== undefined && valueset.expansion.contains !== undefined)
        for (const contain of valueset.expansion?.contains) {
            const concept = transformToConcept(contain)
            if (concept !== undefined) {
                collection.push(concept)
            }
        }
    return collection;
}
const transformToConcept = (contain: ValueSetExpansionContains): IConceptSearchResultItem | undefined => {
    let concept: IConceptSearchResultItem | undefined = undefined;
    if (contain.code !== undefined && contain.designation !== undefined) {

        let swedishSynonyms: IConceptSynonym[] = [];
        let swedishRecommended: IConceptSynonym | undefined = undefined;

        let englishSynonyms: IConceptSynonym[] = [];
        let englishUSRecommended: IConceptSynonym | undefined = undefined;
        let englishUKRecommended: IConceptSynonym | undefined = undefined;
        let fullySpecifiedName: string | undefined = undefined;

        //Collect from designations
        const display: string | undefined = getSwedishDisplayOrDefault(contain);

        if (contain.designation !== undefined) {

            for (let designation of contain.designation) {

                //Swedish acceptableSynonyms
                const swedishSynonym: IConceptSynonym | undefined = getSwedishAcceptableSynonymsFromDesignation(designation);
                if (swedishSynonym !== undefined) {
                    swedishSynonyms.push(swedishSynonym);
                }

                //Swedish Recommended
                const sweRecommended: string | undefined = getRecommendedFromDesignation(designation, snomedSwedishLanguageCode);
                if (sweRecommended !== undefined) {
                    swedishRecommended = {value: sweRecommended, selected: false}
                }

                //English acceptableSynonyms
                const englishSynonym: IConceptSynonym | undefined = getAcceptableEnglishLanguageSynonymFromDesignation(designation);
                if (englishSynonym !== undefined) {
                    englishSynonyms.push(englishSynonym);
                }

                //English US Recommended
                const engUsRecommended: string | undefined = getRecommendedFromDesignation(designation, US_English_en_x_sctlang);
                if (engUsRecommended !== undefined) {
                    englishUSRecommended = {value: engUsRecommended, selected: false}
                }

                //English UK Recommended
                const engUKRecommended: string | undefined = getRecommendedFromDesignation(designation, UK_English_en_x_sctlang);
                if (engUKRecommended !== undefined) {
                    englishUKRecommended = {value: engUKRecommended, selected: false}
                }

                //Eng fully specifiedName
                fullySpecifiedName = getFullySpecifiedNameFromDesignation(designation, snomedDefaultLanguageCodeEn);

            }
        }

        const synonymSwedish: IConceptSynonymsSwedish | undefined = getSwedishSynonyms(swedishSynonyms, swedishRecommended);

        const synonymEnglish: IConceptSynonymsEnglish | undefined = getEnglishSynonyms(
            englishSynonyms,
            englishUSRecommended,
            englishUKRecommended);

        const synonymsAll: IConceptSynonymsAll | undefined = getAllSynonyms(synonymSwedish, synonymEnglish);
        const synonymSummeryAsString = getSwedishOrDefaultSynonymsAsStringsFromConceptSynonymsAll(synonymsAll);


        concept = {
            // @ts-ignore
            code: contain.code,
            display: display,
            synonyms: synonymsAll,
            synonym: synonymSummeryAsString.toString(),
            fullySpecifiedName: fullySpecifiedName

        };

        if (contain.extension !== undefined) {
            concept = transformExtensionsAndSetProperties(contain.extension, concept);
        }

        setInActiveStatus(contain, concept);
    }
    return concept;
}

const setInActiveStatus = async (contain: ValueSetExpansionContains, concept: IConceptSearchResultItem | undefined) => {

    if (concept !== undefined) {
        if (contain.inactive === undefined) {
            concept.inactive = false;
        } else {
            // @ts-ignore
            concept.inactive = contain.inactive;
        }
    }
}

export const extractCodes = (contains: ValueSetExpansionContains[] | undefined): string[] => {
    let codes: string[] = [];
    if (contains !== undefined) {
        for (let contain of contains) {
            if (contain.code !== undefined) {
                // @ts-ignore
                codes.push(contain.code);
            }
        }
    }
    return codes;
}