import {IRHookFormValidationMessage} from "../rhookform/IRHookFormValidationMessage";
import ITabName from "../page/ITabName";
import {string} from "yup";
import {createValidationNoteForMessageList} from "./ValidationUtil";


export const handleMessageList = (message: string, label: string, messageList: IRHookFormValidationMessage[], setMessageList: any, tabName: ITabName, rowindex?: string, objPath?: string) => {

    const messageExist: boolean = (message != null && message !== undefined);
    const labelWithOptionalRowIndex: string = getMessageArrayLabelName(label, rowindex);

    // @ts-ignore
    setMessageList(existingList => {

        // @ts-ignore
        let fieldMessageAlreadyExisting = existingList.find(x => (x.label.startsWith(labelWithOptionalRowIndex)));

        //Add if message is not already existing for label
        if (!fieldMessageAlreadyExisting && messageExist) {
            return [...existingList, createValidationNoteForMessageList(labelWithOptionalRowIndex, message, tabName, objPath)]
        }
        //remove
        else if ((fieldMessageAlreadyExisting !== undefined) && (message == null)) {
            // @ts-ignore
            const newArray = existingList.filter(message => (message.label.startsWith(labelWithOptionalRowIndex) == false))
            if (newArray === undefined) {
                return []
            } else {
                return newArray;
            }
        }
        // do nothing
        else {
            return existingList;
        }
    })

};

export const removeValidationMessagesOnObjPath = (path: string, setMessageList: any) => {
    // @ts-ignore
    setMessageList(existingList => {
        // @ts-ignore
        const newArray = existingList.filter(message => ((message.path === undefined) || (message.path.startsWith(path) == false)))
        return newArray;
    })
}


export const getMessageArrayLabelName = (label: string, rowindex?: string) => {
    return rowindex === undefined ? label : label + rowindex
}

export const collectValidationMessage = (message: string, label: string, result: any, handleNoticeList: any, tabName: ITabName, rowindex?: string, path?: string) => {
    if (handleNoticeList !== undefined) {
        if (result !== undefined && result !== null && result !== false) {
            let m = message.repeat(1);
            //   console.log("isWithoutID collectValidationMessage message: " + m);
            handleNoticeList(m, label, tabName, rowindex, path);
        } else {
            //remove
            handleNoticeList(null, label, tabName, rowindex, path);
        }
    }
    return result;
}

export const validEmail = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(email)) {
        return false;
    } else {
        return true;
    }
}
