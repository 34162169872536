import ITabName from "./ITabName";
import {IRHookFormValidationMessage} from "../rhookform/IRHookFormValidationMessage";

const METADATA = "METADATA";
export const CONTENT = "CONTENT";
const PUBLISH = "PUBLISH";

const createTabName = (name: string) => {
    let tabName : ITabName = {} as ITabName;
    tabName.name = name;
    return tabName;
};

export const metaDataTabName = createTabName(METADATA);
export const contentTabName = createTabName(CONTENT);
export const publishTabName = createTabName(PUBLISH);


export const collectNotices = (infoMessageList: IRHookFormValidationMessage[], warnMessageList: IRHookFormValidationMessage[], errorMessageList: IRHookFormValidationMessage[],
                               setMetaDataNotices: any, setContentNotices: any, setPublishNotices: any) => {

        countMetaDataNotices(infoMessageList,warnMessageList, errorMessageList, setMetaDataNotices);
        countContentNotices(infoMessageList,warnMessageList, errorMessageList, setContentNotices);
        countPublishNotices(infoMessageList,warnMessageList, errorMessageList,setPublishNotices);

};

const countMetaDataNotices = (infoMessageList: IRHookFormValidationMessage[], warnMessageList: IRHookFormValidationMessage[], errorMessageList: IRHookFormValidationMessage[], setNotices: any) => {
    countNotices(metaDataTabName, infoMessageList, warnMessageList, errorMessageList, setNotices);
};

const countContentNotices = (infoMessageList: IRHookFormValidationMessage[], warnMessageList: IRHookFormValidationMessage[], errorMessageList: IRHookFormValidationMessage[], setNotices: any) => {
    countNotices(contentTabName, infoMessageList, warnMessageList, errorMessageList, setNotices);
};

const countPublishNotices = (infoMessageList: IRHookFormValidationMessage[], warnMessageList: IRHookFormValidationMessage[], errorMessageList: IRHookFormValidationMessage[], setNotices: any) => {
    countNotices(publishTabName, infoMessageList, warnMessageList, errorMessageList, setNotices);
};

const countNotices = (tabName: ITabName, infoMessageList: IRHookFormValidationMessage[], warnMessageList: IRHookFormValidationMessage[], errorMessageList: IRHookFormValidationMessage[],  setNotices: any) => {
    let countMetaDataNotices = 0;

    countMetaDataNotices = countNoticeFromList(countMetaDataNotices, tabName, infoMessageList);
    countMetaDataNotices = countNoticeFromList(countMetaDataNotices, tabName, warnMessageList);
    countMetaDataNotices = countNoticeFromList(countMetaDataNotices, tabName, errorMessageList);

    // @ts-ignore
    //setNotices(prevState => prevState + countMetaDataNotices);
    setNotices(countMetaDataNotices);
    countMetaDataNotices.toString();
};

const countNoticeFromList = (countMetaDataNotices:number , tabName: ITabName, messageList: IRHookFormValidationMessage[]) => {

    for (let errorMessage of messageList) {
        try {
            // @ts-ignore
            if (errorMessage.tabName.name === tabName.name) {
                countMetaDataNotices = countMetaDataNotices + 1;
            }
        } catch (error) {
            console.error("errorMessage" + JSON.stringify(errorMessage)  + " and tabName: "
                + JSON.stringify(tabName) + "throws error: " + error);
            throw error;
        }
    }

   return countMetaDataNotices;
};